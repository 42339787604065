import { axios } from 'tccc-utils';
import './tracer';
import i18next from '../i18n/i18next.config';

axios.defaults.baseURL = 'https://api.tccc.qcloud.com';
/// @ts-ignore
axios.defaults.backupURL = 'https://api-bak.tccc.qcloud.com';
axios.interceptors.request.use(
  (config) => {
    if (
      config.url &&
      ['/tccclogin/login/checkLogin', '/tccclogin/login/loginInstance'].includes(config.url) &&
      config.headers.Authorization
    ) {
      Object.assign(config.headers, {
        Authorization: undefined,
      });
    }
    Object.assign(config.data, {
      language: i18next.language,
    });

    return config;
  },
  (err) => Promise.reject(err),
);

export { axios };
