import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ulid } from 'ulidx';
import isError from 'lodash/isError';
import get from 'lodash/get';
import { axios, backupUrlInterceptor, csrfInterceptor } from 'tccc-utils/src/axios';
import { GET_API_BAK_DOMAIN, GET_API_DOMAIN } from 'tccc-env/src/url';

type CommonResponse = {
  errorCode: string;
  msg: string;
  nonce: string;
  requestId: string;
};

type AuthUserInfo = Partial<{ sessionKey: string; userId: string; sdkAppId: string }>;

type APIs = {
  '/ccc/pstn/dial': {
    Input: {
      callee:
        | { userId: string }
        | { skillGroupId: string }
        | { phone: string; phoneEncodeType?: 'base64' | 'reflect' | 'number' };
    } & Partial<{
      skillGroupId: number;
      servingNumberGroupIds: string[];
      servingNum: string;
      uui: string;
      useMobile: boolean;
    }>;
    Output: {
      sessionId: string;
    } & Partial<{
      userMark: string;
      servingNumber: string;
      useMobile: boolean;
      useExtension: boolean;
      phone: string;
      protectedPhone: string;
    }>;
  };
  '/ccc/pstn/dialBack': {
    Input: {
      sessionId: string;
    };
    Output: {
      sessionId: string;
    } & Partial<{
      userMark: string;
      servingNumber: string;
      useMobile: boolean;
      useExtension: boolean;
      phone: string;
      protectedPhone: string;
    }>;
  };
  '/ccc/pstn/seatin': {
    Input: {
      sessionId: string;
    };
    Output: {};
  };
};

const logger = console;

let axiosInstance: AxiosInstance;
const getAxiosInstance = (origin?: string) => {
  if (!axiosInstance) {
    const options = {
      baseURL: `https://${GET_API_DOMAIN(origin)}`,
      // @ts-ignore
      backupURL: `https://${GET_API_BAK_DOMAIN(origin)}`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axiosInstance = axios.create(options);

    backupUrlInterceptor(axiosInstance);
    csrfInterceptor(axiosInstance);
  }
  return axiosInstance;
};

export async function request<T extends keyof APIs>(
  url: T,
  data: APIs[T]['Input'],
  config?: AxiosRequestConfig & AuthUserInfo & { origin: string },
): Promise<APIs[T]['Output'] & CommonResponse> {
  const id = ulid();
  const sdkAppId = get(config, 'sdkAppId');

  const params = {
    nonce: id,
    requestId: id,
    timestamp: `${Date.now()}`,
    // @ts-ignore
    ...data,
  };
  if (sdkAppId && !['/tccclogin/login/loginInstance', '/tccclogin/login/checkLogin'].includes(url)) {
    Object.assign(params, { sdkAppId });
  }
  return getAxiosInstance(config?.origin)
    .request<APIs[T]['Output'] & CommonResponse>({
      url,
      method: 'post',
      data: params,
      ...config,
      headers: config?.sessionKey
        ? {
            ...config?.headers,
            Authorization: `Bearer ${config.sessionKey}`,
          }
        : config?.headers,
    })
    .then((res) => {
      if (Number(res.data.errorCode) === 0) {
        return res.data;
      }
      throw res;
    })
    .catch((error) => {
      if (typeof error === 'string') {
        console.error(error);
        const unknownError = new Error(error);
        const log = {
          errorCode: '-3',
          name: 'Unknown error',
          message: error,
          nonce: params.nonce,
          requestId: params.nonce,
          stack: unknownError.stack,
        };
        logger.warn(log);
        return Promise.reject(unknownError);
      }
      // io-ts parsed error
      if (isError(error) && error.message.indexOf('parse failed') !== -1) {
        const parsedError = error;
        const log = {
          errorCode: '-2',
          name: 'parse failed',
          message: error.message,
          nonce: params.nonce,
          requestId: params.nonce,
          stack: parsedError.stack,
        };
        logger.warn(log);
        return Promise.reject(parsedError);
      }
      // errorCode !== 0
      if (error.data) {
        const tcccError = new Error(error.data.msg);
        const log = {
          ...error.data,
          message: tcccError.message,
          stack: tcccError.stack,
          code: error.data.errorCode,
        };
        if (error.data?.errorCode) {
          if (/^-\d*999$/.test(error.data?.errorCode)) {
            logger.error(log);
          }
        } else {
          logger.warn(log);
        }
        return Promise.reject(log);
      }
      // Network Error
      if (error.request) {
        if (axios.isAxiosError(error)) {
          const log = {
            errorCode: '-1',
            message: error.message,
            nonce: params.nonce,
            requestId: params.requestId,
            stack: error.stack,
          };
          logger.warn(log);
          return Promise.reject(log);
        }
        const errJSon = error?.toJSON?.() || { name: 'Network Error', message: 'Network Error' };
        const log = {
          errorCode: '-1',
          message: `[network error]${errJSon.name}:${errJSon.message},url:${url}`,
          nonce: params.nonce,
          requestId: params.requestId,
          stack: errJSon.stack,
        };
        logger.warn(log);
        return Promise.reject(errJSon);
      }
      // Unknown Error
      const tcccError = new Error(error?.statusText || 'unknown error');
      const log = {
        errorCode: error?.status || '-1',
        message: error?.statusText || 'unknown error',
        nonce: params.nonce,
        requestId: params.nonce,
        stack: tcccError.stack,
      };
      logger.error(log);

      return Promise.reject(tcccError);
    });
}
