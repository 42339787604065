import { ulid } from 'ulidx';
import EventEmitter from 'eventemitter3';
import Logger from 'tccc-logger';
import { WebsocketInterface } from './WebsocketInterface';

enum SOCKET_STATUS {
  STATUS_CONNECTED = 0,
  STATUS_CONNECTING = 1,
  STATUS_DISCONNECTED = 2,
}

export type SocketMessage = {
  errorCode: string;
  msg: string;
  nonce: string;
  event?: string;
  command?: 's2c';
};

enum UserStatus {
  offline = 0, // 离线
  free = 100, // 空闲
  busy = 200,
  rest = 300,
  countdown = 400,
  arrange = 500,
  stopRest = 600,
  notReady = 700,
  stopNotReady = 800,
}

// 最大重连数量
const MAX_RECOVER_COUNT = 5;
const MAX_HEARTBEAT_FAIL_COUNT = 10;

type Newable<T> = new (...args: any[]) => T;
/**
 * TCCC基础Websocket
 * 1. 上下线
 * 2. 心跳
 * 3. 重连
 * 4. 域名切换
 */
export type TcccWebsocketEvent<T extends SocketMessage> = {
  connect: () => void; // 包括ws连接+login command
  disconnect: (msg: { reason: string; code: string }) => void;
  failed: (msg: { message: string; code: string }) => void;
  s2c: (msg: T, ...args: any[]) => void;
  heartbeat: (msg: T, tabUUID?: string) => void;
};

export class TcccWebsocket<T extends SocketMessage> extends EventEmitter<TcccWebsocketEvent<T>> {
  protected userId?: string;
  protected sdkAppId?: string;
  protected readonly logger: Logger;
  private sessionKey?: string;
  private language: string;
  private url: string;
  private backupUrl: string;
  private readonly uuid: string;
  private tracer?: any;
  private lastHeartBeat?: SocketMessage;

  private status: SOCKET_STATUS;
  private closed: boolean; // 是否主动关闭
  private recoverAttempts: number; // 表示重连次数，重连成功或主动下线设为0

  private ws: WebsocketInterface | null;
  private heartbeatTimer: ReturnType<typeof setInterval> | null;
  private nonceTimer: Record<string, ReturnType<typeof setTimeout>> = {};

  private readyAwait: Promise<unknown>;
  private readyResolve?: (value?: unknown) => void;
  private readonly nonceEvent = new EventEmitter();
  private readonly wsInterface: Newable<WebsocketInterface>;

  constructor(options: {
    url: string;
    wsInterface: Newable<WebsocketInterface>;
    logger?: Logger;
    backupUrl?: string;
    language?: string;
    tracer?: any;
  }) {
    super();
    this.uuid = ulid();
    this.logger = options.logger || (console as unknown as Logger);
    this.url = options.url;
    this.backupUrl = options.backupUrl || options.url;
    this.language = options.language || 'zh-CN';
    this.tracer = options.tracer;
    this.wsInterface = options.wsInterface;
    this.ws = null;
    this.status = SOCKET_STATUS.STATUS_DISCONNECTED;
    this.closed = false;
    this.recoverAttempts = 0;
    this.heartbeatTimer = null;

    this.readyAwait = new Promise((resolve) => {
      this.readyResolve = resolve;
    });
  }

  /**
   * 登录包括websocket open、login和heartbeat command
   */
  login({
    sdkAppId,
    userId,
    loginType = 'login',
    sessionKey,
  }: {
    sdkAppId: string;
    userId: string;
    sessionKey?: string;
    loginType?: 'login' | 'relogin';
    loginReject?: (e: any) => void;
  }) {
    this.recordTrace();
    this.logger.info({
      msg: 'login',
      attributes: {
        loginType,
      },
    });
    return new Promise((resolve, reject) => {
      if (this.status === SOCKET_STATUS.STATUS_CONNECTED) {
        this.logger.debug('already connected');
        return resolve(this.lastHeartBeat);
      }
      if (this.status === SOCKET_STATUS.STATUS_CONNECTING) {
        this.logger.debug('connecting');
        return resolve(this.lastHeartBeat);
      }

      this.sdkAppId = sdkAppId;
      this.userId = userId;
      this.sessionKey = sessionKey;
      this.closed = false;
      this.status = SOCKET_STATUS.STATUS_CONNECTING;

      if (!this.closed) {
        const url = new URL(this.url);
        url.searchParams.append('uuid', this.uuid);
        url.searchParams.append('sdk_appid', sdkAppId);
        if (this.recoverAttempts > 0) {
          url.searchParams.append('recover', `${this.recoverAttempts}`);
        }
        const socket = new this.wsInterface(url.toString(), {
          logger: this.logger,
        });
        socket.addListener('connect', this.onConnect.bind(this, resolve, reject, loginType));
        socket.addListener('data', this.onData.bind(this));
        socket.addListener('disconnect', this.onDisconnect.bind(this));
        this.ws = socket;
        socket.connect();
      } else {
        resolve('login');
      }
    });
  }
  logout(reason = 'logout') {
    this.logger.info({
      msg: 'logout()',
      attributes: {
        reason,
      },
    });

    this.closed = true;
    this.recoverAttempts = 0;

    const disconnect = () => {
      this.status = SOCKET_STATUS.STATUS_DISCONNECTED;
      this.lastHeartBeat = undefined;
      if (this.ws) {
        this.ws.disconnect();
        this.ws.removeAllListeners('connect');
        this.ws.removeAllListeners('disconnect');
        this.ws.removeAllListeners('data');
        this.ws = null;
      }
    };

    if (
      this.status === SOCKET_STATUS.STATUS_CONNECTED &&
      reason !== 'kickedOut' &&
      reason !== 'forcedOffLine' &&
      reason !== 'beforeUnload'
    ) {
      this.setStatus('offline')
        .catch(() => void 0)
        .then(disconnect);
    } else {
      disconnect();
    }
  }
  request(type: string, option?: object): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.ws || !this.ws.isConnected()) {
        return reject('socket has not been initialized');
      }
      const { userId, sdkAppId } = this;
      if (!userId || !sdkAppId) {
        return reject({
          errorCode: '-1',
          msg: 'internal error(missing userInfo)',
        });
      }
      const nonce = type + ulid();
      const requestTimer = setTimeout(() => {
        if (!this.ws || !this.ws.isConnected() || (typeof window !== 'undefined' ? !window.navigator.onLine : true)) {
          return;
        }
        this.logger.warn('socket response timeout');
        reject({
          errorCode: '-1',
          msg: 'ws timeout',
          nonce,
        });
      }, 10000);
      this.nonceTimer[nonce] = requestTimer;
      this.nonceEvent.on(nonce, (e) => {
        delete this.nonceTimer[nonce];
        clearTimeout(requestTimer);
        if (e.errorCode !== '0') {
          this.logger.warn({
            msg: 'socket response error',
            attributes: {
              message: e.msg || 'rejected',
              code: e.errorCode || '-999',
            },
          });
          if (e.errorCode === '-999') {
            this.logger.error({
              msg: 'server error',
              attributes: {
                message: e.msg || 'rejected',
                code: e.errorCode || '-999',
              },
            });
          }
          reject({
            message: e.msg || 'rejected',
            errorCode: e.errorCode || '-999',
          });
        } else {
          // trigger s2c message
          if (e.status && !nonce.startsWith('first')) {
            this.emit('heartbeat', e);
            this.lastHeartBeat = e;
          }
          resolve(e);
        }
      });
      this.ws.send(
        JSON.stringify({
          ...option,
          language: this.language,
          nonce,
          socketUUID: this.uuid,
          staff: {
            userId,
            sdkAppId,
          },
        }),
      );
    });
  }
  setStatus<K extends keyof typeof UserStatus>(status: K, restReason?: string): Promise<T> {
    return this.request('setStatus', {
      command: 'setStatus',
      status: UserStatus[status]?.toString(),
      ...(restReason ? { reason: restReason } : {}),
    });
  }

  setLanguage(language: string) {
    this.language = language;
    // TODO command
  }

  isConnected() {
    return this.status === SOCKET_STATUS.STATUS_CONNECTED;
  }
  isConnecting() {
    return this.status === SOCKET_STATUS.STATUS_CONNECTING;
  }
  private onData(data: string) {
    const msg = JSON.parse(data) as T;
    this.nonceEvent.emit(msg.nonce, msg);
    if (msg.errorCode && msg.errorCode !== '0') {
      this.logger.error(`socket response error ${msg.errorCode} ${msg.nonce}`);
    } else {
      if (msg.command === 's2c') {
        this.readyAwait.then(() => {
          this.emit('s2c', msg);
        });
      }
    }
  }
  private onConnect(
    resolve: (e: T | PromiseLike<T>) => void,
    reject: (e: T | PromiseLike<T>) => void,
    loginType: 'login' | 'relogin',
  ) {
    this.logger.debug(`onConnect ${loginType}`);
    this.request(loginType, {
      sessionKey: this.sessionKey,
      command: loginType,
    })
      .then(() => {
        this.readyResolve?.();
        this.logger.info('login success');
        return this.request('firstHeartbeat', {
          command: 'heartbeat',
        }).then(() =>
          this.request('heartbeat', {
            command: 'heartbeat',
          }),
        );
      })
      .then((e) => {
        this.logger.debug('first heartbeat success');
        this.status = SOCKET_STATUS.STATUS_CONNECTED;
        this.recoverAttempts = 0;
        this.emit('connect');
        this.doHeartBeat();
        resolve(e);
      })
      .catch((e) => {
        // login or heartbeat error
        this.logger.warn({
          msg: 'login failed',
          attributes: {
            code: e.code,
            message: e.message,
          },
        });
        this.emit('failed', { code: e.code, message: e.message });
        this.logout(e.message || 'Login failed');
        reject(e);
      });
  }
  private onDisconnect(e: CloseEvent) {
    this.logger.info({
      msg: 'onDisconnect',
      attributes: {
        code: e.code,
        reason: e.reason,
      },
    });
    this.status = SOCKET_STATUS.STATUS_DISCONNECTED;
    this.reset();
    this.emit('disconnect', { code: `${e.code}`, reason: e.reason || 'disconnect to websocket' });
    if (e.code === 1006) {
      [this.url, this.backupUrl] = [this.backupUrl, this.url];
      this.logger.debug({
        msg: 'switch url success',
        attributes: {
          url: this.url,
          backupUrl: this.backupUrl,
        },
      });
    }

    if (this.closed) {
      return;
    }

    this.reconnect();
  }
  private reconnect() {
    this.logger.info('reconnect');
    this.recoverAttempts += 1;

    let k = Math.floor(Math.random() * 2 ** this.recoverAttempts + 1);

    if (k < 1) {
      k = 1;
    } else if (k > 5) {
      k = 5;
    }

    if (this.recoverAttempts >= MAX_RECOVER_COUNT) {
      this.logger.info('exceed max recover count');
      this.logout('exceed max recover count');
      this.emit('failed', { code: '-998', message: 'Connection failed. Please check your network' });
      return;
    }

    this.logger.debug(`reconnection attempt: ${this.recoverAttempts}. next connection attempt in ${k} seconds`);

    setTimeout(() => {
      if (this.userId && this.sdkAppId && !this.closed && !(this.isConnected() || this.isConnecting())) {
        this.logger.info('try to reconnect');
        this.login({
          sdkAppId: this.sdkAppId,
          userId: this.userId,
          loginType: 'relogin',
          sessionKey: this.sessionKey,
        }).then(() => {
          this.logger.info({
            msg: 'reconnect success',
          });
        });
      }
    }, k * 1000);
  }
  // 断开当前连接，重新建立一个
  private disconnect(reason?: string) {
    this.logger.info({
      msg: 'disconnect',
      attributes: {
        reason,
      },
    });
    if (this.ws) {
      this.ws.disconnect(1000, reason);
    }
  }
  private doHeartBeat() {
    this.logger.debug('doHeartbeat');
    let heartbeatCount = 0;
    let heartbeatFailCount = 0;
    this.heartbeatTimer = setInterval(() => {
      const { sessionKey } = this;
      if (!sessionKey) {
        // trigger expired error event;
        this.logger.warn('socket expired');
        this.logout('socket expired');
      }
      if (heartbeatFailCount >= MAX_HEARTBEAT_FAIL_COUNT) {
        this.logger.warn(`heartbeat failed count exceed ${heartbeatFailCount} times`);
        this.disconnect('heartbeat timeout');
      }

      this.request('heartbeat', {
        command: 'heartbeat',
      })
        .then(() => {
          heartbeatFailCount = 0;
          heartbeatCount += 1;
          if (heartbeatCount % 24 === 0) {
            this.logger.debug(`heartbeat success: ${heartbeatCount}`);
          }
        })
        .catch((e) => {
          heartbeatFailCount = heartbeatFailCount + 1;
          this.logger.info({
            msg: 'heartbeat error',
            attributes: {
              message: e.message,
            },
          });
        });
    }, 5000);
  }

  private reset() {
    this.logger.debug('reset()');
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
    Object.values(this.nonceTimer).forEach((nonce) => {
      clearTimeout(nonce);
    });
    this.nonceTimer = {};
  }

  private recordTrace() {
    if (!this.tracer) return;
    const span = this.tracer.startSpan('tcccWebsocket', {
      attributes: { uuid: this.uuid, userId: this.userId, sdkAppId: this.sdkAppId, recover: this.recoverAttempts },
    });

    const failedCallback = (e: { reason: string; code: string } | { message: string; code: string }) => {
      if (span.isRecording()) {
        if ('message' in e) {
          span.setAttributes({ code: e.code, reason: e.message });
          span.recordException(new Error(e.message));
        } else {
          span.setAttributes({ code: e.code, reason: e.reason });
          span.recordException(new Error(e.reason));
        }
        span.end();
      }
    };
    const successCallback = () => {
      if (span.isRecording()) {
        span.end();
      }
    };

    this.once('failed', failedCallback);
    this.once('connect', successCallback);
    this.once('disconnect', failedCallback);
  }
}
