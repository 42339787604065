import zip from 'lodash/zip';

export function getIvrPath({ keyPress, ivrPressLabel }: { keyPress?: string; ivrPressLabel?: string[] }) {
  if (!keyPress || !ivrPressLabel) return [];
  const ivrNumber = Array.from(keyPress).filter((c) => c === '*' || c === '#' || Number.isInteger(Number(c)));
  return zip(ivrNumber, ivrPressLabel).map(([key, label]) => ({
    key,
    label,
  }));
}
