import * as t from 'io-ts';
import keyBy from 'lodash/keyBy';
import { getOrPromiseReject } from 'tccc-utils';
import { getLogger } from '../../common/Logger';
import { InvalidParamsError, NotFoundError } from '../../common/TcccError';
import { unknown2string } from '../../common/unknown2string';
import { SessionType } from '../../constants/sessions';
import i18next from '../../i18n/i18next.config';
import { TcccSdk } from '../../tccc';
import { createAsyncThunk, unwrapResult } from '../createAsyncThunk';
import { Session } from './index';
import { getUnreadCountList } from './tim.thunk';
import { transformActiveSessionItemToSession } from './utils';

export const SessionIdParams = t.type({
  sessionId: t.string,
});

export const getSessionList = createAsyncThunk('sessions/getSessionList', async (arg: { tccc: TcccSdk }) => {
  if (!arg.tccc.Agent.userInfo) {
    throw new Error(i18next.t('Authorization failed'));
  }
  const { userId } = arg.tccc.Agent.userInfo;

  const { numberReflectMode } = arg.tccc.Agent.settings;
  try {
    const { sessionList } = await arg.tccc.http.request('/ccc/im/getActiveSession');
    let normalSessions: (Session & { duration: number; timestamp: number })[] = [];
    for (const item of sessionList) {
      const session = transformActiveSessionItemToSession(item, userId, numberReflectMode);

      const existSession = normalSessions.find((item) => item.sessionId === session.sessionId);
      if (existSession) {
        if (existSession.state === 'finished') {
          normalSessions = normalSessions.filter((item) => item.sessionId !== existSession.sessionId);
          if (session.state !== 'finished') {
            normalSessions.push(session);
          } else {
            normalSessions.push(existSession);
          }
        }
      } else {
        normalSessions.push(session);
      }
    }
    const inProgressIMUserId: string[] = normalSessions.reduce((acc, session) => {
      if (session.type === 'im' && session.state === 'inProgress' && session.userId) {
        const groupId = session.imGroupId || session.userId;
        return [...acc, groupId];
      }
      return acc;
    }, [] as string[]);
    const unreadCountList = inProgressIMUserId.length
      ? await getUnreadCountList({ tccc: arg.tccc, groupIdList: inProgressIMUserId }).then(unwrapResult)
      : [];
    const unreadCountMap = keyBy(unreadCountList, 'userId');
    const response = normalSessions.map((session) => {
      if (session.userId && session.state === 'inProgress' && unreadCountMap[session.userId]) {
        return {
          ...session,
          unreadCount: unreadCountMap[session.userId].unreadCount,
        };
      }
      return session;
    });

    response.forEach((session) => {
      if (['phone', 'voip', 'internal'].includes(session.type)) {
        arg.tccc.Call.upsertOne(session);
      } else {
        arg.tccc.Chat.upsertOne(session);
      }
    });

    return response;
  } catch (e) {
    throw new Error(unknown2string(e));
  }
});

export const giveUpSession = createAsyncThunk(
  'sessions/giveUpSession',
  async (params: t.TypeOf<typeof SessionIdParams> & { deviceError?: boolean; emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const logger = getLogger(params.emitter.Agent.userInfo);
    const { userInfo } = params.emitter.Agent;
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    let setRest = false;
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    const session = params.emitter.Chat.selectOne(sessionId) || params.emitter.Call.selectOne(sessionId);
    if (!session) {
      throw new NotFoundError('session', sessionId);
    }
    if (session.type === SessionType.im || session.type === SessionType.video) {
      // 在线客服-小程序类型(全媒体使用旧接口)，视频客服使用新接口
      if (session?.peerSource === '2' && session.type !== SessionType.video) {
        const res = await params.emitter.http.request('/ccc/seatGiveUp', {
          staff: {
            sdkAppId: userInfo.sdkAppId,
            userId: userInfo.userId,
          },
          attendee: {
            userId: session.userId!,
          },
          roomId: session.roomId!,
          sessionId,
        });
        setRest = res.setRest ?? false;
      } else {
        const res = await params.emitter.http.request('/ccc/im/seatGiveUp', {
          sessionId,
        });
        setRest = res.setRest ?? false;
      }
      params.emitter.Chat.updateOne(sessionId, {
        status: '400',
      });
    } else {
      const res = await params.emitter.http.request('/ccc/seatGiveUp', {
        sessionId,
      });
      setRest = res.setRest ?? false;
      params.emitter.Call.updateOne(sessionId, { status: '400' });
    }
    logger.info(`giveUpSession: ${sessionId} success`, `setRest: ${setRest}`);
    return { ...session, setRest };
  },
);

export const deleteSession = createAsyncThunk(
  'sessions/deleteSession',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    await params.emitter.http.request('/ccc/im/completeSession', {
      sessionId,
    });
    params.emitter.Call.removeOne(sessionId);
    params.emitter.Chat.removeOne(sessionId);

    return {
      sessionId,
    };
  },
);
