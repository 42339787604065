import * as t from 'io-ts';

export enum NumberReflectMode {
  // 后台已经没有close这个类型了，但是前端需要
  close,
  partial,
  global,
}

export const NumberReflectModeC = t.keyof({
  [NumberReflectMode.close]: 0,
  [NumberReflectMode.partial]: 1,
  [NumberReflectMode.global]: 2,
});
