import * as t from 'io-ts';
import i18next from '../i18n/i18next.config';
export enum Direction {
  callIn,
  callOut,
}

export enum UseMobileCallOut {
  off,
  on,
}
export enum UseMobileAccept {
  off,
  offlineOnly,
  always,
}

export enum SessionType {
  phone = 'phone',
  im = 'im',
  internal = 'internal',
  voip = 'voip',
  video = 'video',
}

export enum SessionStatus {
  ringing = '100',
  inProgress = '200',
  finished = '400',
}

export const hangupRecord = new Map([
  [0, i18next.t('正常结束')],
  [1, i18next.t('正常结束')],
  [2, i18next.t('未接通')],
  [104, i18next.t('用户挂断')],
  [202, i18next.t('未接听')],
  [203, i18next.t('拒接挂断')],
  [204, i18next.t('关机')],
  [205, i18next.t('空号')],
  [206, i18next.t('占线')],
  [207, i18next.t('欠费')],
  [208, i18next.t('外呼线路异常')],
  [209, i18next.t('主叫取消')],
  [210, i18next.t('不在服务区')],
  [211, i18next.t('客户端错误')],
]);

export const HangupType = t.union([
  t.literal(0, hangupRecord.get(0)),
  t.literal(1, hangupRecord.get(1)),
  t.literal(2, hangupRecord.get(2)),
  t.literal(104, hangupRecord.get(104)),
  t.literal(202, hangupRecord.get(202)),
  t.literal(203, hangupRecord.get(203)),
  t.literal(204, hangupRecord.get(204)),
  t.literal(205, hangupRecord.get(205)),
  t.literal(206, hangupRecord.get(206)),
  t.literal(207, hangupRecord.get(207)),
  t.literal(208, hangupRecord.get(208)),
  t.literal(209, hangupRecord.get(209)),
  t.literal(210, hangupRecord.get(210)),
  t.literal(211, hangupRecord.get(211)),
]);
export const HangupSide = t.union([
  t.undefined,
  t.literal('', '座席挂断'),
  t.literal('seat', '座席挂断'),
  t.literal('user', '用户挂断'),
  t.literal('system', '系统挂断'),
]);
