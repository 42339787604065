import * as t from 'io-ts';
import { unifiedTRTCParams } from '../../socket/s2cEvent';
import { booleanFromNumber0or1, nullableArray, stringFromNumber } from 'tccc-utils';

export const numberList = nullableArray(
  t.type({
    number: t.string,
    location: t.string,
  }),
);

export const pstnAPIs = {
  '/ccc/pstn/seatin': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({
      errorCode: t.string,
    }),
  },
  '/ccc/pstn/getStaffCanCalloutNumberList': {
    Input: t.type({}),
    Output: t.type({
      numberList,
    }),
  },
  '/ccc/pstn/deflect': {
    Input: t.intersection([
      t.type({
        sessionId: t.string,
        callee: t.union([
          t.type({
            userId: t.string,
          }),
          t.type({
            skillGroupId: t.union([t.number, stringFromNumber]),
          }),
          t.type({
            phone: t.string,
          }),
        ]),
      }),
      t.partial({
        allowQueue: t.boolean,
      }),
    ]),
    Output: t.type({
      errorCode: t.string,
    }),
  },
  '/ccc/pstn/monitor': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.intersection([
      t.type({
        roomId: t.string,
        caller: t.string,
        callee: t.string,
        direction: t.union([t.literal(1), t.literal(0)]),
        seat: t.string,
        privateMapKey: t.string,
        userSig: t.string,
      }),
      t.partial({
        unifiedTRTCParams,
      }),
    ]),
  },
  '/ccc/pstn/dialBack': {
    Input: t.type({
      sessionId: t.string,
      servingNum: t.union([t.string, t.undefined]),
    }),
    Output: t.intersection([
      t.type({
        sessionId: t.string,
        roomId: t.string,
        userSig: t.string,
        userMark: t.string,
        servingNumber: t.string,
        privateMapKey: t.string,
      }),
      t.partial({
        useMobile: t.boolean,
        useExtension: t.boolean,
        phone: t.string,
        protectedPhone: t.string,
        unifiedTRTCParams,
      }),
    ]),
  },
  '/ccc/pstn/end': {
    Input: t.intersection([
      t.type({
        sessionId: t.string,
      }),
      t.partial({
        error: t.union([t.string, t.boolean]),
      }),
    ]),
    Output: t.type({}),
  },
  '/ccc/pstn/sendDtmf': {
    Input: t.type({
      keyPress: t.string,
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/getPSTNActiveSessionList': {
    Input: t.type({}),
    Output: t.type({
      total: t.string,
      sessions: nullableArray(
        t.type({
          callType: t.union([t.literal('callin'), t.literal('callout')]),
          callee: t.string,
          caller: t.string,
          roomId: t.string,
          sessionId: t.string,
          sessionStatus: t.string,
          staffNo: t.string,
          staffUserId: t.string,
          startTimestamp: t.string,
          acceptTimestamp: t.string,
          ringTimestamp: t.string,
        }),
      ),
    }),
  },
  '/ccc/seatGiveUp': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.partial({
      setRest: booleanFromNumber0or1,
    }),
  },
  '/ccc/pstn/startForwardingOuter': {
    Input: t.type({
      sessionId: t.string,
      servingNumber: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/giveUpForwardingOuter': {
    Input: t.type({
      sessionId: t.string,
      servingNumber: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/leaveForwardingOuter': {
    Input: t.type({
      sessionId: t.string,
      servingNumber: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/restoreForwardingOuter': {
    Input: t.type({
      sessionId: t.string,
      servingNumber: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/triggerIvr': {
    Input: t.intersection([
      t.type({
        sessionId: t.string,
      }),
      t.partial({
        clientData: t.string,
        ivrId: t.string,
        selfId: t.number,
        param: t.string,
      }),
    ]),
    Output: t.type({}),
  },
  '/ccc/pstn/onHold': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/offHold': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/startAsr': {
    Input: t.type({
      roomId: t.number,
      userId: t.string,
    }),
    Output: t.type({
      url: t.string,
    }),
  },
  '/ccc/pstn/stopAsr': {
    Input: t.type({
      roomId: t.number,
      userId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/transferHost': {
    Input: t.type({
      userId: t.string,
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/dial': {
    Input: t.intersection([
      t.type({
        callee: t.union([
          t.type({
            userId: t.string,
          }),
          t.type({
            skillGroupId: t.number,
          }),
          t.intersection([
            t.type({
              phone: t.string,
            }),
            t.partial({
              phoneEncodeType: t.union([t.literal('base64'), t.literal('reflect'), t.literal('number')]),
            }),
          ]),
        ]),
      }),
      t.partial({
        skillGroupId: t.string,
        servingNumberGroupIds: t.array(t.string),
        servingNum: t.string,
        uui: t.string,
        useMobile: t.boolean,
      }),
    ]),
    Output: t.intersection([
      t.type({
        sessionId: t.string,
      }),
      t.partial({
        userMark: t.string,
        servingNumber: t.string,
        useMobile: t.boolean,
        useExtension: t.boolean,
        phone: t.string,
        protectedPhone: t.string,
        unifiedTRTCParams,
      }),
    ]),
  },
  '/ccc/pstn/singleStepConference': {
    Input: t.intersection([
      t.type({
        sessionId: t.string,
        callee: t.union([
          t.type({
            userId: t.string,
          }),
          t.type({
            skillGroupId: t.string,
          }),
          t.type({
            phone: t.string,
          }),
        ]),
      }),
      t.partial({
        skillGroupId: t.number,
        servingNumberGroupIds: t.array(t.string),
        servingNum: t.string,
        uui: t.string,
        allowQueue: t.boolean, // 默认false
      }),
    ]),
    Output: t.type({}),
  },
  '/ccc/pstn/kick': {
    Input: t.type({
      memberId: t.string,
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/muteMember': {
    Input: t.type({
      memberId: t.string,
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/unmuteMember': {
    Input: t.type({
      memberId: t.string,
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/mute': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/unmute': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
};
