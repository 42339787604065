import EventEmitter from 'eventemitter3';
import { ulid } from 'ulidx';
import { Timer } from 'tccc-utils';
import isFunction from 'lodash/isFunction';
import { RtcSocket } from './socket/socket';
import i18next from './i18n/i18next.config';
import { Call } from './tccc/Call';
import { Chat } from './tccc/Chat';
import { Video } from './tccc/Video';
import { Devices } from './tccc/Devices';
import { Agent } from './tccc/Agent';
import { Proxy } from './tccc/Proxy';
import { TcccEvents, ExternalTcccEvents, IframeSdkEvents, CompatibleEvents } from './tccc/events';
import { getSessionList } from './store/sessions/sessions.thunk';
import { TIM } from './tim/tim';
import { ASR } from './tccc/ASR';
import { WatchMicrophonesPermissionChange } from './trtc/Permission';
import { TcccPlugin } from './plugin';
import { Request } from './http';
import { getLogger } from './common/Logger';
import { reportScriptLoadTime } from './utils/reportScriptLoadTime';
export { Request } from './http';
export { default as TIMSDK } from '@tencentcloud/chat';
export { unwrapResult, wrapError, wrapResult } from './store/createAsyncThunk';
export * from './plugin';
export type {
  Message,
  TextMessage,
  FileMessage,
  FaceMessage,
  ImageMessage,
  LocationMessage,
  VideoMessage,
  CustomMessage,
  CreateMessageParams,
  CreateAudioMessageParams,
  CreateLocationMessageParams,
  CreateFaceMessageParams,
  CreateCustomMessageParams,
  CreateVideoMessageParams,
  CreateFileMessageParams,
  CreateTextMessageParams,
  CreateImageMessageParams,
} from './tim/tim';
export * from './tccc/events';
export type TcccEventEmitter = EventEmitter<TcccEvents>;

type KeyOfMap<M extends Map<unknown, unknown>> = M extends Map<infer K, unknown> ? K : never;
type Newable<T> = new (...args: any[]) => T;
/**
 * 对外API
 */
type ExternalEventTypes<K> = {
  [T in keyof K]: T;
};
const instance: Record<string, TcccSdk> = {};
export class TcccSdk extends EventEmitter<TcccEvents & ExternalTcccEvents> {
  static createAgent = async ({
    sdkAppId,
    userId,
    token,
    origin,
  }: {
    sdkAppId: string;
    userId: string;
    token?: string;
    origin: string;
  }) => {
    if (sdkAppId && userId && instance[sdkAppId + userId]) return instance[sdkAppId + userId];
    const agent = new TcccSdk({ origin });
    Object.assign(instance, {
      [sdkAppId + userId]: agent,
    });
    await agent.Agent.createAgent({ sdkAppId, userId, token, origin });
    return agent;
  };

  socket: RtcSocket | null;
  tim: TIM | null;
  uuid = ulid();
  readonly timer: Timer;
  readonly http: Request;
  public Agent: Agent;
  public Call = new Call(this);
  public Chat = new Chat(this);
  public Video = new Video(this);
  public Asr = new ASR(this);
  public Devices = new Devices(this);
  public Proxy = new Proxy();
  public UI: any;
  public events: ExternalEventTypes<IframeSdkEvents> = {
    asr: 'asr',
    autoTransfer: 'autoTransfer',
    callIn: 'callIn',
    callOuted: 'callOuted',
    calloutAccepted: 'calloutAccepted',
    dialogStateChanged: 'dialogStateChanged',
    forcedOffline: 'forcedOffline',
    imMessage: 'imMessage',
    imMessagePosted: 'imMessagePosted',
    kickedOut: 'kickedOut',
    liveLocalLevel: 'liveLocalLevel',
    memberStateChanged: 'memberStateChanged',
    messageReceived: 'messageReceived',
    networkQuality: 'networkQuality',
    onlineStateChanged: 'onlineStateChanged',
    ready: 'ready',
    receiveStatusChanged: 'receiveStatusChanged',
    sendMessage: 'sendMessage',
    sessionCompleted: 'sessionCompleted',
    sessionEnded: 'sessionEnded',
    statusChanged: 'statusChanged',
    tokenExpired: 'tokenExpired',
    transfer: 'transfer',
    userAccessed: 'userAccessed',
    userChanged: 'userChanged',
    userSettingsUpdated: 'userSettingsUpdated',
  };
  private _plugins: Map<string, TcccPlugin> = new Map();

  constructor(params: { origin: string; query?: string }) {
    super();
    this.socket = null;
    this.tim = null;
    this.timer = new Timer();
    this.Agent = new Agent(this, params.origin);
    this.http = new Request(this, this.Agent);

    WatchMicrophonesPermissionChange();
    CompatibleEvents(this, this);
    reportScriptLoadTime();
  }
  public getConversationList() {
    return getSessionList({ tccc: this });
  }

  changeLanguage(lang: string) {
    if (!['zh', 'en'].includes(lang)) {
      throw new Error(i18next.t('language not supported'));
    }
    if (isFunction(this.UI?.changeLanguage)) {
      this.UI.changeLanguage(lang);
    }
    this.Agent.changeLanguage(lang);
    return i18next.changeLanguage(lang);
  }

  isEnvSupported: () => boolean = () => {
    const isLocal = window.location.hostname === 'localhost';
    const isHttps = window.location.protocol === 'https:';

    return isLocal || isHttps;
  };
  isBrowserSupported() {
    return this.Devices.isBrowserSupported();
  }
  getDevices() {
    return this.Devices.getDevices();
  }
  getMicrophones() {
    return this.Devices.getMicrophones();
  }
  getSpeakers() {
    return this.Devices.getSpeakers();
  }
  getServingPhoneCallList() {
    return Promise.resolve({ sessions: [] });
  }
  callOut(...args: Parameters<Call['startOutboundCall']>) {
    return this.Call.startOutboundCall(...args);
  }
  endSession(...args: Parameters<Call['end']>) {
    return this.Call.end(...args);
  }
  hold(...args: Parameters<Call['hold']>) {
    return this.Call.hold(...args);
  }
  unhold(...args: Parameters<Call['unHold']>) {
    return this.Call.unHold(...args);
  }
  transfer(...args: Parameters<Call['transfer']>) {
    return this.Call.transfer(...args);
  }
  online() {
    return this.Agent.online();
  }
  offline() {
    return this.Agent.offline();
  }
  /**
   * @deprecated
   */
  changeStatus(...args: Parameters<Agent['setStatus']>) {
    return this.Agent.setStatus(...args);
  }
  /**
   * @deprecated
   */
  changeMicStatus({ sessionId, micStatus }: { sessionId: string; micStatus: 'on' | 'off' }) {
    if (micStatus === 'off') {
      return this.Call.mute({ sessionId });
    }
    return this.Call.unmute({ sessionId });
  }
  /**
   * @deprecated
   */
  sendDigits({ sessionId, dtmfText }: { sessionId: string; dtmfText: string }) {
    return this.Call.sendDigits({ sessionId, digits: dtmfText });
  }

  /**
   * @deprecated
   */
  setActiveUser() {}
  /**
   * @deprecated
   */
  deleteCallInfo() {}
  /**
   * @deprecated
   */
  accessUser(sessionId: string) {
    return this.Call.accept({ sessionId });
  }
  /**
   * @deprecated
   */
  getUserList() {
    return [];
  }
  getConversations() {
    return [];
  }
  /**
   * @deprecated
   */
  muteVideo(params: { sessionId: string }) {
    return this.Video.muteVideo(params);
  }
  /**
   * @deprecated
   */
  unmuteVideo(params: { sessionId: string }) {
    return this.Video.muteVideo(params);
  }
  /**
   * @deprecated
   */
  monitorServingPhoneCall(params: { sessionId: string; id?: string; phoneDesc?: string; remark?: string }) {
    if (isFunction(this.UI?.monitorServingPhoneCall)) {
      return this.UI.monitorServingPhoneCall({
        sessionId: params.id || params.sessionId,
        remark: params.phoneDesc || params.remark,
      });
    }
    return this.Call.monitorCall({ sessionId: params.id || params.sessionId });
  }

  /**
   * @deprecated
   */
  exitMonitor(params?: { sessionId: string }) {
    // return this.Call.exitMonitor()
    if (isFunction(this.UI?.monitorServingPhoneCall)) {
      return this.UI.exitMonitor({
        sessionId: params?.sessionId,
      });
    }
    return Promise.reject();
  }

  overrideButtonConfig(params: any) {
    if (isFunction(this.UI?.overrideButtonConfig)) {
      return this.UI.overrideButtonConfig(params);
    }
  }

  addPlugin<T>(Plugin: Newable<TcccPlugin>, scriptArgs?: any) {
    let plugin: TcccPlugin<T>;
    try {
      plugin = new Plugin({
        core: this,
        request: this.http.request,
        logger: getLogger,
        settings: this.Agent.settings,
        userInfo: this.Agent.userInfo!,
        scriptArgs,
      });
    } catch (e) {
      console.error('add plugin failed', e);
      throw new Error('add plugin failed');
    }
    if (isFunction(plugin.getName) && plugin.getName()) {
      const name = plugin.getName();
      if (this._plugins.has(name)) {
        throw new Error('Plugin already exist');
      }
      this._plugins.set(name, plugin);
    }
    if (isFunction(plugin.getNameSpace) && plugin.getNameSpace()) {
      const namespace = plugin.getNameSpace() as KeyOfMap<typeof this._plugins>;
      this._plugins.set(namespace, plugin);
      Object.assign(this, {
        [namespace]: plugin.getMethods(),
      });
    }
  }
}
