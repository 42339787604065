/// <reference types="@types/sharedworker" />
import Logger from 'tccc-logger';
import { ulid } from 'ulidx';
import { WorkerPort } from './worker/port';

const isWorker = typeof window === 'undefined';
if (isWorker) {
  const isInternational = self.name === 'tccc-worker-intl';
  const workerUUID = ulid();
  const logger = new Logger({
    url: `https://${isInternational ? 'otlp.connect.tencentcloud.com' : 'otlp.tccc.qcloud.com'}/v1/logs`,
    id: isInternational ? 'tcccsg' : 'tccc',
    name: self.name,
    attributes: {
      workerUUID,
    },
  });
  logger.info('start worker');
  // @ts-ignore
  self.connections = [];
  self.onconnect = function (event) {
    logger.info('onconnect');
    const port = event.ports[0];
    // @ts-ignore
    self.connections.push(
      new WorkerPort({
        port,
        workerUUID,
      }),
    );
  };
}

export default class {
  options?: WorkerOptions;
  constructor(options?: WorkerOptions) {
    this.options = options;
  }
}
