import { getLogger } from '../../common/Logger';
import omit from 'lodash/omit';

export const CreateLogger = (
  type: string,
  params: { sessionId?: string; memberId?: string; mode?: string } & Record<string, string> = {
    sessionId: '',
    memberId: '',
    mode: 'memberEvent',
  },
  sdk: { sdkAppId: string; userId: string } | null,
) => {
  const commonLogger = getLogger(sdk);
  return {
    info: (...args: any[]) => {
      commonLogger.custom.call(
        commonLogger,
        { level: 'info', sessionid: params.sessionId, ...omit(params, 'sdk') },
        `[${type}]`,
        ...args,
      );
    },
    debug: (...args: any[]) => {
      commonLogger.custom.call(
        commonLogger,
        { level: 'debug', sessionid: params.sessionId, ...omit(params, 'sdk') },
        `[${type}]`,
        ...args,
      );
    },
    warn: (...args: any[]) => {
      commonLogger.custom.call(
        commonLogger,
        { level: 'warn', sessionid: params.sessionId, ...omit(params, 'sdk') },
        `[${type}]`,
        ...args,
      );
    },
    error: (...args: any[]) => {
      commonLogger.custom.call(
        commonLogger,
        { level: 'error', sessionid: params.sessionId, ...omit(params, 'sdk') },
        `[${type}]`,
        ...args,
      );
    },
    report: (...args: any[]) => {
      commonLogger.custom.call(
        commonLogger,
        { level: 'error', sessionid: params.sessionId, ...omit(params, 'sdk') },
        `[${type}]`,
        ...args,
      );
    },
  };
};
