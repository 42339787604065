import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { i18nLanguage } from 'tccc-utils';
import I18nextLazyPlugin from './i18next.lazy';

enum I18nLanguage {
  en = 'en',
  zh = 'zh',
}

const BackendLanguageMap = {
  [I18nLanguage.zh]: 'zh-CN',
  [I18nLanguage.en]: 'en-US',
};

export const getBackendLanguage = (language: string) =>
  BackendLanguageMap[language as I18nLanguage] || BackendLanguageMap.zh;

i18next.use(I18nextLazyPlugin).use(LanguageDetector).init({
  fallbackLng: 'en',
  defaultNS: 'translation',
  lng: i18nLanguage.language,
});
export default i18next;
