import { getLogger } from '../../common/Logger';
import { TcccSdk } from '../../tccc';
import { AsrParser } from './AsrParser';
export class TencentASRSocket {
  socket?: WebSocket;
  side: 'seat' | 'client';
  sessionId: string;
  emitter: TcccSdk;
  asrParser: AsrParser;
  logger: ReturnType<typeof getLogger>;
  constructor({
    side,
    emitter,
    sessionId,
    roomId,
    userId,
  }: {
    side: 'seat' | 'client';
    emitter: TcccSdk;
    sessionId: string;
    userId: string;
    roomId: string;
  }) {
    this.side = side;
    this.emitter = emitter;
    this.logger = getLogger(emitter.Agent.userInfo);
    this.sessionId = sessionId;
    this.asrParser = new AsrParser({ side, sessionId, emitter, roomId, userId });
  }

  start = (url: string) => {
    this.#createSocket(url);
  };

  send = (data: any) => {
    const isWsActive = WebSocket.OPEN === this.socket?.readyState;
    if (!isWsActive) {
      return;
    }
    if (!this.socket) {
      this.logger.error('tencent asr socket was empty');
      return;
    }
    this.socket.send(data);
  };

  close = () => {
    // 关闭前安全起见执行一次重置
    this.socket?.close();
  };

  stop = () => {
    this.close();
  };

  #createSocket = (url: string) => {
    // 单例
    if (
      this.socket &&
      (this.socket.readyState === WebSocket.OPEN || this.socket?.readyState === WebSocket.CONNECTING)
    ) {
      this.logger.error('tencent asr old socket still open!');
      this.socket.close();
    } else if (WebSocket.CLOSING === this.socket?.readyState) {
      this.logger.error('tencent asr old socket not closed yet!');
      return;
    }
    this.socket = new WebSocket(url);
    this.socket.binaryType = 'blob';
    this.socket.addEventListener('error', this.#handleError);
    this.socket.addEventListener('open', this.#handleOpen);
    this.socket.addEventListener('close', this.#handleClose);
    this.socket.addEventListener('message', this.#handleMessage);
  };

  #handleOpen = () => {
    this.logger.info('tencent asr websocket open');
  };

  #handleError = (event: Event) => {
    this.logger.error(`tencent asr socket 长连接操作错误: ${event?.type}`);
    this.stop();
  };

  #handleClose = () => {
    this.logger.info('tencent asr websocket close', `side: ${this.side}`);
    this.close();
  };

  #handleMessage = (event: any) => {
    try {
      const msgBody = JSON.parse(event.data);
      if (msgBody.code !== 0) {
        throw new Error(`tencent asr recognize error: ${msgBody.message}`);
      }
      if (msgBody?.message !== 'success' || !msgBody?.result) return;
      this.asrParser.handleRecognizeMessages(msgBody.result);
    } catch (e) {
      this.logger.error(e);
    }
  };
}
