import { Span } from '@opentelemetry/api';
import i18next from '../i18n/i18next.config';

export class TcccError extends Error {
  constructor(message: string) {
    super(message);
    this.name = new.target.name;
    if (typeof (Error as any).captureStackTrace === 'function') {
      (Error as any).captureStackTrace(this, new.target);
    }
    if (typeof Object.setPrototypeOf === 'function') {
      Object.setPrototypeOf(this, new.target.prototype);
    } else {
      (this as any).__proto__ = new.target.prototype;
    }
  }
}

type Code = 400 | 404;

export class InvalidParamsError extends Error {
  code: Code;
  parameter: string;
  value: any;
  constructor(parameter: string, value: any, span?: Span) {
    super();
    this.name = 'INVALID_PARAMS_ERROR';
    this.code = 400;
    this.parameter = parameter;
    this.message = `${
      !value
        ? i18next.t('Missing parameter {{parameter}}', { parameter })
        : i18next.t('Invalid value for parameter {{parameter}}', { parameter })
    } - ${value}`;

    if (span) {
      span.recordException(this);
      span.setAttributes({
        'error.name': this.name,
        'error.code': this.code,
        'error.message': this.message,
      });
    }
  }
}

export class NotFoundError extends Error {
  code: Code;
  parameter: string;
  value: any;

  constructor(parameter: string, value: any, span?: Span) {
    super();
    this.code = 404;
    this.name = 'NOT_FOUND_ERROR';
    this.parameter = parameter;
    this.value = value;
    this.message = i18next.t('{{parameter}} is not found', { parameter });

    if (span) {
      span.recordException(this);
      span.setAttributes({
        'error.name': this.name,
        'error.code': this.code,
        'error.message': this.message,
      });
    }
  }
}

export class RequestError extends Error {
  code: string;
  constructor(code: string, message: string) {
    super();
    this.code = code;
    this.name = 'HTTP_ERROR';
    this.message = message;
  }
}
