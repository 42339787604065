import { BackendModule } from 'i18next';
const I18nextLazyPlugin: BackendModule = {
  type: 'backend',
  // init(services, backendOptions, i18nextOptions) {},
  init() {},
  read(language, namespace, callback) {
    import(
      /* webpackChunkName: "i18n/[request]" */ `tccc-i18n/translations/${language === 'en' ? 'en-US' : 'zh-CN'}.json`
    ).then((obj) => {
      callback(null, obj);
    });
  },

  // save(language, namespace, data) {},
  save() {},

  // create(languages, namespace, key, fallbackValue) {
  create() {
    /* save the missing translation */
  },
};

export default I18nextLazyPlugin;
