import { TcccSdk } from '../tccc';
import { Session } from '../store/sessions';
import { BaseClass } from './BaseClass';
export class SessionAdapter implements BaseClass {
  sdk: TcccSdk;
  sessions: Record<string, Session>;
  constructor(sdk: TcccSdk) {
    this.sdk = sdk;
    this.sessions = {};
  }
  upsertOne(changes: Session) {
    if (this.sessions[changes.sessionId]) {
      this.updateOne(changes.sessionId, changes);
    } else {
      this.sessions[changes.sessionId] = changes;
    }
  }
  upsertMany(changes: Session[]) {
    changes.forEach((session) => this.upsertOne(session));
  }
  updateOne(id: string, changes: Partial<Session>) {
    if (this.sessions[id]) {
      this.sessions[id] = {
        ...this.sessions[id],
        ...changes,
      };
    }
  }
  selectOne(id: string): Session | null {
    return this.sessions[id];
  }
  removeOne(id: string) {
    delete this.sessions[id];
  }
  selectAll() {
    return Object.values(this.sessions);
  }
  reset(): void {
    this.sessions = {};
  }
}
