import logger from '../common/Logger';

/**
 * 查询用户麦克风权限，并检测更新状态
 */
export const WatchMicrophonesPermissionChange = () => {
  try {
    const name = 'microphone' as PermissionName;
    navigator.permissions.query({ name }).then((res) => {
      logger.info(`microphone permission state is ${res.state}`);
      res.addEventListener('change', (e) => {
        // @ts-ignore
        const newState = e.target?.state as PermissionState;
        logger.info(`microphone state change to ${newState}`);
      });
    });
  } catch (e) {
    logger.info('WatchMicrophonesPermissionChange error', (e as Error).message);
  }
};
