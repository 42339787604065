import { isLocal } from 'tccc-env/src/env';

const getMessage = (message: unknown) => {
  let errMsg: string;
  if (typeof message === 'string') {
    errMsg = message;
  } else if (
    typeof message === 'object' &&
    message !== null &&
    'message' in message &&
    typeof (message as any).message === 'string'
  ) {
    errMsg = (message as any).message;
  } else {
    errMsg = 'Unknown error';
  }
  return errMsg;
};

export class TCCCError extends Error {
  code: number | string;
  errorMsg: string;
  errorCode: string;

  constructor(message: unknown, error?: any) {
    const errMsg = getMessage(message);
    super(errMsg);
    this.code = error?.code || -1;
    this.errorCode = `${this.code}`;
    this.errorMsg = errMsg;
    Object.setPrototypeOf(this, TCCCError.prototype);
  }
  toString(): string {
    return `TCCCError: ${this.errorMsg} (code: ${this.code})`;
  }
}

function logError(error: unknown) {
  if (isLocal) {
    console.error(getMessage(error));
  }
}

export type WrapResult<T> = { status: string; data: T };

export const wrapResult = <T>(data: T): WrapResult<T> => ({
  status: 'success',
  data,
});
export const unwrapResult = <T>(data: WrapResult<T>): T => data.data;
export const wrapError = (error: any) => {
  const message = getMessage(error);
  const throwData = {
    name: error.name || 'Error',
    errorMsg: message, // 兼容旧错误信息
    message,
    code: error.code,
    status: 'error',
  };
  return throwData;
};

export function createAsyncThunk<T, A extends any[]>(
  name: string,
  fn: (...args: A) => Promise<T> | T,
): (...args: A) => Promise<WrapResult<T>> {
  return async function (...args: Parameters<typeof fn>) {
    try {
      return await Promise.resolve(fn(...args)).then(wrapResult);
    } catch (error: any) {
      const message = getMessage(error);
      const throwData = {
        name: error.name || 'Error',
        errorMsg: message, // 兼容旧错误信息
        message,
        code: error.code,
        status: 'error',
      };
      logError(error);
      throw throwData;
    }
  };
}
