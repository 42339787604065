import Aegis, { WebConfig } from 'aegis-web-sdk';
export const defaultAegisConfig = {
  pagePerformance: false,
  webVitals: false,
  reportApiSpeed: false, // 接口测速
  reportAssetSpeed: false, // 静态资源测速
  spa: true, // spa 页面需要开启，页面切换的时候上报pv
  beforeRequest: (data: any) => {
    if (data.logType === 'pv' || data.logType === 'whiteList') {
      return false;
    }
  },
};
class EmptyAegis {
  setConfig() {}
  report() {}
  error() {}
  info() {}
  debug() {}
  reportTime() {}
  reportEvent() {}
}

export const aegis =
  import.meta && import.meta.env && import.meta.env.PROD ? new EmptyAegis() : new Aegis(defaultAegisConfig);

export const setAegisConfig = ({ id, url, ...args }: { id: string; url: string } & Partial<WebConfig>) => {
  aegis.setConfig({
    id,
    ...args,
    hostUrl: url,
    url: `${url}/collect`,
    pvUrl: `${url}/collect/pv`,
    whiteListUrl: `${url}/collect/whitelist`,
    offlineUrl: `${url}/collect/offline`,
    eventUrl: `${url}/collect/events`,
    speedUrl: `${url}/collect/speed`,
    customTimeUrl: `${url}/speed/custom`,
    performanceUrl: `${url}/speed/performance`,
    webVitalsUrl: `${url}/speed/webvitals`,
  });
};
