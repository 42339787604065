// @ts-nocheck
import TIM, { ConversationType } from '@tencentcloud/chat';
import * as t from 'io-ts';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import values from 'lodash/values';
import toLower from 'lodash/toLower';
import cloneDeep from 'lodash/cloneDeep';
import { timer, getOrPromiseReject } from 'tccc-utils';
import { SessionIdParams } from './sessions.thunk';
import { getLogger } from '../../common/Logger';
import type { Message } from '../../tccc';
import { TcccSdk } from '../../tccc';
import { endVideoSession } from './video.thunk';
import { enterRoom, leaveRoom } from './trtc.thunk';
import traceContext, { contextWithSpan } from '../../http/tracer';
import { Direction, SessionType } from '../../constants/sessions';
import { InvalidParamsError, NotFoundError } from '../../common/TcccError';
import i18next from '../../i18n/i18next.config';
import pick from 'lodash/pick';
import { createAsyncThunk, unwrapResult } from '../createAsyncThunk';
import { transformActiveSessionItemToSession } from './utils';

const innerFlag = 'C2C';
export const userIdSessionIdMap = new Map<string, string>([]);
export const groupIdSessionIdMap = new Map<string, string>([]);
export const getUserIdFromSession = (sessionId: string, sdk: TcccSdk): string => {
  const userId = userIdSessionIdMap.get(sessionId);
  if (userId) {
    return userId;
  }
  const session = sdk.Chat.selectOne(sessionId);
  if (!session || !session.userId) {
    throw new NotFoundError('session', sessionId);
  }
  userIdSessionIdMap.set(sessionId, session.userId);
  userIdSessionIdMap.set(session.userId, sessionId);
  return session.userId;
};
export const getGroupIdFromSession = (sessionId: string, sdk: TcccSdk): string => {
  const cacheGroupId = groupIdSessionIdMap.get(sessionId);
  if (cacheGroupId) {
    return cacheGroupId;
  }
  const session = sdk.Chat.selectOne(sessionId);
  if (!session || !session.imGroupId || !session.userId) {
    throw new NotFoundError('session', sessionId);
  }
  const { userId, imGroupId } = session;
  groupIdSessionIdMap.set(sessionId, imGroupId || userId);
  userIdSessionIdMap.set(session.userId, sessionId);
  return imGroupId || userId;
};
export const getSessionIdFromUserId = (userId: string, sdk: TcccSdk): string => {
  const sessions = sdk.Chat.selectAll();
  const session = sessions.find((item) => item.userId === userId && item.status === '200');
  if (session?.userId) {
    let sessionId = userIdSessionIdMap.get(userId);
    if (sessionId !== session.sessionId) {
      sessionId = session.sessionId;
      userIdSessionIdMap.set(sessionId, session.userId);
      userIdSessionIdMap.set(session.userId, sessionId);
      return sessionId;
    }
    return sessionId;
  }

  return '';
};
export const getSessionIdFromGroupId = (groupId: string, sdk: TcccSdk): string => {
  const sessions = sdk.Chat.selectAll();
  const session = sessions.find((item) => item.imGroupId === groupId && item.status === '200');
  if (session?.imGroupId) {
    let sessionId = groupIdSessionIdMap.get(groupId);
    if (sessionId !== session.sessionId) {
      sessionId = session.sessionId;
      groupIdSessionIdMap.set(sessionId, session.imGroupId);
      groupIdSessionIdMap.set(session.imGroupId, sessionId);
      return sessionId;
    }
    return sessionId;
  }

  return '';
};

export const getSessionInfoFromSessionId = (sessionId: string, sdk: TcccSdk) => {
  let userId;
  let conversationID;
  let conversationType: ConversationType;
  let groupId;
  const logger = getLogger(sdk.Agent.userInfo);
  try {
    if (sessionId.startsWith(innerFlag)) {
      userId = sessionId.replace(/^C2C/, '');
      conversationID = `C2C${userId}`;
      conversationType = 'C2C';
      groupId = userId;
    } else {
      userId = getUserIdFromSession(sessionId, sdk);
      groupId = getGroupIdFromSession(sessionId, sdk);
      conversationID = `GROUP${groupId || userId}`;
      conversationType = 'GROUP';
    }
    return {
      userId,
      groupId,
      conversationID,
      conversationType,
    };
  } catch (e) {
    logger.error('getSessionInfo failed', e);
    throw e;
  }
};

const getFeedbackSession = createAsyncThunk(
  'TIM/getFeedbackSession',
  async (params: { sessionId: string; emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const { userInfo } = params.emitter.Agent;
    const { count } = await params.emitter.http.request('/tcccadmin/feedbackqueue/queryQueueCount', {
      userId: userInfo.userId,
    });
    if (count === 0) return;

    const { sessions } = await params.emitter.http.request('/ccc/queryFeedbackQueue');
    if (Array.isArray(sessions) && sessions.length > 0) {
      return sessions.find((item) => item.sessionId === params.sessionId);
    }
  },
);

export const accessIMSession = createAsyncThunk(
  'TIM/access',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const logger = getLogger(params.emitter.Agent.userInfo);
    const { userInfo } = params.emitter.Agent;
    timer.off(params.sessionId);
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    const session = params.emitter.Chat.selectOne(sessionId);
    if (!session || !session.imGroupId || !session.userId) {
      throw new NotFoundError('session', sessionId);
    }
    if (session.feedback) {
      const feedbackSession = await getFeedbackSession({ sessionId, emitter: params.emitter }).then(unwrapResult);
      if (!feedbackSession) {
        throw new NotFoundError('session', sessionId);
      }
      await params.emitter.http.request('/ccc/assignFeedbackSession', {
        userId: userInfo.userId,
        sessions: [
          {
            skillId: feedbackSession.skillId,
            roomId: feedbackSession.roomId,
            sessionId: feedbackSession.sessionId,
          },
        ],
      });
    }
    try {
      await params.emitter.http.request('/ccc/im/seatin', {
        sessionId,
      });
    } catch (e) {
      logger.error('im seatin failed', e);
      throw e;
    }
    params.emitter.Chat.updateOne(session.sessionId, { status: '200' });
    params.emitter.emit('callInAccepted', {
      sessionId,
      id: sessionId,
      tabUUID: params.emitter.uuid,
      ivrPath: [],
      type: SessionType.im,
      direction: '0',
      timeout: 0,
      callerPhoneNumber: '',
      calleePhoneNumber: '',
      protectedCallee: '',
      protectedCaller: '',
    });
    // setmessageread
    return session;
  },
);

const EndIMSessionParams = t.type({
  sessionId: t.string,
  closeBy: t.union([t.literal('admin'), t.literal('seat'), t.literal('client')]),
});
export const endIMSession = createAsyncThunk(
  'TIM/end',
  async (params: t.TypeOf<typeof EndIMSessionParams> & { emitter: TcccSdk }) => {
    timer.off(params.sessionId);
    const logger = getLogger(params.emitter.Agent.userInfo);
    const { sessionId, closeBy } = await getOrPromiseReject(EndIMSessionParams)(params);
    const session = params.emitter.Chat.selectOne(sessionId);
    if (session?.status === '400') {
      /**
       * 这里做一个判断是因为giveupSession调用过程中会先触发1054挂断，认为是后台挂断而不是超时挂断
       * 所以忽略giveup时的1054事件，用来触发setRest字段
       */
      logger.info('session is already ended', sessionId);
      return false;
    }

    if (!session) {
      throw new NotFoundError('session', sessionId);
    }
    if (session.currentMedia || session.autoEnter) {
      try {
        await endVideoSession({ sessionId, closeBy, emitter: params.emitter });
      } catch (e) {
        logger.error('endVideo session failed', e);
        throw e;
      }
      return session;
    }
    try {
      if (closeBy === 'seat') {
        await params.emitter.http.request('/ccc/im/endSession', {
          sessionId,
        });
      }
    } catch (e) {
      logger.error('endIMSession failed', e);
      throw e;
    }
    params.emitter.Chat.updateOne(sessionId, {
      status: '400',
    });
    return session;
  },
);

export const TransferParams = t.union([
  t.type({ sessionId: t.string, userId: t.string }),
  t.type({ sessionId: t.string, skillGroupId: t.string }),
]);
export const transferIM = createAsyncThunk(
  'TIM/transfer',
  async (params: t.TypeOf<typeof TransferParams> & { sdk: TcccSdk }) => {
    let userId;
    let skillGroupId;
    const { sessionId, ...validParams } = await getOrPromiseReject(TransferParams)(params);
    if ('userId' in validParams) {
      userId = validParams.userId;
    } else {
      skillGroupId = validParams.skillGroupId;
    }
    const session = params.sdk.Chat.selectOne(sessionId);
    if (!session) {
      throw new NotFoundError('session', sessionId);
    }
    if (!session.userId) {
      throw new NotFoundError('userId', session.userId);
    }
    await params.sdk.http.request('/ccc/im/seatForward', {
      sessionId,
      skillGroupId,
      userId,
    });

    if (params.sdk.Chat.selectOne(sessionId)) {
      // 临时处理，因为在线会话转接会转到自己...
      if (session?.status && ['100', '200'].includes(session.status)) {
        params.sdk.Chat.updateOne(sessionId, {
          status: '400',
        });
      } else {
        params.sdk.Chat.removeOne(sessionId);
      }
    }
    return {
      sessionId,
    };
  },
);

export const sendIMSessionRating = createAsyncThunk(
  'TIM/sendRating',
  async (params: { sessionId: string; sdk: TcccSdk }) => {
    const logger = getLogger(params.sdk.Agent.userInfo);
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    const session = params.sdk.Chat.selectOne(sessionId);
    if (!session) {
      throw new NotFoundError('session', sessionId);
    }
    try {
      await params.sdk.http.request('/ccc/im/sendSatisfaction', {
        sessionId: session.sessionId,
      });
    } catch (error) {
      logger.info('send rating failed', error);
      throw error;
    }
    return session;
  },
);

export const getMessageList = createAsyncThunk(
  'TIM/getMessageList',
  async (params: { tccc: TcccSdk; sessionId: string; nextReqMessageID?: string; count?: number }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      sessionId,
      nextReqMessageID,
      count,
      tccc: { tim },
    } = params;
    const { conversationID } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const { data } = await tim.getMessageList({
      sessionId,
      nextReqMessageID,
      conversationID,
      count,
    });
    const response = {
      sessionId,
      nextReqMessageID: data.nextReqMessageID,
      isCompleted: data.isCompleted,
      messageList: data.messageList,
    };
    return response;
  },
);

export const setMessageRead = createAsyncThunk(
  'TIM/setMessageRead',
  async (params: { tccc: TcccSdk; sessionId: string }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      sessionId,
      tccc: { tim },
    } = params;
    const { conversationID } = getSessionInfoFromSessionId(sessionId, params.tccc);
    return await tim.setMessageRead({ conversationID });
  },
);

export const markUnread = createAsyncThunk('TIM/markUnread', async (params: { tccc: TcccSdk; sessionId: string }) => {
  if (!params.tccc.tim) {
    throw new Error('Authorization failed');
  }
  const {
    sessionId,
    tccc: { tim },
  } = params;
  const { conversationID } = getSessionInfoFromSessionId(sessionId, params.tccc);
  return await tim.markUnread(conversationID);
});

export const sendMessageReadReceipt = createAsyncThunk(
  'TIM/sendMessageReadReceipt',
  async (params: { tccc: TcccSdk; messageIds: Array<string> }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      messageIds,
      tccc: { tim },
    } = params;
    const messageList = await Promise.all(messageIds.map((id) => tim.findMessage(id)));

    return await tim.sendMessageReadReceipt(messageList);
  },
);

export const sendMessageReadReceiptV2 = createAsyncThunk(
  'TIM/sendMessageReadReceiptV2',
  async (params: { tccc: TcccSdk; messages: Array<Message> }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      messages,
      tccc: { tim },
    } = params;
    return await tim.sendMessageReadReceipt(messages);
  },
);

export const getMessageReadReceiptList = createAsyncThunk(
  'TIM/getMessageReadReceiptList',
  async (params: { tccc: TcccSdk; messageIds: Array<string> }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      messageIds,
      tccc: { tim },
    } = params;
    const messageList = await Promise.all(messageIds.map(tim.findMessage));
    return await tim.getMessageReadReceiptList(messageList);
  },
);
export const createTextMessage = createAsyncThunk(
  'TIM/createTextMessage',
  async (params: {
    tccc: TcccSdk;
    sessionId: string;
    cloudCustomData?: string;
    payload: {
      text: string;
      custom?: string;
    };
  }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      sessionId,
      payload,
      cloudCustomData,
      tccc: { tim },
    } = params;
    const { groupId, conversationType } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const message = await tim.createTextMessage({
      to: groupId,
      sessionId,
      conversationType,
      payload,
      cloudCustomData,
    });
    return {
      ...message,
      sessionId,
    };
  },
);

export const createImageMessage = createAsyncThunk(
  'TIM/createImageMessage',
  async (params: { tccc: TcccSdk; sessionId: string; payload: { file: File; custom?: string } }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      sessionId,
      payload,
      tccc: { tim },
    } = params;
    const { conversationType, groupId } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const message = await tim.createImageMessage({
      sessionId,
      to: groupId,
      conversationType,
      payload,
    });
    return {
      ...message,
      sessionId,
    };
  },
);

export const createFileMessage = createAsyncThunk(
  'TIM/createFileMessage',
  async (params: {
    tccc: TcccSdk;
    sessionId: string;
    payload: { file: File; custom?: string };
    onProgress?: (progress: number) => void;
  }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      sessionId,
      payload,
      tccc: { tim },
      onProgress,
    } = params;
    const { conversationType, groupId } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const message = await tim.createFileMessage({
      sessionId,
      to: groupId,
      conversationType,
      payload,
      onProgress,
    });
    return {
      ...message,
      sessionId,
    };
  },
);

export const createVideoMessage = createAsyncThunk(
  'TIM/createVideoMessage',
  async (params: { tccc: TcccSdk; sessionId: string; payload: { file: File } }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      sessionId,
      payload,
      tccc: { tim },
    } = params;
    const { conversationType, groupId } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const message = await tim.createVideoMessage({
      sessionId,
      to: groupId,
      conversationType,
      payload,
    });
    return {
      ...message,
      sessionId,
    };
  },
);

export const createCustomMessage = createAsyncThunk(
  'TIM/createCustomMessage',
  async (params: {
    tccc: TcccSdk;
    sessionId: string;
    payload: { data: string; description: string; extension: string };
  }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      tccc: { tim },
      sessionId,
      payload,
    } = params;
    const { conversationType, groupId } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const message = await tim.createCustomMessage({
      sessionId,
      to: groupId,
      conversationType,
      payload,
    });
    return {
      ...message,
      sessionId,
    };
  },
);

export const sendMessage = createAsyncThunk(
  'TIM/sendMessage',
  async (params: {
    tccc: TcccSdk;
    message: Message;
    options?: { onlineUserOnly: boolean };
  }): Promise<{ code: number; data: { message: Message } }> => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const res = await params.tccc.tim.sendMessage(params.message, params.options);
    return res;
  },
);

export const resendMessage = createAsyncThunk('TIM/resendMessage', (params: { tccc: TcccSdk; message: Message }) => {
  if (!params.tccc.tim) {
    throw new Error('Authorization failed');
  }
  return params.tccc.tim.resendMessage(params.message);
});

export const revokeMessage = createAsyncThunk('TIM/revokeMessage', (params: { tccc: TcccSdk; message: Message }) => {
  if (!params.tccc.tim) {
    throw new Error('Authorization failed');
  }
  return params.tccc.tim.revokeMessage(params.message);
});

export const getUnreadCountList = createAsyncThunk(
  'TIM/getUnreadCountList',
  async (params: { tccc: TcccSdk; groupIdList: string[] }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      data: { conversationList },
    } = await params.tccc.tim.getConversationList();
    const conversationMap = keyBy(conversationList, 'conversationID');
    return params.groupIdList.map((item) => {
      const conversation = conversationMap[`GROUP${item}`];
      const unreadCount =
        conversation?.unreadCount || (conversation?.markList?.includes(TIM.TYPES.CONV_MARK_TYPE_UNREAD) ? 1 : 0);

      return {
        unreadCount,
        userId: item,
      };
    });
  },
);

export const getInnerConversations = createAsyncThunk(
  'TIM/getInnerConversations',
  async (params: { tccc: TcccSdk }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const { code, data } = await params.tccc.tim.getConversationList({ type: TIM.TYPES.CONV_C2C });
    if (code !== 0) {
      return [];
    }
    const innerConversationList = data.conversationList
      .filter((conversation) => conversation.type === 'C2C')
      .map((conversation) => ({
        sessionId: conversation.conversationID,
        unreadCount: conversation.unreadCount,
        userId: conversation.userProfile.userID,
        lastMessage: conversation.lastMessage,
      }));
    if (innerConversationList.length === 0) {
      return [];
    }
    const batchUserIds = innerConversationList.map((item) => item.userId);
    const { staffList } = await params.tccc.http.request('/tcccadmin/staff/getStaffList', {
      batchUserIds,
      isNeedStatus: true,
    });
    return values(
      merge(
        keyBy(innerConversationList, (d) => toLower(d.userId)),
        keyBy(staffList, (d) => toLower(d.userId)),
      ),
    ).map((item) => ({
      sessionId: item.sessionId,
      userId: item.userId,
      nickname: item.nickName,
      staffNo: item.staffNo,
      skillGroupId: item.skillGroupId,
      unreadCount: item.unreadCount,
      lastMessage: item.lastMessage,
      status: item.status,
      lastMessage: item.lastMessage,
    }));
  },
);

export const getUserProfile = createAsyncThunk(
  'TIM/getUserProfile',
  async (params: { userIDList: string[]; tccc: TcccSdk }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const res = await params.tccc.tim.getUserProfile({
      userIDList: params.userIDList,
    });
    if (res.code !== 0) {
      return [];
    }
    return cloneDeep(res.data);
  },
);

export const inviteMedia = createAsyncThunk(
  'TIM/inviteMedia',
  async (params: { sessionId: string; type: 'video' | 'audio'; tccc: TcccSdk }) => {
    const logger = getLogger(params.tccc.Agent.userInfo);
    const session = params.tccc.Chat.selectOne(params.sessionId);
    const span = traceContext.tracer.startSpan('tccc.Chat.inviteMedia', {
      attributes: pick(params.tccc.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    if (session) {
      const res = await contextWithSpan(span, () =>
        params.tccc.http.request('/ccc/im/startMedia', {
          sessionId: params.sessionId,
          type: params.type,
        }),
      );
      const enterRoomParams = res?.unifiedTRTCParams?.unified
        ? res.unifiedTRTCParams
        : {
            roomId: res.roomId,
            userSig: res.userSig,
            sdkAppId: res.sdkAppId,
            userId: res.userId,
            privateMapKey: res.privateMapKey,
          };
      logger.info('/ccc/im/startMedia', res);
      try {
        await enterRoom({
          sessionId: params.sessionId,
          type: params.type,
          emitter: params.tccc,
          direction: Direction.callOut,
          parentSpan: span,
          ...enterRoomParams,
        });
        params.tccc.Chat.updateOne(params.sessionId, {
          currentMedia: params.type,
        });
        return {
          sessionId: params.sessionId,
          currentMedia: params.type,
        };
      } catch (e) {
        await contextWithSpan(span, () =>
          params.tccc.http.request('/ccc/im/stopMedia', {
            sessionId: params.sessionId,
            type: params.type,
          }),
        );
        const error = new Error(enterRoomRes.error.message);
        span.recordException(error);
        span.end();
        throw error;
      }
    }
    const error = new NotFoundError('session', params.sessionId);
    span.recordException(error);
    span.end();
    throw error;
  },
);

export const endInviteMedia = createAsyncThunk(
  'TIM/endInviteMedia',
  async (params: { sessionId: string; emitter: TcccSdk }) => {
    const session = params.emitter.Chat.selectOne(params.sessionId);
    const span = traceContext.tracer.startSpan('tccc.Chat.endInviteMedia', {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    if (session) {
      try {
        await params.emitter.http.request('/ccc/im/stopMedia', {
          sessionId: params.sessionId,
          type: session.currentMedia,
        });
      } catch (e) {
        span.recordException(e as Error);
      } finally {
        await leaveRoom({
          sessionId: params.sessionId,
          parentSpan: span,
          emitter: params.emitter,
        });
        span.end();
      }
    } else {
      const error = new NotFoundError('session', params.sessionId);
      span.recordException(error);
      span.end();
      throw error;
    }
  },
);

export const getHistoryMessageList = createAsyncThunk(
  'TIM/getHistoryMessageList',
  async (params: { tccc: TcccSdk; direct: 'up' | 'down'; size: number; sessionId?: string; cursor?: string }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const { sessionId, cursor, size, direct, tccc } = params;
    const response = await tccc.http.request('/tcccadmin/chat/queryUserChatHistoryTask', {
      sessionId,
      direct,
      size,
      cursor,
    });
    return response;
  },
);

export const sendCardMessage = createAsyncThunk(
  'TIM/sendMessage',
  async (params: {
    tccc: TcccSdk;
    sessionId: string;
    payload: { header: string; desc: string; pic: string; url: string };
    options?: { onlineUserOnly: boolean };
  }): Promise<{ code: number; data: { message: Message } }> => {
    const {
      tccc: { tim },
      sessionId,
      payload,
    } = params;
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const { conversationType, groupId } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const message = await tim.createCustomMessage({
      sessionId,
      to: groupId,
      conversationType,
      payload: {
        data: JSON.stringify({
          src: '22',
          content: payload,
          customerServicePlugin: 0,
        }),
      },
    });
    const cardMessage = {
      ...message,
      sessionId,
    };
    const res = await params.tccc.tim.sendMessage(cardMessage, params?.options);
    params.tccc.emit('sendCustomerMessage', { message: res?.data?.message });
    return res;
  },
);

export const sendOrderMessage = createAsyncThunk(
  'TIM/sendMessage',
  async (params: {
    tccc: TcccSdk;
    sessionId: string;
    payload: {
      guide: string;
      name: string;
      desc: string;
      pic: string;
      customField?: { name?: string; value?: string; valueColor?: string; customerValue?: string }[];
    };
    options?: { onlineUserOnly: boolean };
  }): Promise<{ code: number; data: { message: Message } }> => {
    const {
      tccc: { tim },
      sessionId,
      payload,
    } = params;
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const { conversationType, groupId } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const message = await tim.createCustomMessage({
      sessionId,
      to: groupId,
      conversationType,
      payload: {
        data: JSON.stringify({
          src: '28',
          content: payload,
          customerServicePlugin: 0,
        }),
      },
    });
    const orderMessage = {
      ...message,
      sessionId,
    };
    const res = await params.tccc.tim.sendMessage(orderMessage, params?.options);
    params.tccc.emit('sendCustomerMessage', { message: res?.data?.message });
    return res;
  },
);

export const sendCustomMessage = createAsyncThunk(
  'TIM/sendMessage',
  async (params: {
    tccc: TcccSdk;
    sessionId: string;
    payload: { title: string; description: string; iconUrl: string; extension: any };
    options?: { onlineUserOnly: boolean };
  }): Promise<{ code: number; data: { message: Message } }> => {
    const {
      tccc: { tim },
      sessionId,
      payload,
    } = params;
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const { conversationType, groupId } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const message = await tim.createCustomMessage({
      sessionId,
      to: groupId,
      conversationType,
      payload: {
        data: JSON.stringify({
          title: payload.title,
          description: payload.description,
          iconUrl: payload.iconUrl,
          extension: payload.extension,
          fromPostMessage: 1,
        }),
      },
    });
    const customMessage = {
      ...message,
      sessionId,
    };
    const res = await params.tccc.tim.sendMessage(customMessage, params?.options);
    params.tccc.emit('sendCustomerMessage', { message: res?.data?.message });
    return res;
  },
);

export const getMessageListV2 = createAsyncThunk(
  'TIM/getMessageList',
  async (params: { tccc: TcccSdk; sessionId: string; time?: number; sequence?: string }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      sessionId,
      time,
      sequence,
      tccc: { tim },
    } = params;
    const { conversationID } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const { data } = await tim.getMessageListV2({
      sessionId,
      conversationID,
      sequence,
      time,
      direction: 0,
    });
    const response = {
      sessionId,
      nextMessageSeq: data.nextMessageSeq,
      nextMessageTime: data.nextMessageTime,
      isCompleted: data.isCompleted,
      messageList: data.messageList,
    };
    return response;
  },
);

export const getConversationProfile = createAsyncThunk(
  'TIM/getConversationProfile',
  async (params: { tccc: TcccSdk; sessionId: string }) => {
    if (!params.tccc.tim) {
      throw new Error('Authorization failed');
    }
    const {
      sessionId,
      tccc: { tim },
    } = params;
    const { conversationID } = getSessionInfoFromSessionId(sessionId, params.tccc);
    const conversationRes = await tim.getConversationProfile(conversationID);
    return conversationRes;
  },
);

export const getSessionList = createAsyncThunk('sessions/getChatSessionList', async (tccc: TcccSdk) => {
  if (!tccc.tim) {
    throw new Error('Authorization failed');
  }
  const userId = tccc.Agent.userInfo?.userId ?? '';
  const { numberReflectMode } = tccc.Agent.settings;
  const { sessionList } = await tccc.http.request('/ccc/im/getActiveSession');
  let normalSessions: Session[] = [];
  for (const item of sessionList) {
    const session = transformActiveSessionItemToSession(item, userId, numberReflectMode);
    const existSession = normalSessions.find((item) => item.sessionId === session.sessionId);
    if (existSession) {
      if (existSession.state === 'finished') {
        normalSessions = normalSessions.filter((item) => item.sessionId !== existSession.sessionId);
        if (session.state !== 'finished') {
          normalSessions.push(session);
        } else {
          normalSessions.push(existSession);
        }
      }
    } else {
      normalSessions.push(session);
    }
  }
  const inProgressIMUserId: string[] = normalSessions.reduce((acc, session) => {
    if (session.type === 'im' && session.state === 'inProgress' && session.userId) {
      const groupId = session.imGroupId || session.userId;
      return [...acc, groupId];
    }
    return acc;
  }, [] as string[]);
  const {
    data: { conversationList },
  } = await tccc.tim.getConversationList();
  const conversationMap = keyBy(conversationList, 'conversationID');
  const unreadCountList = inProgressIMUserId.map((item) => {
    const conversation = conversationMap[`GROUP${item}`];
    const unreadCount =
      conversation?.unreadCount || (conversation?.markList?.includes(TIM.TYPES.CONV_MARK_TYPE_UNREAD) ? 1 : 0);
    return {
      unreadCount,
      userId: item,
    };
  });
  const unreadCountMap = keyBy(unreadCountList, 'userId');
  const response = normalSessions.map((session) => {
    if (session.userId && session.state === 'inProgress' && unreadCountMap[session.userId]) {
      return {
        ...session,
        unreadCount: unreadCountMap[session.userId].unreadCount,
      };
    }
    return session;
  });
  return response;
});

export const completeChat = createAsyncThunk(
  'sessions/completeChat',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    await params.emitter.http.request('/ccc/im/completeSession', {
      sessionId,
    });
    params.emitter.Call.removeOne(sessionId);
    params.emitter.Chat.removeOne(sessionId);

    return {
      sessionId,
    };
  },
);

export const getIMAgentListForContactUser = createAsyncThunk(
  'TIM/getIMAgentListForContactUser',
  async (tccc: TcccSdk) => {
    if (!tccc.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const res = await tccc.http.request('/tcccadmin/app/getIMAgentListForContactUser', {});
    return res;
  },
);

export const getIMUserProfile = createAsyncThunk('TIM/getIMUserProfile', async (userIDs: string[], tccc: TcccSdk) => {
  if (!tccc.Agent.userInfo) {
    throw new Error(i18next.t('Authorization failed'));
  }
  const res = await tccc.http.request('/ccc/im/getIMUserProfile', {
    UserIDs: userIDs,
  });
  return res;
});

export const startFollowUpConversation = createAsyncThunk(
  'TIM/startFollowUpConversation',
  async (channelAgentID: string, ClientUserID: string, tccc: TcccSdk) => {
    if (!tccc.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const res = await tccc.http.request('/ccc/im/startCallOut', {
      channelAgentID,
      ClientUserID,
      isFromRecord: false,
    });
    return res;
  },
);

export const translateText = createAsyncThunk(
  'TIM/translateText',
  async (
    args: {
      sourceTextList: string[];
      sourceLanguage: string;
      targetLanguage: string;
    },
    tccc: TcccSdk,
  ) => {
    if (!tccc.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const res = await tccc.tim?.translateText(args);
    return res;
  },
);
