import traceContext from '../http/tracer';

declare let performance: any;

const TCCC_SDK_FILE_NAME = 'tcccSdk.js';

const getResourceData = (timing: PerformanceResourceTiming) => {
  const script = new URL(timing.name);
  const qs = new URLSearchParams(script.search);
  return {
    name: timing.name,
    sdkAppId: qs.get('sdkAppId') || '',
    userId: qs.get('userid') || '',
    token: qs.get('token') || '',
    lazy: 'chunk',
  };
};

export const reportScriptLoadTime = () => {
  try {
    const resourceTimings = performance
      ?.getEntriesByType?.('resource')
      ?.filter((item: any) => item && item.initiatorType === 'script' && item.name.includes(TCCC_SDK_FILE_NAME));
    if (resourceTimings.length > 0) {
      const timing = resourceTimings[resourceTimings.length - 1];
      traceContext.tracer
        .startSpan('TcccSDKTiming', {
          attributes: getResourceData(timing),
          startTime: timing.startTime,
        })
        .end(timing.responseEnd);
    } else {
      traceContext.tracer
        .startSpan('TcccSDKTimingNotFound', {
          attributes: {
            origin,
          },
        })
        .end();
    }
  } catch (e) {
    //
  }
};
