import { CreateLogger } from './createLogger';
import { Customer, SessionChangedType } from './session-manager';
import { checkServerTypeSupported } from './checkSession';
import { TcccSdk } from '../../tccc';

export const filterMember = (
  data: SessionChangedType,
  agentUserId: string,
  memberType: 'agent' | 'customer' | 'user',
  customer: Customer | null | undefined,
  emitter: TcccSdk,
) => {
  const sessionLogger = CreateLogger('SESSION', { sessionId: data.sessionId }, emitter.Agent.userInfo);
  if (memberType === 'agent') {
    const agentMembers = data.members
      .filter((member) => member.userId === agentUserId && checkServerTypeSupported(member.serverType))
      .sort((a, b) => {
        if (a.serverType === b.serverType) return 0;
        // 开启手机始终接听时，同时存在serverType为staffSeat和staffPhoneSeat的Member，staffSeatMember优先级更高
        if (a.serverType === 'staffSeat') return -1;
        return 1;
      });
    if (agentMembers.length !== 0) {
      sessionLogger.debug('agentMembers', agentMembers.map((member) => member.memberId).join(','));
    }
    return agentMembers;
  }
  if (memberType === 'customer') {
    const customerMembers = data.members.filter((member) => {
      if (data.sessionType === 'CallInternal' && customer) {
        return member.userId === customer.userId && member.userId !== agentUserId;
      }
      return member.userId !== agentUserId && member.serverType === 'customer';
    });

    if (customerMembers.length !== 0) {
      sessionLogger.debug('customerMembers', customerMembers.map((member) => member.memberId).join(','));
    }
    return customerMembers;
  }
  if (memberType === 'user') {
    const userMembers = data.members
      .filter((member) => {
        if (data.sessionType === 'CallInternal' && customer) {
          return member.userId !== customer.userId && member.userId !== agentUserId;
        }
        return (
          !member.isMonitor &&
          member.serverType !== 'customer' &&
          (member.serverType === 'queue' || member.userId !== agentUserId)
        );
      })
      .sort((a, b) => {
        // 先处理queue类型
        if (a.serverType === 'queue') return -1;
        if (b.serverType === 'queue') return 1;
        return 0;
      });
    if (userMembers.length !== 0) {
      sessionLogger.debug('userMembers', userMembers.map((member) => member.memberId).join(','));
    }
    return userMembers;
  }
  return [];
};
