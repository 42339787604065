import * as t from 'io-ts';
import { booleanFromString1or2 } from 'tccc-utils';

export const unifiedTRTCParams = t.union([
  t.type({
    unified: t.literal(true),
    roomId: t.string,
    sdkAppId: t.string,
    userId: t.string,
    userSig: t.string,
    privateMapKey: t.string,
  }),
  t.partial({
    unified: t.literal(false),
  }),
]);

export const IMCallIn = t.intersection([
  t.type({
    callInInfo: t.type({
      direction: t.literal('0'),
      peerSource: t.keyof({
        '2': undefined,
        '3': undefined,
        '4': undefined,
        '5': undefined,
        '6': undefined,
        '7': undefined,
        '8': undefined,
        // IM Agent
        '10': undefined,
      }),
      roomId: t.string,
      sdkAppId: t.string,
      userSig: t.string,
      privateMapKey: t.string,
      avatar: t.union([t.undefined, t.string]),
      wxNick: t.union([t.undefined, t.string]),
      nickName: t.union([t.undefined, t.string]),
      remark: t.union([t.undefined, t.string]),
      autoEnter: t.union([
        t.undefined,
        t.keyof({
          video: undefined,
          audio: undefined,
        }),
      ]),
      currentMedia: t.union([
        t.undefined,
        t.keyof({
          video: undefined,
          audio: undefined,
        }),
      ]),
      onlineState: booleanFromString1or2,
    }),
    clientChannelName: t.union([t.undefined, t.string]),
    extra: t.type({
      clientChannelName: t.union([t.undefined, t.string]),
      clientData: t.union([t.undefined, t.string]),
      imGroupId: t.union([t.undefined, t.string]),
      sessionId: t.string,
      channelAgentID: t.union([t.undefined, t.string]),
      isIMCallOut: t.union([t.undefined, t.number]),
    }),
    staff: t.type({
      userId: t.string,
    }),
  }),
  t.partial({
    unifiedTRTCParams,
  }),
]);
