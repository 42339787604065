import Logger from 'tccc-logger';
import { getEnvName } from './opentelemetry';
import { COMMIT_ID } from '../constants/env';
import { isInternational } from 'tccc-env/src/env';

export const config: {
  url: string;
  id: string;
} = {
  url: 'https://otlp.tccc.qcloud.com/v1/logs',
  id: 'tccc',
};
if (isInternational()) {
  Object.assign(config, {
    url: 'https://otlp.connect.tencentcloud.com/v1/logs',
    id: 'tcccsg',
  });
}
const now = () => {
  const date = new Date();
  return `${[date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')} ${[
    date.getHours(),
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds(),
  ].join(':')}`;
};

let logger: Logger;

const obj = {
  get logger() {
    if (logger) {
      return logger;
    }
    return new Logger({
      url: config.url,
      id: config.id,
      name: 'tccc-sdk',
      version: COMMIT_ID,
      attributes: {
        commit: COMMIT_ID,
        env_Name: getEnvName(),
      },
    });
  },
  set logger(newLogger: Logger) {
    logger = newLogger;
  },
};

const getAttributes = (fields: Partial<Record<any, any>>) => ({
  ...fields.attributes,
  now: now(),
  spanId: fields.spanId,
  traceId: fields.traceId,
});

export const otlpLogger = {
  debug: (msg: string, fields: Partial<Record<any, any>>) => {
    obj.logger.debug({
      msg,
      attributes: getAttributes(fields),
    });
  },
  info: (msg: string, fields: Partial<Record<any, any>>) => {
    logger.info({
      msg,
      attributes: getAttributes(fields),
    });
  },
  warn: (msg: string, fields: Partial<Record<any, any>>) => {
    logger.info({
      msg,
      attributes: getAttributes(fields),
    });
  },
  error: (msg: string, fields: Partial<Record<any, any>>) => {
    logger.error({
      msg,
      attributes: getAttributes(fields),
    });
  },
};

export default obj;
