// import { Stream } from 'trtc-js-sdk';
import { TencentASRSocket } from './tencentASRSocket';
const INPUT_SAMPLE_RATE = 48000; // 输入采样率
const OUTPUT_SAMPLE_RATE = 8000; // 输出采样数位
// 腾讯云语音识别官网文档建议 8k 采样率语音包大小为 640 字节

export default class TencentASRWebRecorder {
  destTencentSocket: TencentASRSocket;
  audioContext: AudioContext;
  audioInput: MediaStreamAudioSourceNode;
  recorder: ScriptProcessorNode;
  size: number;
  buffer: Float32Array[];
  constructor(audioTrack: MediaStreamTrack, destTencentSocket: TencentASRSocket) {
    this.destTencentSocket = destTencentSocket;
    this.buffer = []; // 录音缓存
    this.size = 0; // 录音文件长度
    this.#init();
    const mediaStream = new MediaStream();
    // const audioTrack = this.stream.getAudioTrack();
    if (audioTrack) {
      mediaStream.addTrack(audioTrack);
    }
    this.audioContext = new AudioContext();
    this.audioInput = this.audioContext.createMediaStreamSource(mediaStream);
    /* 创建一个ScriptProcessorNode用于通过JavaScript直接处理音频，采样的缓冲区大小为2048，输入和输出都是单声道
     * buffer size计算方法:
     * 【buffer size must be 0 or a power of two between 256 and 16384】
     * compression = INPUT_SAMPLE_RATE / OUTPUT_SAMPLE_RATE; => 6
     * bufferSize <= compression * RECOMMENDED_DATA_PKG_SIZE; => 2048
     */
    const bufferSize = 2048;
    this.recorder = this.audioContext.createScriptProcessor(bufferSize, 1, 1);
    this.recorder.addEventListener('audioprocess', (e) => {
      const inputBuffer = e.inputBuffer.getChannelData(0);
      this.#input(inputBuffer);
      this.#sendByWs();
    });
  }

  start = () => {
    this.audioInput.connect(this.recorder);
    this.recorder.connect(this.audioContext.destination);
  };

  stop = () => {
    this.recorder?.disconnect();
    this.destTencentSocket?.send(JSON.stringify({ type: 'end' }));
    this.destTencentSocket?.close();
  };

  #init = () => {};

  #input = (data: Float32Array) => {
    this.buffer.push(new Float32Array(data));
    this.size += data.length;
  };

  #encodePCM = (input: any) => {
    // to 16Bit PCM
    const dataLength = input.length * (16 / 8);
    const dataBuffer = new ArrayBuffer(dataLength);
    const dataView = new DataView(dataBuffer);
    let offset = 0;
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      dataView.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return new Blob([dataView]);
  };

  #compress = () => {
    // to 8kHz
    const data = new Float32Array(this.size);
    let offset = 0;
    for (const iterator of this.buffer) {
      data.set(iterator, offset);
      offset += iterator.length;
    }
    const compression = INPUT_SAMPLE_RATE / OUTPUT_SAMPLE_RATE;
    const length = Math.round(data.length / compression);
    const result = new Float32Array(length);
    const springFactor = (data.length - 1) / (length - 1);
    // eslint-disable-next-line prefer-destructuring
    result[0] = data[0];
    for (let i = 1; i < length - 1; i++) {
      const tmp = i * springFactor;
      const before = +Math.floor(tmp).toFixed();
      const after = +Math.ceil(tmp).toFixed();
      const atPoint = tmp - before;
      result[i] = data[before] + (data[after] - data[before]) * atPoint;
    }
    result[length - 1] = data[data.length - 1];
    return result;
  };

  #sendByWs = () => {
    const output = this.#compress();
    const audioData = this.#encodePCM(output);
    // 将文件内容读入内存，通过一系列异步接口，可以在主线程中访问本地文件
    const fileReader = new FileReader();
    // 异步按字节读取文件内容，结果用ArrayBuffer对象表示
    fileReader.readAsArrayBuffer(audioData);
    // 读取操作成功完成时调用
    fileReader.onload = (e) => {
      const bytesArrayBuffer = e.target?.result;
      if (bytesArrayBuffer) {
        this.destTencentSocket?.send(bytesArrayBuffer);
      }
    };
    this.#clearBuffer();
  };

  #clearBuffer = () => {
    // 每次发送完成，清理掉旧数据
    this.buffer = [];
    this.size = 0;
  };
}
