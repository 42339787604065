import isError from 'lodash/isError';
import toString from 'lodash/toString';
export function unknown2string(val: unknown): string {
  if (isError(val) && 'code' in val) {
    return `${(val as any).name}: (${(val as any).code})${(val as any).message}`;
  }
  if (isError(val)) {
    return `${val.name}:${val.message}`;
  }
  return toString(val);
}
