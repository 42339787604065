import * as t from 'io-ts';
import {
  TransferParams,
  accessVideoCall,
  endVideoSession,
  transferVideoSession,
  play as playStream,
} from '../store/sessions/video.thunk';
import {
  unmuteVideo as unmuteVideoThunk,
  muteVideo as muteVideoThunk,
  muteMic,
  unmuteMic,
} from '../store/sessions/trtc.thunk';
import { TcccSdk } from '../tccc';

type OmitEndParams = 'emitter' | 'closeBy';

export class Video {
  sdk: TcccSdk;
  constructor(sdk: TcccSdk) {
    this.sdk = sdk;
  }
  accept = (params: Omit<Parameters<typeof accessVideoCall>[0], 'emitter'>) =>
    accessVideoCall({ emitter: this.sdk, ...params });

  end = (params: Omit<Parameters<typeof endVideoSession>[0], OmitEndParams>) =>
    endVideoSession({ emitter: this.sdk, closeBy: 'seat', ...params });

  transfer = (params: t.TypeOf<typeof TransferParams>) => transferVideoSession({ ...params, emitter: this.sdk });
  unmuteVideo = (params: Omit<Parameters<typeof unmuteVideoThunk>[0], 'emitter'>) =>
    unmuteVideoThunk({ ...params, emitter: this.sdk });
  muteVideo = (params: Omit<Parameters<typeof muteVideoThunk>[0], 'emitter'>) =>
    muteVideoThunk({ ...params, emitter: this.sdk });
  muteAudio = (params: Omit<Parameters<typeof muteMic>[0], 'emitter'>) => muteMic({ ...params, emitter: this.sdk });
  unmuteAudio = (params: Omit<Parameters<typeof unmuteMic>[0], 'emitter'>) =>
    unmuteMic({ ...params, emitter: this.sdk });

  play(params: Omit<Parameters<typeof playStream>[0], 'sdk'>) {
    return playStream({ ...params, sdk: this.sdk });
  }
}
