import { Direction, SessionStatus, SessionType } from '../constants/sessions';
import { Session } from '../store/sessions';
import { TcccSdk } from '../tccc';
import { FeedbackQueue } from '../tccc/events';

export async function queryQueueCount(emitter: TcccSdk) {
  const { IM, assignIM, existIM } = emitter.Agent.agentState;
  let count = 0;
  if (!assignIM || !existIM || IM.used >= IM.total) {
    return;
  }
  if (!window.navigator.onLine) {
    return;
  }
  if (!emitter.Agent.userInfo) return;
  try {
    const res = await emitter.http.request('/tcccadmin/feedbackqueue/queryQueueCount', {
      userId: emitter.Agent.userInfo.userId,
    });
    if (res.count > 0) {
      count = res.count;
    }
  } catch (e) {}

  if (count > 0) {
    try {
      const { sessions } = await emitter.http.request('/ccc/queryFeedbackQueue');
      if (Array.isArray(sessions) && sessions.length > 0) {
        // 限制最大查询数量
        const maxSessionCount = IM.total - IM.used;
        const res: FeedbackQueue[] = sessions
          .map((item) => ({
            sessionId: item.sessionId,
            peerSource: item.peerSource,
            userId: item.userId,
            nickname: item.wxNick,
            imGroupId: item.imGroupId,
            timestamp: Math.floor(item.timestamp / 1000),
          }))
          .slice(0, maxSessionCount);
        const sessionList: Session[] = res.map((item) => ({
          ...item,
          type: SessionType.im,
          status: SessionStatus.ringing,
          direction: Direction.callIn,
          feedback: true,
        }));
        emitter.emit('feedbackQueueChanged', res);
        emitter.Chat.upsertMany(sessionList);
        return {
          ...res,
        };
      }
    } catch (e) {}
  }

  if (count === 0) {
    emitter.emit('feedbackQueueChanged', []);
  }
}
