export const parseJson = <T extends unknown>(json: T): T => {
  try {
    if (json && typeof json === 'string') {
      return JSON.parse(json);
    }
    return json;
  } catch (e) {
    return json;
  }
};
