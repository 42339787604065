import isError from 'lodash/isError';
import toString from 'lodash/toString';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import { ulid } from 'ulid';
import lscache from 'lscache';
import EventEmitter from 'eventemitter3';
import { aegis } from '../http/aegis';
import { unknown2string } from './unknown2string';
import { otlpLogger } from '../http/logger';
import { isLocal, isProd } from 'tccc-env/src/env';

type LogLevel = 'debug' | 'warn' | 'error' | 'info' | 'report';

const getDeviceId = () => {
  const key = 'tccc-deviceId';
  let deviceId = lscache.get(key);
  if (!deviceId) {
    deviceId = ulid();
    lscache.set(key, deviceId);
  }
  return deviceId;
};
type UserInfo = Partial<{
  sdkAppId: string;
  userId: string;
}>;
export class Logger {
  userInfo: UserInfo | null;
  userInfoCallback: () => UserInfo | null;
  constructor(userInfo?: UserInfo | null, userInfoCallback?: () => UserInfo | null) {
    this.userInfo = userInfo || null;
    this.userInfoCallback = userInfoCallback || (() => null);
  }
  #convertAnyTypeToString = (s: unknown): string => {
    try {
      switch (typeof s) {
        case 'object':
          if (isError(s)) {
            return unknown2string(s);
          }
          if (typeof s === 'object' && s !== null) {
            return `{${Object.entries(s)
              .map(([k, v]) => {
                if (isError(v)) {
                  return unknown2string(v);
                }
                if (isPlainObject(v)) {
                  return `${k}:${JSON.stringify(v)}`;
                }
                if (v instanceof EventEmitter) {
                  return `${k}: ''`;
                }
                return `${k}:${v}`;
              })
              .join(',')}}`;
          }
          return '';
        case 'function':
          return '';
        default:
          return toString(s);
      }
    } catch (e) {
      if (isError(e) && e.message.includes('circular')) {
        return '';
      }
      return 'convert failed';
    }
  };

  info(...args: any[]) {
    const log = args.map(this.#convertAnyTypeToString).join(' ');
    const str = `${log}`;
    const commonInfo = this.getCommonInfo();
    if (isLocal) {
      console.info(str);
    }
    otlpLogger.info(str, {
      attributes: commonInfo,
    });
  }
  debug(...args: any[]) {
    const log = args.map(this.#convertAnyTypeToString).join(' ');
    const str = `${log}`;
    const commonInfo = this.getCommonInfo();
    if (isLocal) {
      console.debug(str);
    }
    otlpLogger.debug(str, {
      attributes: commonInfo,
    });
  }

  warn(...args: any[]) {
    const str = `${args.map(this.#convertAnyTypeToString).join(' ')}`;
    const commonInfo = this.getCommonInfo();
    if (isLocal) {
      console.warn(str);
    }
    otlpLogger.warn(str, {
      attributes: commonInfo,
    });
  }

  error(...args: any[]) {
    const log = args.map(this.#convertAnyTypeToString).join(' ');
    const str = `${log}`;
    const commonInfo = this.getCommonInfo();
    if (isLocal) {
      console.error(str);
    }
    otlpLogger.error(str, {
      attributes: commonInfo,
    });
  }

  report(...args: any[]) {
    const str = `${args.map(this.#convertAnyTypeToString).join(' ')}`;
    this.getCommonInfo();
    if (isProd) {
      aegis.report({
        msg: str,
      });
    }
  }

  /**
   * 自定义上报tags, 默认level: INFO
   */
  custom(
    tags: Record<string, string | number | undefined> & { level: LogLevel; spanId?: string; traceId?: string },
    ...args: any[]
  ) {
    const log = args.map(this.#convertAnyTypeToString).join(' ');
    const str = `${log}`;
    const commonInfo = this.getCommonInfo();
    const attributes = {
      ...commonInfo,
      ...omit(tags, 'level'),
    };
    if (tags.level === 'report') {
      if (isProd) {
        aegis.report({
          msg: str,
        });
      }
    } else if (tags.level === 'error') {
      if (isLocal) {
        console.error(str);
      }
      otlpLogger.error(str, {
        spanID: tags.spanId,
        traceID: tags.traceId,
        attributes,
      });
    } else if (tags.level === 'warn') {
      if (isLocal) {
        console.warn(str);
      }
      otlpLogger.warn(str, {
        spanID: tags.spanId,
        traceID: tags.traceId,
        attributes,
      });
    } else if (tags.level === 'debug') {
      if (isLocal) {
        console.debug(str);
      }
      otlpLogger.debug(str, {
        spanID: tags.spanId,
        traceID: tags.traceId,
        attributes,
      });
    } else if (tags.level === 'info') {
      if (isLocal) {
        console.info(str);
      }
      otlpLogger.info(str, {
        spanID: tags.spanId,
        traceID: tags.traceId,
        attributes,
      });
    } else {
      if (isLocal) {
        console.info(str);
      }
      otlpLogger.info(str, {
        spanID: tags.spanId,
        traceID: tags.traceId,
        attributes,
      });
    }
  }

  /**
   *
   *  duration ms
   */
  reportTime(name: string, duration: number) {
    this.getCommonInfo();
    aegis.reportTime(name, duration);
    this.custom(
      {
        name,
        duration,
        level: 'info',
      },
      `${name} cost ${duration}ms`,
    );
  }

  private getCommonInfo() {
    const commonInfo = {
      ...(this.userInfo && this.userInfo),
      ...this.userInfoCallback(),
      deviceId: getDeviceId(),
      hidden: document.hidden,
    };
    return commonInfo;
  }
}

export const getLogger = (target?: { sdkAppId?: string; userId?: string } | null, callback?: () => UserInfo | null) => {
  const sdkAppId = get(target, 'sdkAppId');
  const userId = get(target, 'userId');
  if (sdkAppId && userId) {
    return new Logger({ sdkAppId, userId }, callback);
  }
  return new Logger(null, callback);
};

export default getLogger();
