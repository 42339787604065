import { AxiosRequestConfig } from 'axios';
import * as t from 'io-ts';
import { ulid } from 'ulid';
import isError from 'lodash/isError';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { axios } from './axios';
import { APIs } from './apis';
import { getOrPromiseReject } from 'tccc-utils';
import { getLogger } from '../common/Logger';
import { TcccSdk } from '../tccc';
import { Agent } from '../tccc/Agent';
type CommonResponse = {
  errorCode: string;
  msg: string;
  nonce: string;
  requestId: string;
};

type AuthUserInfo = Partial<{ sessionKey: string; userId: string; sdkAppId: string }>;
async function request<T extends keyof typeof APIs>(
  url: T,
  data: t.InputOf<typeof APIs[T]['Input']> = {},
  config?: AxiosRequestConfig & AuthUserInfo,
): Promise<t.TypeOf<typeof APIs[T]['Output']> & CommonResponse> {
  const id = ulid();
  const sdkAppId = get(config, 'sdkAppId');
  const userId = get(config, 'userId');
  const logger = getLogger({ sdkAppId, userId });
  const formData = new FormData();
  let isFormDataType = false;

  const params = {
    nonce: id,
    requestId: id,
    timestamp: `${Date.now()}`,
    // @ts-ignore
    ...APIs[url].Input.encode(data),
  };
  if (sdkAppId && !['/tccclogin/login/loginInstance', '/tccclogin/login/checkLogin'].includes(url)) {
    Object.assign(params, { sdkAppId });
  }
  if (config?.headers?.['Content-Type'] === 'multipart/form-data') {
    Object.entries(params).forEach(([key, val]) => {
      formData.append(key, val);
    });
    isFormDataType = true;
  }
  return axios
    .request<t.TypeOf<typeof APIs[T]['Output']> & CommonResponse>({
      url,
      method: 'post',
      data: isFormDataType ? formData : params,
      ...config,
      headers: config?.sessionKey
        ? {
            ...config?.headers,
            Authorization: `Bearer ${config.sessionKey}`,
          }
        : config?.headers,
    })
    .then((res) => {
      if (Number(res.data.errorCode) === 0) {
        return getOrPromiseReject(APIs[url].Output)(res.data) as CommonResponse & t.TypeOf<typeof APIs[T]['Output']>;
      }
      throw res;
    })
    .catch((error) => {
      if (typeof error === 'string') {
        console.error(error);
        const unknownError = new Error(error);
        const log = {
          errorCode: '-3',
          name: 'Unknown error',
          message: error,
          nonce: params.nonce,
          requestId: params.nonce,
          stack: unknownError.stack,
        };
        logger.warn(log);
        return Promise.reject(unknownError);
      }
      // io-ts parsed error
      if (isError(error) && error.message.indexOf('parse failed') !== -1) {
        const parsedError = error;
        const log = {
          errorCode: '-2',
          name: 'parse failed',
          message: error.message,
          nonce: params.nonce,
          requestId: params.nonce,
          stack: parsedError.stack,
        };
        logger.warn(log);
        logger.report(log);
        return Promise.reject(parsedError);
      }
      // errorCode !== 0
      if (error.data) {
        const tcccError = new Error(error.data.msg);
        const log = {
          ...error.data,
          message: tcccError.message,
          stack: tcccError.stack,
          code: error.data.errorCode,
        };
        if (error.data?.errorCode) {
          if (/^-\d*999$/.test(error.data?.errorCode)) {
            logger.error(log);
            logger.report('server error', error.data);
          }
        } else {
          logger.warn(log);
        }
        return Promise.reject(log);
      }
      // Network Error
      if (error.request) {
        const errJSon = error?.toJSON?.() || { name: 'Network Error', message: 'Network Error' };
        const log = {
          errorCode: '-1',
          message: `[network error]${errJSon.name}:${errJSon.message},url:${url}`,
          nonce: params.nonce,
          requestId: params.requestId,
          stack: errJSon.stack,
        };
        logger.warn(log);
        logger.report(log);
        return Promise.reject(errJSon);
      }
      // Unknown Error
      const tcccError = new Error(error?.statusText || 'unknown error');
      const log = {
        errorCode: error?.status || '-1',
        message: error?.statusText || 'unknown error',
        nonce: params.nonce,
        requestId: params.nonce,
        stack: tcccError.stack,
      };
      logger.error(log);

      return Promise.reject(tcccError);
    });
}

export class Request {
  request: typeof request;
  constructor(sdk: TcccSdk, agent: Agent) {
    this.request = async <T extends keyof typeof APIs>(
      url: T,
      data: t.InputOf<typeof APIs[T]['Input']> = {},
      config?: AxiosRequestConfig,
    ) => {
      const userInfo = pick(agent.userInfo || {}, ['userId', 'sdkAppId']);
      return request(url, data, { ...config, sessionKey: agent.sessionKey || '', ...userInfo }).catch((error) => {
        if (error?.code === '-2015') {
          sdk.emit('expired');
        }
        throw error;
      });
    };
  }
}
