import { pstnAPIs } from './pstn';
import { adminAPIs } from './admin';
import { imAPIs } from './im';
import { loginAPIs } from './login';
import { cccAPIs } from './ccc';

export const APIs = {
  ...pstnAPIs,
  ...adminAPIs,
  ...imAPIs,
  ...loginAPIs,
  ...cccAPIs,
};
