import { CallInAccepted, SessionEnded, TcccSdk } from '../../tccc';
import {
  AcceptedState,
  AgentMember,
  CustomerMember,
  HungupParams,
  Member,
  MemberServerType,
  UserMember,
} from './member-manager';
import { Direction, HangupType, SessionStatus, SessionType as SessionEventType } from '../../constants/sessions';
import { unwrapResult } from '../../store/createAsyncThunk';
import EventEmitter from 'eventemitter3';
import { InternalCallIn, InternalCallOut, PhoneCallIn, PhoneCallOut, VoipCallIn } from '../../tccc/events';
import { HangupSide, ServerType, Session, UnifiedTRTCParams } from '../../store/sessions';
import { getIvrPath } from '../../utils/getIvrPath';
import { timer } from 'tccc-utils';
import { giveUpSession } from '../../store/sessions/sessions.thunk';
import * as t from 'io-ts';
import { transformHangupType } from '../../store/sessions/utils';
import { CreateLogger } from './createLogger';
import { getUserProfile } from '../../store/sessions/tim.thunk';
import omit from 'lodash/omit';
import i18next from '../../i18n/i18next.config';
import { checkSessionCreate, checkSessionTypeSupported } from './checkSession';
import { innerEmitter } from '../../utils/innerEmitter';
import { setCustomer, setDirection, setHost, setServerType, setSessionType, setTRTCParams } from './setSessionProps';
import { filterMember } from './filter-member';
import { BaseClass } from '../../tccc/BaseClass';
import { deleteWebRTCInstance, getWebRTCInstance } from '../../trtc/trtc';
import traceContext from '../../http/tracer';
import pick from 'lodash/pick';
import { NumberReflectMode } from '../../constants/appSettings';

export type CallState = 'INIT' | 'RINGING' | 'ACCEPTED' | 'END';

export type SessionType = 'CallIncoming' | 'CallOutgoing' | 'CallInternal' | 'AudioIncoming' | 'IVRCallOutgoing';

export type SessionEndState = {
  hangupCode: t.TypeOf<typeof HangupType>;
  hangupSide: 'user' | 'system' | 'seat';
};

export type Customer = Partial<{
  callerLocation: string;
  ivrPress: string;
  ivrPressLabel: string[];
  phone: string;
  protectedPhone: string;
  skillGroupID?: string;
  servingNumber: string;
  servingNumberRemark?: string;
  clientData: string;
  remark: string;
  userId: string;
}>;

export type SessionChangedType<T extends MemberServerType = MemberServerType> = {
  sessionId: string;
  members: Member<T>[];
  sequence: number;
  sessionType: SessionType;
  sessionEndState?: SessionEndState;
  customer: Customer;
};

export type SessionSummary = {
  RoomID: number;
  SdkAppID: number;
  Sequence: number;
  SessionID: string;
  SessionType: SessionType;
  Timestamp: number;
};

export enum statusC {
  NULL = 0,
  RINGING,
  ACCEPTED,
  END,
}
/**
 * Session
 * 2017事件驱动
 */
export class SessionManager extends EventEmitter implements BaseClass {
  get type(): SessionEventType {
    return setSessionType(this.sessionType);
  }
  static _sessions: Record<string, SessionManager> = {};
  static sequence: Record<string, number> = {};

  static getSession({ sdkAppId, userId, sessionId }: { sdkAppId: string; userId: string; sessionId: string }) {
    return SessionManager._sessions[`${sdkAppId}${userId}${sessionId}`];
  }

  static getSessionList({ sdkAppId, userId }: { sdkAppId: string; userId: string }): Record<string, SessionManager> {
    const initialList: Record<string, SessionManager> = {};
    return Object.entries(SessionManager._sessions).reduce((acc, [key, item]) => {
      if (key.startsWith(sdkAppId + userId)) {
        return {
          ...acc,
          [item.sessionId]: SessionManager.getSession({ sdkAppId, userId, sessionId: item.sessionId }),
        };
      }
      return acc;
    }, initialList);
  }

  static createSession({
    userId,
    data,
    emitter,
    sdkAppId,
    tabUUID,
  }: {
    userId: string;
    data: SessionChangedType;
    emitter: TcccSdk;
    sdkAppId: string;
    tabUUID: string;
  }) {
    const sessionLogger = CreateLogger('SESSION', { sessionId: data.sessionId }, emitter.Agent.userInfo);
    if (!checkSessionTypeSupported(data.sessionType)) {
      return null;
    }
    if (checkSessionCreate({ sdkAppId, userId, data })) {
      sessionLogger.debug('ignore 2017 data', {
        sessionId: data.sessionId,
      });
      return null;
    }
    if (!SessionManager.getSession({ sdkAppId, userId, sessionId: data.sessionId })) {
      const session = new SessionManager({ sdkAppId, userId, data, emitter, tabUUID });
      SessionManager._sessions[`${sdkAppId}${userId}${data.sessionId}`] = session;
      if (innerEmitter.listenerCount(`dial${data.sessionId}`) > 0) {
        innerEmitter.emit(`dial${data.sessionId}`, { ...session.customer, serverType: session.serverType });
      }
    }
    SessionManager._sessions[`${sdkAppId}${userId}${data.sessionId}`].dispatchSessionChange({ data, tabUUID });
    return SessionManager._sessions[`${sdkAppId}${userId}${data.sessionId}`];
  }

  static deleteSession({
    sdk,
    sdkAppId,
    userId,
    sessionId,
  }: {
    sdkAppId: string;
    userId: string;
    sessionId: string;
    sdk?: TcccSdk;
  }) {
    const sessionLogger = CreateLogger('SESSION', { sessionId }, sdk?.Agent.userInfo || null);
    delete SessionManager._sessions[`${sdkAppId}${userId}${sessionId}`];
    sessionLogger.debug('delete session');
  }

  static getSequence({ sdkAppId, userId, sessionId }: { sdkAppId: string; userId: string; sessionId: string }) {
    return SessionManager.sequence[sdkAppId + userId + sessionId];
  }
  static setSequence({
    sdkAppId,
    userId,
    sessionId,
    sequence,
  }: {
    sdkAppId: string;
    userId: string;
    sessionId: string;
    sequence: number;
  }) {
    SessionManager.sequence[sdkAppId + userId + sessionId] = sequence;
  }

  static deleteSequence({ sdkAppId, userId }: { sdkAppId: string; userId: string }) {
    Object.keys(SessionManager.sequence).forEach((key) => {
      if (key.startsWith(sdkAppId + userId)) {
        Object.assign(SessionManager.sequence, {
          [key]: undefined,
        });
      }
    });
  }

  static reset({ sdk }: { sdk: TcccSdk }): void {
    const { userInfo } = sdk.Agent;
    if (!userInfo) return;
    const resetSpan = traceContext.tracer.startSpan('sessionManageReset', {
      attributes: pick(sdk.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { sdkAppId, userId } = userInfo;
    Object.keys(this.getSessionList({ sdkAppId, userId })).forEach((val) => {
      SessionManager.deleteSession({ sdkAppId, userId, sessionId: val });
      try {
        getWebRTCInstance({ sdk, sessionId: val })
          ?.leaveRoom(resetSpan)
          .then(() => {
            deleteWebRTCInstance(sdk, val);
          });
      } catch (e) {}
    });
    SessionManager.deleteSequence({ sdkAppId, userId });
  }

  sessionId: string;
  direction: Direction = Direction.callIn;
  serverType: ServerType = 'staffSeat';

  phoneNumber = '';
  ivrPath: { key?: string; label?: string }[] = [];

  /**
   * 音频呼叫相关属性
   */
  nickname?: string = '';
  avatar?: string = '';

  status: statusC = statusC.NULL;
  isHost?: boolean = false;

  trtcParams: {
    sdkAppId: string;
    userId: string;
    roomId: string;
    userSig: string;
    privateMapKey: string;
    unified?: boolean;
  } = {
    sdkAppId: '',
    userSig: '',
    userId: '',
    privateMapKey: '',
    roomId: '',
  };

  sessionEndState?: SessionEndState;
  sessionType: SessionType;

  /**
   * @deprecated
   */
  startCallTime?: number;

  public customer?: Customer;
  private trtcError?: string;
  private tabUUID: string;
  private readonly userId: string;
  private readonly sdkAppId: string;

  private readonly agentMembers: Record<string, AgentMember> = {};
  private readonly customerMembers: Record<string, CustomerMember> = {};
  private readonly userMembers: Record<string, UserMember> = {};
  private readonly emitter: TcccSdk;

  private logger: ReturnType<typeof CreateLogger>;

  private constructor({
    sdkAppId,
    userId,
    data,
    emitter,
    tabUUID,
  }: {
    sdkAppId: string;
    userId: string;
    data: SessionChangedType;
    emitter: TcccSdk;
    tabUUID: string;
  }) {
    super();
    this.logger = CreateLogger('SESSION', { sessionId: data.sessionId }, emitter.Agent.userInfo);
    this.logger.debug('new');

    // session static props
    this.sessionId = data.sessionId;
    this.sessionType = data.sessionType;
    this.sdkAppId = sdkAppId;
    this.userId = userId;
    this.emitter = emitter;
    this.tabUUID = tabUUID;

    // session self dynastic data
    this.status = statusC.NULL;
    if (typeof SessionManager.getSequence({ sdkAppId, userId, sessionId: data.sessionId }) !== 'number') {
      SessionManager.setSequence({ userId, sdkAppId, sessionId: data.sessionId, sequence: -1 });
    }
    // session customer data
  }

  reset(): void {}
  protected dispatchSessionChange = ({ data, tabUUID }: { data: SessionChangedType; tabUUID: string }) => {
    this.logger.debug('dispatchSessionChange', data);
    const sequence = SessionManager.getSequence({
      sdkAppId: this.sdkAppId,
      userId: this.userId,
      sessionId: data.sessionId,
    });
    if (!sequence) {
      this.logger.warn('sequence not exist');
      return;
    }
    // 忽略相同seq的状态变更
    if (sequence >= data.sequence) {
      return this.logger.debug('ignore by seq', {
        sequence,
      });
    }

    const storeSession = this.emitter.Call.selectOne(this.sessionId);
    // fixme: 先忽略monitor类型的会话
    if (storeSession && storeSession.type === 'monitor') {
      this.destroySession();
      return;
    }

    if (
      // 已经转接到其他座席的会话仍然会收到事件通知，直接忽略处理
      this.status === statusC.NULL &&
      !data.sessionEndState &&
      filterMember(data, this.userId, 'agent', null, this.emitter).filter((member) => member.callState !== 'END')
        .length === 0
    ) {
      return this.logger.debug('ignore end state member from initial session');
    }
    SessionManager.setSequence({
      sdkAppId: this.sdkAppId,
      userId: this.userId,
      sessionId: data.sessionId,
      sequence: data.sequence,
    });
    this.tabUUID = tabUUID;
    this.isHost = setHost({ data, userId: this.userId, sdk: this.emitter });
    this.direction = setDirection({ data, userId: this.userId, emitter: this.emitter });
    this.serverType = setServerType({ data, userId: this.userId });
    const customer = setCustomer({
      data,
      userId: this.userId,
      direction: this.direction,
      sessionType: this.sessionType,
    });
    const { phone, protectedPhone, userId } = customer;
    this.customer = customer;
    this.phoneNumber = protectedPhone || phone || userId || '';
    if (data.sessionEndState) {
      this.sessionEndState = data.sessionEndState;
    }
    const trtcParams = setTRTCParams({ data, userId: this.userId, emitter: this.emitter });
    if (trtcParams) {
      this.trtcParams = trtcParams;
    }

    if (this.direction === Direction.callOut) {
      // 外呼优先处理用户侧挂断
      this.handleCustomerMember(data);
      this.handleAgentMember(data);
    } else {
      this.handleAgentMember(data);
      this.handleCustomerMember(data);
    }

    this.handleUserMember(data);
  };

  // 客人Member
  private handleCustomerMember = (data: SessionChangedType) => {
    for (const member of filterMember(data, this.userId, 'customer', this.customer, this.emitter)) {
      // 不关注已经退出Session的Member
      if (!this.customerMembers[member.memberId] && member.callState !== 'END') {
        if (!this.customerMembers[member.memberId]) {
          const customerMember = new CustomerMember({
            sessionId: this.sessionId,
            member,
            emitter: this.emitter,
            event: data,
          });
          this.customerMembers[member.memberId] = customerMember;
          customerMember.on('stateChanged', this.customerStateChange);
        }
      }
      this.customerMembers[member.memberId]?.dispatchState(member, data);
    }
  };

  // 座席Member
  private handleAgentMember = (data: SessionChangedType) => {
    // TODO:记录self memberId, 所有agent member callstate为end后挂断
    const activeAgentMembers = filterMember(data, this.userId, 'agent', null, this.emitter);

    this.logger.debug('activeAgentMembers', activeAgentMembers);

    for (const member of activeAgentMembers) {
      // 多次转接到自己的
      if (!this.agentMembers[member.memberId]) {
        // 已经结束并且不存在于Session的Member不需要创建
        if (member.callState !== 'END') {
          const agentMember = new AgentMember({
            sessionId: this.sessionId,
            sessionType: this.sessionType,
            event: data,
            member,
            emitter: this.emitter,
            tabUUID: this.tabUUID,
          });
          this.agentMembers[member.memberId] = agentMember;
          agentMember.on('stateChanged', this.agentStateChange);
          agentMember.on('onEnterRoom:failed', (error) => (this.trtcError = error));
        }
      }
      const agentMember = this.agentMembers[member.memberId];
      // 如果只有一个，表示座席单端状态，直接触发，否则进入下面的多端逻辑中
      if (activeAgentMembers.length === 1 || activeAgentMembers.every((member) => member.callState === 'END')) {
        agentMember?.dispatchState(member, data);
      }
    }
    if (activeAgentMembers.length > 1) {
      for (const member of activeAgentMembers) {
        if (member.callState !== 'END') {
          this.agentMembers[member.memberId]?.dispatchState(member, data);
        }
      }
    }
  };

  private handleUserMember = (data: SessionChangedType) => {
    // 其他座席
    for (const member of filterMember(data, this.userId, 'user', this.customer, this.emitter)) {
      if (!this.userMembers[member.memberId] && member.callState !== 'END') {
        if (!this.userMembers[member.memberId]) {
          if (member.serverType === 'queue' && member.userId) {
            // queue的userId必定对应一个member的waitId
            const userMember = data.members.find((userMember) => userMember.waitId === member.memberId);
            if (userMember) {
              // 找到了预先创建
              Object.assign(userMember, {
                memberId: userMember.waitId,
              });
              const baseMember = new UserMember({
                sessionId: this.sessionId,
                member,
                emitter: this.emitter,
                event: data,
              });
              this.userMembers[member.memberId] = baseMember;
              baseMember.on('stateChanged', this.userMemberStateChange);
            } else {
              // 不存在，后台有bug
              this.logger.error('waitId member not found');
            }
          } else {
            // 普通member流程
            const baseMember = new UserMember({
              sessionId: this.sessionId,
              member,
              emitter: this.emitter,
              event: data,
            });
            this.userMembers[member.memberId] = baseMember;
            baseMember.on('stateChanged', this.userMemberStateChange);
          }
        }
      }
      this.userMembers[member.memberId]?.dispatchState(member, data);
    }
  };
  private agentStateChange = ({
    prevState,
    state,
    acceptedState,
    prevAcceptedState,
    hungupParams,
    member,
    event,
  }: {
    prevState: CallState | null;
    state: CallState;
    prevAcceptedState: AcceptedState | null;
    acceptedState: AcceptedState;
    member: Member;
    hungupParams?: HungupParams;
    event: SessionChangedType;
  }) => {
    this.logger.debug(
      `agentStateChanged, sessionState:${statusC[this.status]}, prevState:${prevState} -> state:${state}`,
    );
    if (
      this.status === statusC.NULL &&
      ((state === 'ACCEPTED' && this.direction === Direction.callOut && this.serverType === 'staffSeat') ||
        (state === 'RINGING' && this.direction === Direction.callOut && this.serverType !== 'staffSeat'))
    ) {
      this.emitAgentCallOuted();
    }
    if (this.status <= statusC.RINGING && state === 'ACCEPTED' && this.direction === Direction.callIn) {
      this.emitCallInAccepted({ event, member });
    }
    if (!prevAcceptedState || (prevAcceptedState && prevAcceptedState.MUTE !== acceptedState.MUTE)) {
      if (prevAcceptedState === null) {
        if (acceptedState.MUTE) {
          this.emitter.emit('muted', { audio: false, sessionId: this.sessionId });
        }
      } else {
        if (acceptedState.MUTE) {
          this.emitter.emit('muted', { audio: false, sessionId: this.sessionId });
        } else {
          this.emitter.emit('unmuted', { audio: true, sessionId: this.sessionId });
        }
      }
    }
    this.emitter.emit('memberStateChanged', {
      sessionId: this.sessionId,
      memberId: member.memberId,
      callState: state,
      acceptedState,
      hungupParams,
      userId: member.userId,
      phone: member.phone,
      isHost: member.isHost,
      isSelf: true,
      protectedPhone: member.protectedPhone,
      displayName: member.displayName,
      serverType: member.serverType as ServerType,
      extra: {
        callerLocation: member.extra?.callerLocation,
        skillGroupId: member.extra?.skillGroupId,
      },
      acceptTimestamp: member.acceptTimestamp,
      hungupTimestamp: member.hungupTimestamp,
      waitId: member.waitId,
    });

    if (state === 'END') {
      if (this.status >= statusC.END) return;
      this.emitSessionEnded(hungupParams);
    }
  };

  private emitAgentCallOuted = () => {
    const callOutProps: PhoneCallOut | InternalCallOut =
      this.sessionType === 'CallInternal'
        ? {
            sessionId: this.sessionId,
            userId: this.customer?.userId || '',
            type: SessionEventType.internal,
            serverType: this.serverType,
          }
        : {
            sessionId: this.sessionId,
            callerPhoneNumber: this.customer?.servingNumber || '',
            calleePhoneNumber: this.customer?.phone || this.customer?.protectedPhone || this.customer?.userId || '',
            calleeLocation: this.customer?.callerLocation,
            type: SessionEventType.phone,
            remark: this.customer?.remark,
            serverType: this.serverType,
            aiEnabled: this.serverType === 'staffSeat' && this.emitter.Agent.settings.realtimeAsr,
            isHost: this.isHost,
            tabUUID: this.tabUUID,
            id: this.sessionId,
            startCallTime: Date.now(),
            direction: '1',
            protectedCallee:
              this.emitter.Agent.settings.numberReflectMode !== NumberReflectMode.close ? this.phoneNumber : '',
          };
    this.logger.info('emit agent "callOuted"', callOutProps);
    const session: Session = {
      ...callOutProps,
      status: '150',
      direction: Direction.callOut,
      roomId: '',
      userSig: '',
      unifiedTRTCParams: this.trtcParams.unified ? (this.trtcParams as UnifiedTRTCParams) : undefined,
    };
    this.emitter.Call.upsertOne(session);
    this.emitter.emit('callOut', callOutProps);
    this.status = statusC.RINGING;
  };

  private acceptedStateChanged = ({ prevState, state }: { prevState: AcceptedState | null; state: AcceptedState }) => {
    // 当前只处理接通后放音
    if (prevState?.PLAY_AUDIO && !state.PLAY_AUDIO) {
      this.logger.info('emit play audio stateChange', prevState, state);
      this.emitter.emit('ivrSoundPlayFinished', { sessionId: this.sessionId });
    }
  };

  private customerStateChange = ({
    prevState,
    state,
    prevAcceptedState,
    acceptedState,
    member,
    hungupParams,
  }: {
    prevState: CallState | null;
    state: CallState;
    prevAcceptedState: AcceptedState | null;
    acceptedState: AcceptedState;
    hungupParams?: HungupParams;
    member: Member;
    event: SessionChangedType;
  }) => {
    this.acceptedStateChanged({ prevState: prevAcceptedState, state: acceptedState });

    this.logger.debug(
      `customerStateChange, sessionState:${statusC[this.status]}, prevState:${prevState} -> state:${state}`,
    );

    this.emitter.emit('memberStateChanged', {
      sessionId: this.sessionId,
      memberId: member.memberId,
      callState: state,
      acceptedState,
      hungupParams,
      userId: member.userId,
      isHost: member.isHost,
      isSelf: false,
      phone: member.phone,
      protectedPhone: member.protectedPhone,
      displayName: member.displayName,
      serverType: member.serverType as ServerType,
      extra: {
        callerLocation: member.extra?.callerLocation,
        skillGroupId: member.extra?.skillGroupId,
      },
      acceptTimestamp: member.acceptTimestamp,
      hungupTimestamp: member.hungupTimestamp,
      waitId: member.waitId,
    });

    if (state === 'ACCEPTED' && this.direction === Direction.callIn) {
      if (this.status >= statusC.RINGING) return;
      if (this.type === SessionEventType.voip) {
        return this.emitVoipCallIn();
      }
      if (this.type === SessionEventType.phone) {
        return this.emitPhoneCallIn();
      }
      if (this.type === SessionEventType.internal) {
        return this.emitInternalCallIn();
      }
      return this.logger.info('unknown accepted event in callIn');
    }
    if (state === 'ACCEPTED' && this.direction === Direction.callOut) {
      if (this.status >= statusC.ACCEPTED) return;
      if (this.status === statusC.NULL) {
        // 如果直接跳到接通状态，说明没触发callOut事件
        this.emitAgentCallOuted();
      }
      this.logger.info('emit user "accepted"');
      this.status = statusC.ACCEPTED;
      this.emitter.Call.updateOne(this.sessionId, { status: '200' });
      this.emitter.emit('calloutAccepted', {
        sessionId: this.sessionId,
        isHost: this.isHost,
      });
    }
    if (state === 'END') {
      if (this.status >= statusC.END) return;
    }
  };

  private emitVoipCallIn = async () => {
    const timeout = this.emitter.Agent.settings.audioTimeout;
    this.status = statusC.RINGING;
    try {
      const userProfileRes = await getUserProfile({
        userIDList: [this.phoneNumber],
        tccc: this.emitter,
      }).then(unwrapResult);
      const { nick: nickname, avatar } = userProfileRes[0];
      this.nickname = nickname;
      this.avatar = avatar;
    } catch (e) {
      this.logger.warn('getUserProfile failed', e);
    }
    this.ivrPath = getIvrPath({ keyPress: this.customer?.ivrPress, ivrPressLabel: this.customer?.ivrPressLabel });
    const callInProps: VoipCallIn = {
      sessionId: this.sessionId,
      callee: this.customer?.servingNumber || '',
      caller: this.phoneNumber,
      ivrPath: this.ivrPath,
      calleeRemark: this.customer?.servingNumberRemark,
      clientData: this.customer?.clientData,
      timeout,
      type: SessionEventType.voip,
      nickname: this.nickname,
      avatar: this.avatar,
      serverType: this.serverType,
      aiEnabled: this.serverType === 'staffSeat' && this.emitter.Agent.settings.realtimeAsr,
    };
    const session: Session = {
      ...omit(callInProps, 'members'),
      roomId: this.trtcParams.roomId,
      userId: this.trtcParams.userId,
      userSig: this.trtcParams.userSig,
      privateMapKey: this.trtcParams.privateMapKey,
      unifiedTRTCParams: this.trtcParams.unified ? (this.trtcParams as UnifiedTRTCParams) : undefined,
      status: SessionStatus.ringing,
      direction: Direction.callIn,
    };
    this.emitter.Call.upsertOne(session);

    if (timeout > 0 && session.serverType === 'staffSeat') {
      timer.once(
        session.sessionId,
        async () => {
          // 提前设置状态为end，因为give up api导致的2017事件会在http响应前触发
          this.status = statusC.END;
          try {
            const res = await giveUpSession({
              sessionId: session.sessionId,
              emitter: this.emitter,
            }).then(unwrapResult);
            const endProps: SessionEnded = {
              sessionId: session.sessionId,
              closeBy: 'timer',
              setRest: res.setRest,
              hangupType: 1,
              calleePhoneNumber: this.customer?.servingNumber || '',
              callerPhoneNumber: this.phoneNumber,
              id: this.sessionId,
              calleeLocation: '',
              callerLocation: '',
              ivrPath: this.ivrPath,
              remark: this.customer?.remark,
              protectedCallee: this.customer?.servingNumber || '',
              protectedCaller: this.phoneNumber,
              direction: this.direction === Direction.callIn ? '0' : '1',
              type: this.type,
              serverType: this.serverType,
              timeout: 0,
              duration: 0,
            };
            this.logger.debug('emit sessionEnded', endProps);
            this.emitter.emit('sessionEnded', endProps);
            this.destroySession();
          } catch (e) {
            this.logger.info('giveUp failed', e);
          }
        },
        timeout * 1000,
        () => {
          this.logger.debug('off callIn timer');
        },
      );
    }
    this.logger.info('emit user "callIn"', callInProps);
    this.emitter.emit('callIn', callInProps);
  };
  private emitPhoneCallIn = () => {
    const timeout = this.emitter.Agent.settings.callTimeout;
    this.status = statusC.RINGING;
    this.ivrPath = getIvrPath({ keyPress: this.customer?.ivrPress, ivrPressLabel: this.customer?.ivrPressLabel });
    this.startCallTime = Date.now();
    const callInProps: PhoneCallIn = {
      sessionId: this.sessionId,
      calleePhoneNumber: this.customer?.servingNumber || '',
      callerPhoneNumber: this.phoneNumber,
      protectedCallee: this.customer?.servingNumber || '',
      protectedCaller: this.phoneNumber,
      ivrPath: this.ivrPath,
      callerLocation: this.customer?.callerLocation || '',
      remark: this.customer?.remark,
      timeout,
      type: SessionEventType.phone,
      serverType: this.serverType,
      aiEnabled: this.serverType === 'staffSeat' && this.emitter.Agent.settings.realtimeAsr,
      // 旧字段兼容
      id: this.sessionId,
      phone: this.phoneNumber,
      direction: '0',
      startCallTime: this.startCallTime,
    };
    if (this.sessionType === 'IVRCallOutgoing') {
      Object.assign(callInProps, {
        sessionType: this.sessionType,
      });
    }
    const session: Session = {
      ...omit(callInProps, 'members'),
      roomId: this.trtcParams.roomId,
      userId: this.trtcParams.userId,
      userSig: this.trtcParams.userSig,
      privateMapKey: this.trtcParams.privateMapKey,
      unifiedTRTCParams: this.trtcParams.unified ? (this.trtcParams as UnifiedTRTCParams) : undefined,
      status: SessionStatus.ringing,
      direction: Direction.callIn,
    };
    this.emitter.Call.upsertOne(session);

    if (timeout > 0 && session.serverType === 'staffSeat') {
      timer.once(
        session.sessionId,
        async () => {
          // 提前设置状态为end，因为give up api导致的2017事件会在http响应前触发
          this.status = statusC.END;
          try {
            const res = await giveUpSession({
              sessionId: session.sessionId,
              emitter: this.emitter,
            }).then(unwrapResult);
            const endProps: SessionEnded = {
              sessionId: session.sessionId,
              closeBy: 'timer',
              setRest: res.setRest,
              hangupType: 1,
              id: this.sessionId,
              callerLocation: this.customer?.callerLocation || '',
              calleeLocation: '',
              ivrPath: this.ivrPath,
              remark: this.customer?.remark,
              protectedCallee: this.customer?.servingNumber || '',
              protectedCaller: this.phoneNumber,
              direction: '0',
              type: SessionEventType.phone,
              serverType: this.serverType,
              timeout,
              duration: 0,
            };
            this.logger.debug('emit sessionEnded', endProps);
            this.emitter.emit('sessionEnded', endProps);
            this.destroySession();
          } catch (e) {
            this.logger.info('giveUp failed', e);
          }
        },
        timeout * 1000,
        () => {
          this.logger.debug('off callIn timer');
        },
      );
    }
    this.logger.info('emit user "callIn"', callInProps);
    this.emitter.emit('callIn', callInProps);
  };
  private emitInternalCallIn = () => {
    this.status = statusC.RINGING;
    const callInProps: InternalCallIn = {
      sessionId: this.sessionId,
      userId: this.customer?.userId || '',
      timeout: 0,
      type: SessionEventType.internal,
      serverType: this.serverType,
    };
    const session: Session = {
      ...omit(callInProps, 'members'),
      roomId: this.trtcParams.roomId,
      userId: this.trtcParams.userId,
      userSig: this.trtcParams.userSig,
      privateMapKey: this.trtcParams.privateMapKey,
      unifiedTRTCParams: this.trtcParams.unified ? (this.trtcParams as UnifiedTRTCParams) : undefined,
      status: SessionStatus.ringing,
      direction: Direction.callIn,
    };
    this.emitter.Call.upsertOne(session);
    this.logger.info('emit internal "callIn"', callInProps);
    this.emitter.emit('callIn', callInProps);
  };

  private userMemberStateChange = ({
    prevState,
    state,
    prevAcceptedState,
    acceptedState,
    member,
    hungupParams,
  }: {
    prevState: CallState | null;
    state: CallState;
    event: SessionChangedType;
    prevAcceptedState: AcceptedState | null;
    acceptedState: AcceptedState;
    member: Member;
    hungupParams?: HungupParams;
  }) => {
    this.logger.debug(
      `userMemberStateChange, sessionState:${statusC[this.status]}, prevState:${prevState} -> state:${state}`,
    );

    // 当前session状态为ringing, 但member为accepted状态，说明客人侧已经挂断，还需要触发接听状态表示通话正在进行中
    if (
      this.status === statusC.RINGING &&
      this.direction === Direction.callOut &&
      prevState === null &&
      state === 'ACCEPTED'
    ) {
      this.logger.info('emit user "accepted" from user member state');
      this.status = statusC.ACCEPTED;
      this.emitter.Call.updateOne(this.sessionId, { status: '200' });
      this.emitter.emit('calloutAccepted', {
        sessionId: this.sessionId,
        isHost: this.isHost,
      });
    }

    this.acceptedStateChanged({ prevState: prevAcceptedState, state: acceptedState });

    // 暂时不关注其他member的变更，只触发状态
    this.emitter.emit('memberStateChanged', {
      sessionId: this.sessionId,
      memberId: member.memberId,
      callState: state,
      acceptedState,
      hungupParams,
      userId: member.userId,
      isHost: member.isHost,
      isSelf: false,
      phone: member.phone,
      protectedPhone: member.protectedPhone,
      displayName: member.displayName,
      serverType: member.serverType as ServerType,
      extra: {
        callerLocation: member.extra?.callerLocation,
        skillGroupId: member.extra?.skillGroupId,
      },
      acceptTimestamp: member.acceptTimestamp,
      hungupTimestamp: member.hungupTimestamp,
      waitId: member.waitId,
    });
  };

  private emitCallOutAccepted = () => {
    this.logger.info('emit user "accepted"');
    this.status = statusC.ACCEPTED;
    this.emitter.Call.updateOne(this.sessionId, { status: '200' });
    this.emitter.emit('calloutAccepted', {
      sessionId: this.sessionId,
      isHost: this.isHost,
    });
  };

  private emitCallInAccepted = ({ event, member }: { event: SessionChangedType; member: Member }) => {
    timer.off(this.sessionId);
    // 谁接听，serverType就改为谁
    this.serverType = member.serverType as ServerType;
    this.emitter.Call.updateOne(this.sessionId, {
      serverType: this.serverType,
      status: '200',
    });
    this.status = statusC.ACCEPTED;
    let playAudio = false;
    if (event.members.some((member) => member.acceptedState?.includes('PLAY_AUDIO'))) {
      playAudio = true;
    }
    const acceptedProps: CallInAccepted = {
      sessionId: this.sessionId,
      serverType: this.serverType,
      playAudio,
      isHost: this.isHost,
      tabUUID: this.tabUUID,

      id: this.sessionId,
      ivrPath: this.ivrPath,
      remark: this.customer?.remark,
      calleePhoneNumber: this.customer?.servingNumber || '',
      callerPhoneNumber: this.phoneNumber,
      callerLocation: this.customer?.callerLocation || '',
      protectedCallee: this.customer?.servingNumber || '',
      protectedCaller: this.phoneNumber,
      direction: '0',
      type: this.type,
      timeout: 0,
    };
    this.logger.info('emit agent "accepted"', acceptedProps);
    this.emitter.emit('callInAccepted', acceptedProps);
    // 没有配置接通后放音也要放一个
    if (!playAudio) {
      this.emitter.emit('ivrSoundPlayFinished', { sessionId: this.sessionId });
    }
  };

  private destroySession() {
    SessionManager.deleteSession({
      sdkAppId: this.sdkAppId,
      userId: this.userId,
      sessionId: this.sessionId,
      sdk: this.emitter,
    });
  }

  private emitSessionEnded = (hungupParams?: HungupParams) => {
    let hangupType: t.TypeOf<typeof HangupType> = 1;
    let hangupSide: HangupSide = 'seat';
    if (this.sessionEndState) {
      if (this.sessionEndState.hangupCode) {
        hangupType = this.sessionEndState.hangupCode;
      }
      // 以session为准，否则取member内的挂断方
      if (this.sessionEndState.hangupSide) {
        hangupSide = this.sessionEndState.hangupSide;
      } else {
        if (hungupParams?.side) {
          const { side } = hungupParams;
          if (side === 'SYSTEM') {
            hangupSide = 'system';
          } else if (side === 'LOCAL') {
            hangupSide = 'seat';
          } else if (side === 'REMOTE') {
            hangupSide = 'user';
          } else {
            this.logger.info('unknown hangup side');
          }
        }
      }
    } else {
      if (hungupParams?.side) {
        const { side } = hungupParams;
        if (side === 'SYSTEM') {
          hangupSide = 'system';
        } else if (side === 'LOCAL') {
          hangupSide = 'seat';
        } else if (side === 'REMOTE') {
          hangupSide = 'user';
        } else {
          this.logger.info('unknown hangup side');
        }
      }
      if (hangupSide === 'seat' && (this.status === statusC.NULL || this.status === statusC.RINGING)) {
        // 座席主动取消的
        hangupType = 209;
      }
    }

    let message = '';
    let subMessage = '';
    if (hangupType === 208) {
      message = hungupParams?.message ?? i18next.t('Outbound call internal error');
      subMessage = hungupParams?.subMessage ?? '';
    }
    if (hangupType === 211) {
      subMessage = this.trtcError || i18next.t('Client network error');
    }

    const { mainReason, subReason } = transformHangupType(hangupSide, hangupType, message, subMessage);
    let duration = 0;
    if (this.status === statusC.ACCEPTED && this.startCallTime) {
      duration = Math.floor((Date.now() - this.startCallTime) / 1000);
    }
    this.status = statusC.END;
    const endProps: SessionEnded = {
      sessionId: this.sessionId,
      closeBy: hangupSide,
      hangupType,
      mainReason,
      subReason,
      id: this.sessionId,
      calleeLocation: (this.direction === Direction.callIn ? '' : this.customer?.callerLocation) || '',
      callerLocation: (this.direction === Direction.callOut ? '' : this.customer?.callerLocation) || '',
      ivrPath: this.ivrPath,
      remark: this.customer?.remark,
      // 被叫号码
      calleePhoneNumber: this.direction === Direction.callIn ? this.customer?.servingNumber : this.phoneNumber,
      callerPhoneNumber: this.direction === Direction.callIn ? this.phoneNumber : this.customer?.servingNumber,
      protectedCallee: this.direction === Direction.callIn ? this.customer?.servingNumber : this.phoneNumber,
      protectedCaller: this.direction === Direction.callIn ? this.phoneNumber : this.customer?.servingNumber,
      direction: this.direction === Direction.callIn ? '0' : '1',
      status: '400',
      serverType: this.serverType,
      type: SessionEventType.phone,
      timeout: 0,
      duration,
    };
    this.logger.info(`emit ${hangupSide} "end"`, endProps);
    timer.off(this.sessionId);
    this.emitter.Call.updateOne(this.sessionId, { status: '400' });
    this.emitter.emit('sessionEnded', endProps);
    this.destroySession();
  };
}
