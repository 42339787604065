import { getLogger } from '../common/Logger';
import { TcccSdk } from '../tccc';
type Stream = any;

export default class Recorder {
  mediaRecorder: MediaRecorder;
  audioContext: AudioContext;
  blob: Blob;
  data: {
    remoteId: string;
    seatUserId: string;
    sessionId: string;
  };
  sdk: TcccSdk;
  logger: ReturnType<typeof getLogger>;
  constructor(
    remoteTrack: Stream,
    localTrack: Stream,
    data: {
      remoteId: string;
      seatUserId: string;
      sessionId: string;
      roomId: string;
      sdkAppId: string;
    },
    sdk: TcccSdk,
  ) {
    this.data = data;
    this.blob = new Blob();
    this.sdk = sdk;
    this.logger = getLogger(sdk.Agent.userInfo);

    const remoteMediaStream = new MediaStream();
    const localStreamMediaStream = new MediaStream();
    const remoteStreamAudioTrack = remoteTrack;
    const localStreamAudioTrack = localTrack;
    if (remoteStreamAudioTrack) {
      remoteMediaStream.addTrack(remoteStreamAudioTrack);
    }
    if (localStreamAudioTrack) {
      localStreamMediaStream.addTrack(localStreamAudioTrack);
    }
    this.audioContext = new AudioContext();
    const merger = this.audioContext.createChannelMerger(2);
    const remoteSource = this.audioContext.createMediaStreamSource(remoteMediaStream);
    const localSource = this.audioContext.createMediaStreamSource(localStreamMediaStream);
    remoteSource.connect(merger, 0, 1);
    localSource.connect(merger, 0, 0);
    const dest = this.audioContext.createMediaStreamDestination();
    merger.connect(dest);
    this.mediaRecorder = new MediaRecorder(dest.stream, { mimeType: 'audio/webm' });
    let chunks: Blob[] = [];
    this.mediaRecorder.addEventListener('dataavailable', (e) => {
      chunks.push(e.data);
    });
    this.mediaRecorder.addEventListener('error', () => {
      this.logger.error('recorder error');
    });
    this.mediaRecorder.addEventListener('stop', () => {
      this.blob = new Blob(chunks, { type: 'audio/webm; codecs=opus' });
      this.upload(this.blob);
      chunks = [];
    });
    this.mediaRecorder.addEventListener('start', () => {
      this.logger.debug('recorder start');
    });
  }

  // 开始录音
  start() {
    this.mediaRecorder.start(1000);
  }

  // 结束
  end() {
    if (this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
    }
  }

  // 上传
  upload(blob: Blob) {
    this.sdk.http
      .request(
        '/ccc/report/seatrecord',
        {
          fileData: blob,
          fileName: `${this.data.sessionId}.webm`,
          userId: this.data.remoteId,
          ...this.data,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((res) => {
        this.audioContext?.close();
        this.logger.debug(`record success: ${res.filePath}`, this.data.sessionId);
      })
      .catch((e) => {
        this.audioContext?.close();
        this.logger.error(`save record failed`, e.message, this.data.sessionId);
      });
  }
}
