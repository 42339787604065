import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';
import { Tracer, BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { TpsResourceAttributes } from '@tencent/opentelemetry-semantic-conventions-tps';
import { SEMRESATTRS_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { COMMIT_ID } from '../constants/env';

export const getEnvName = () => {
  const env = {
    development: 'Dev',
    test: 'Test',
    production: '',
  } as const;
  const envName = (process.env.NODE_ENV || 'production') as keyof typeof env;
  return env[envName];
};

/**
 * 初始化 Opentelemetry SDK
 */

export const bootstrap = (international?: boolean): Tracer => {
  const config: {
    url: string;
    id: string;
  } = {
    url: 'https://otlp.tccc.qcloud.com',
    id: 'tccc',
  };
  if (international) {
    Object.assign(config, {
      url: 'https://otlp.connect.tencentcloud.com',
      id: 'tcccsg',
    });
  }
  const resource = new Resource({
    // 如果要上报天机阁，请添加这些字段。更多字段可参考 @tencent/opentelemetry-semantic-conventions-tps
    [TpsResourceAttributes.TPS_TENANT_ID]: config.id,
    [TpsResourceAttributes.APP_ID]: 'tccc-sdk',
    [TpsResourceAttributes.SERVER_ID]: 'tccc-sdk',
    [TpsResourceAttributes.SERVER_NAME]: 'tccc-sdk',
    [SEMRESATTRS_SERVICE_NAME]: 'tccc-sdk',
    env_name: getEnvName(),
    commit: COMMIT_ID,
  });
  // 创建 tracerProvider
  const tracerProvider = new WebTracerProvider({
    // 服务相关的 resource 描述，会在所有上报的 Span 中加入这些 Tags
    resource,
  });

  // 如果要将 trace 信息上报天机阁，请使用 http OTLPTraceExporter 上报至对应 URL
  tracerProvider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        // tps-collector URL
        url: `${config.url}/v1/trace`,
        headers: {
          // 租户 ID
          'X-Tps-TenantID': config.id,
        },
      }),
    ),
  );
  // 全局注册 tracerProvider
  tracerProvider.register({
    // 引入 context manager
    // 注意不要忘记最后的 enable()
    // contextManager: new ZoneContextManager().enable(),
  });

  // Saas才使用
  // @ts-ignore
  if (!(import.meta || import.meta.env || import.meta.env.PROD)) {
    // 注册自动插桩
    // 注意 registerInstrumentations 需要在 tracerProvider.register() 之后调用
    // 确保插桩使用的是注册的 tracerProvider
    registerInstrumentations({
      instrumentations: [
        new DocumentLoadInstrumentation(),
        new XMLHttpRequestInstrumentation({
          ignoreUrls: [
            /\/ccc\/debug\/seatLog/,
            /\/tcccadmin\/feedbackqueue\/queryQueueCount/,
            /\/ccc\/report\/seatConnectionState/,
            /\/ccc\/report\/seatNetworkQuality/,
            /(AVQualityReportSvc|tim_web_report_v2)/,
            /https:\/\/tpstelemetry.tencent.com/,
            /https:\/\/otlp.tccc.qcloud.com/,
            /https:\/\/otlp.connect.tencentcloud.com/,
            /https:\/\/qcloudimg.tencent-cloud.cn/,
            /https:\/\/schedule.rtc.qcloud.com/,
            /https:\/\/schedule.rtc.qq.com/,
            /^https:\/\/aegis.qq.com/,
            /^https:\/\/imgcache.qq.com/,
            /^https:\/\/web.sdk.qcloud.com/,
          ],
          propagateTraceHeaderCorsUrls: [
            /^https:\/\/((local|test|pre)\.)?ccclogic\.pstn\.avc\.qcloud\.com/,
            /^https:\/\/api\.ccc\.cloud\.tencent\.(com\.)?cn/,
            /^https:\/\/api\.tccc\.qcloud\.com/,
            /^https:\/\/connect\.tencentcloud\.\.com/,
          ],
        }),
      ],
    });
  }
  return tracerProvider.getTracer('tccc-sdk');
};
