import EventEmitter from 'eventemitter3';
import Logger from 'tccc-logger';
import { WebsocketInterface } from '../WebsocketInterface';

/**
 * 通用Websocket层，替换小程序等平台;
 */
export class BrowserWebsocketInterface
  extends EventEmitter<{
    connect: () => void;
    disconnect: (ev: CloseEvent & { wasClean?: boolean }) => void;
    data: (data: string) => void;
    error: (ev: Event) => void;
  }>
  implements WebsocketInterface
{
  url: string;
  logger: Logger;
  private ws?: WebSocket;
  constructor(url: string, options?: { logger?: Logger }) {
    super();
    this.url = url;
    this.logger = options?.logger || (console as unknown as Logger);
    this.logger.debug('new()');
  }

  send(message: string) {
    if (this.ws && this.isConnected()) {
      this.ws.send(message);
      return true;
    }
    this.logger.info('unable to send message, Websocket is not open');
    return false;
  }
  connect() {
    this.logger.info('connect()');

    if (this.isConnected()) {
      this.logger.debug(`already connected`);
      return;
    }
    if (this.isConnecting()) {
      this.logger.debug(`already connecting`);
      return;
    }
    if (this.ws) {
      this.disconnect();
    }
    this.logger.debug(`connecting to Websocket ${this.url}`);

    try {
      this.ws = new WebSocket(this.url);
      this.ws.addEventListener('open', this.onOpen);
      this.ws.addEventListener('message', this.onMessage);
      this.ws.addEventListener('close', this.onClose);
      this.ws.addEventListener('error', this.onError);
      this.logger.debug('new websocket success');
    } catch (e) {
      this.onError(e as Event);
    }
  }

  disconnect(code?: number, reason?: string) {
    this.logger.info({ msg: 'disconnect()', attributes: { code, reason } });
    if (this.ws) {
      this.ws.removeEventListener('open', this.onOpen);
      this.ws.removeEventListener('message', this.onMessage);
      this.ws.removeEventListener('close', this.onClose);
      this.ws.removeEventListener('error', this.onError);
      this.ws.close(code, reason);
      this.ws = undefined;
      const closeEvent = new CloseEvent('close', { code: code || 1005, wasClean: true, reason });
      this.emit('disconnect', closeEvent);
    }
  }

  isConnected() {
    if (!this.ws) return false;
    return this.ws && this.ws.readyState === this.ws.OPEN;
  }
  isConnecting() {
    if (!this.ws) return false;
    return this.ws && this.ws.readyState === this.ws.CONNECTING;
  }

  onOpen = () => {
    this.logger.info('connected');
    this.emit('connect');
  };

  onClose = (e: CloseEvent) => {
    if (e.code === 1006) {
      this.logger.warn(`closed ${e.code}`);
    } else {
      this.logger.info(`closed ${e.code}`);
    }
    if (!e.wasClean) {
      this.logger.info('abrupt disconnection');
    }
    this.emit('disconnect', e);
  };

  onMessage = ({ data }: MessageEvent) => {
    this.emit('data', data);
  };

  onError = (e: Event) => {
    this.logger.warn('error');
    this.emit('error', e);
  };
}
