import * as t from 'io-ts';
import { unifiedTRTCParams } from '../../socket/s2cEvent';
import { booleanFromNumber0or1 } from 'tccc-utils';

export const List = t.type({
  menuId: t.string,
  menuName: t.string,
  lastUpdateTime: t.number,
  menuContent: t.string,
  menuSendRuleFlag: t.string,
});

export const imAPIs = {
  '/ccc/im/sendSatisfaction': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/im/seatin': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/im/endSession': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/im/completeSession': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/im/seatForward': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({
      errorCode: t.string,
    }),
  },
  '/ccc/im/startMedia': {
    Input: t.type({
      type: t.union([t.literal('video'), t.literal('audio')]),
      sessionId: t.string,
    }),
    Output: t.intersection([
      t.type({
        sdkAppId: t.string,
        userId: t.string,
        userSig: t.string,
        roomId: t.string,
        privateMapKey: t.string,
      }),
      t.partial({
        unifiedTRTCParams,
      }),
    ]),
  },
  '/ccc/im/stopMedia': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/im/seatGiveUp': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.partial({
      setRest: booleanFromNumber0or1,
    }),
  },
};
