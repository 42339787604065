import * as t from 'io-ts';
import pick from 'lodash/pick';
import { timer, getOrPromiseReject } from 'tccc-utils';
import { getLogger } from '../../common/Logger';
import { Direction, SessionType } from '../../constants/sessions';
import traceContext from '../../http/tracer';
import type { TcccSdk } from '../../tccc';
import { SessionIdParams } from './sessions.thunk';
import { enterRoom, leaveRoom } from './trtc.thunk';
import { createAsyncThunk } from '../createAsyncThunk';
import { getWebRTCInstance } from '../../trtc/trtc';
import { NotFoundError } from '../../common/TcccError';

export const accessVideoCall = createAsyncThunk(
  'video/access',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    timer.off(params.sessionId);
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    const session = params.emitter.Chat.selectOne(params.sessionId);
    const logger = getLogger(params.emitter.Agent.userInfo);

    if (!session) {
      throw new Error(`access video failed, sessionId: ${sessionId} does not exist`);
    }
    if (session.type !== SessionType.video) {
      throw new Error(`access video failed, sessionId ${sessionId} type does not match`);
    }
    try {
      await params.emitter.http.request('/ccc/im/seatin', {
        sessionId,
      });
    } catch (e) {
      logger.error('video seatin failed', e);
      throw e;
    }
    if (session.autoEnter || session.currentMedia) {
      try {
        const { sdkAppId, userId, userSig, privateMapKey, roomId, unifiedTRTCParams } =
          await params.emitter.http.request('/ccc/im/startMedia', {
            sessionId,
            type: 'video',
          });
        const trtcParams = unifiedTRTCParams?.unified
          ? unifiedTRTCParams
          : {
              unified: false,
              sdkAppId,
              userId,
              userSig,
              roomId,
              privateMapKey,
            };
        if (
          trtcParams.sdkAppId &&
          trtcParams.userId &&
          trtcParams.userSig &&
          trtcParams.roomId &&
          trtcParams.privateMapKey
        ) {
          const span = traceContext.tracer.startSpan('enterRoom', {
            attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
          });
          try {
            await enterRoom({
              sessionId,
              ...trtcParams,
              emitter: params.emitter,
              type: SessionType.video,
              direction: Direction.callOut,
              parentSpan: span,
            });
            params.emitter.emit('callInAccepted', {
              sessionId,
              id: sessionId,
              userId: session.userId,
              remark: session.remark,
              nickname: session.nickname,
              tabUUID: params.emitter.uuid,
              ivrPath: [],
              type: SessionType.video,
              direction: '0',
              timeout: 0,
              callerPhoneNumber: '',
              calleePhoneNumber: '',
              protectedCallee: '',
              protectedCaller: '',
            });
          } catch (e) {
            logger.error('video call enterRoom failed');
            throw e;
          }
        } else {
          throw new Error('startMedia error, userSig or roomId does not exist');
        }
      } catch (e) {
        logger.error('startMedia failed', `sessionId: ${sessionId}`);
        // TODO: transfer / end / giveup
        await params.emitter.http.request('/ccc/im/endSession', {
          sessionId,
        });
        throw e;
      }
    }
  },
);
const EndVideoSession = t.type({
  sessionId: t.string,
  closeBy: t.union([t.literal('seat'), t.literal('client'), t.literal('admin'), t.literal('timer')]),
});
export const endVideoSession = createAsyncThunk(
  'video/end',
  async (params: t.TypeOf<typeof EndVideoSession> & { emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    const { sessionId, closeBy } = await getOrPromiseReject(EndVideoSession)(params);
    if (closeBy === 'seat' || closeBy === 'admin') {
      try {
        await params.emitter.http.request('/ccc/im/endSession', {
          sessionId,
        });
      } catch (e) {
        // 结论：座席端的愿意是无条件需要挂断成功，但我们的场景是因为和服务端有交互，所以挂断是不一定成功的。
        // 讨论结论是兼容座席愿意，程序帮他重试pstn/end增加成功概率，如果还是失败了也告知座席挂断失败了，让他 重试或者让用户侧挂断或者等待用户侧挂断
        logger.error('video end session failed, try again', e);
        try {
          await params.emitter.http.request('/ccc/im/endSession', {
            sessionId,
          });
          logger.info('again video end session success', e);
        } catch (againEx) {
          logger.error('again video end session failed', againEx);
          throw againEx;
        }
      }
    }
    leaveRoom({
      sessionId,
      parentSpan: traceContext.tracer.startSpan('tccc.Video.end', {
        attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
      }),
      emitter: params.emitter,
    });
    return {
      sessionId,
    };
  },
);

export const TransferParams = t.union([
  t.type({ sessionId: t.string, userId: t.string }),
  t.type({ sessionId: t.string, skillGroupId: t.string }),
]);
export const transferVideoSession = createAsyncThunk(
  'video/transfer',
  async (params: t.TypeOf<typeof TransferParams> & { emitter: TcccSdk }) => {
    let userId;
    let skillGroupId;
    const { sessionId, ...validParams } = await getOrPromiseReject(TransferParams)(params);
    if ('userId' in validParams) {
      userId = validParams.userId;
    } else {
      skillGroupId = validParams.skillGroupId;
    }
    const session = params.emitter.Chat.selectOne(sessionId);
    if (!session) {
      throw new Error(`transfer failed, sessionId:${sessionId} does not exist`);
    }
    await params.emitter.http.request('/ccc/im/seatForward', {
      sessionId,
      skillGroupId,
      userId,
    });
    leaveRoom({
      sessionId,
      parentSpan: traceContext.tracer.startSpan('tccc.Video.transfer', {
        attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
      }),
      emitter: params.emitter,
    });
    return {
      sessionId,
    };
  },
);
export const PlayParams = t.intersection([
  t.type({
    sessionId: t.string,
    id: t.any,
  }),
  t.partial({
    local: t.boolean,
  }),
]);
export const play = createAsyncThunk('playVideo', async (params: t.TypeOf<typeof PlayParams> & { sdk: TcccSdk }) => {
  const { sessionId, id, local } = await getOrPromiseReject(PlayParams)(params);

  const webRtc = getWebRTCInstance({ sdk: params.sdk, sessionId });
  if (!webRtc) {
    throw new NotFoundError('session', sessionId);
  }
  return webRtc.playStream({ id, local });
});
