import EventEmitter from 'eventemitter3';
import { ulid } from 'ulid';
import { UserStatus } from 'tccc-utils';
import * as t from 'io-ts';
import { SocketMessage } from './socket';
// @ts-ignore
import SocketWorker from './socket.worker';
import { Agent } from '../tccc/Agent';
import { PortMessageType } from './worker/port';
import { getLogger } from '../common/Logger';
import { APIs } from '../http/apis';
import { TcccWebsocketEvent } from 'tccc-ws/src/platform/TcccWebsocket';
import i18next, { getBackendLanguage } from '../i18n/i18next.config';
import { isLocal } from 'tccc-env/src/env';
export class SocketClient<T extends SocketMessage> extends EventEmitter<TcccWebsocketEvent<T>> {
  private worker: SharedWorker;
  private loginParams?: { sdkAppId: string; userId: string; sessionKey: string; origin: string; language: string };
  private unhealthyCount = 0;
  private aliveTimer: ReturnType<typeof setTimeout> | null = null;
  private readonly name: string;
  private readonly logger: ReturnType<typeof getLogger>;
  private readonly nonceEvent = new EventEmitter();
  constructor(agent: Agent) {
    super();
    this.logger = getLogger(agent.userInfo);
    this.name = agent.isInternational ? 'tccc-worker-intl' : 'tccc-worker';
    // @ts-ignore
    this.worker =
      !Boolean(process.env.VITE_APP_MODE) || isLocal
        ? new SharedWorker(/* webpackChunkName: "tccc-worker" */ new URL('./socket.worker.ts', import.meta.url), {
            /* @vite-ignore */
            name: this.name,
          })
        : new SocketWorker({ name: this.name });
    // close之后再次start会无效，所以需要一直处于开启状态
    this.worker.port.start();
  }

  login(params: { sdkAppId: string; userId: string; sessionKey: string; origin: string }) {
    const loginParams = { ...params, language: getBackendLanguage(i18next.language) };
    this.loginParams = loginParams;
    this.worker.port.start();
    this.worker.port.addEventListener('message', this.handlePortMessage);
    window.addEventListener('beforeunload', this.handleBeforeUnLoad);
    return this.postMessage('login', loginParams)
      .then((res) => {
        this.logger.debug('socket.client login success', res);
        return res;
      })
      .catch((err) => {
        this.logger.warn('socket.client login error', err);
        return Promise.reject(err);
      });
  }

  logout(force?: string) {
    this.unhealthyCount = 0;
    if (this.aliveTimer) {
      clearTimeout(this.aliveTimer);
      this.aliveTimer = null;
    }
    this.postMessage('logout', force)
      .then(() => {
        const state: T = {
          status: 0,
          logicState: 'idle',
          logicStatus: 'offline',
          reason: '',
          IM: {
            total: 0,
            used: 0,
          },
          queueCount: {},
        } as unknown as T;
        this.emit('heartbeat', state);
      })
      .catch((err) => {
        this.logger.info('ignore logout error', err);
      })
      .finally(() => {
        // 超时忽略，继续移除
        this.worker.port.removeEventListener('message', this.handlePortMessage);
        window.removeEventListener('beforeunload', this.handleBeforeUnLoad);
        if (this.aliveTimer) {
          clearTimeout(this.aliveTimer);
          this.aliveTimer = null;
        }
      });
  }

  setStatus<T extends keyof typeof UserStatus>(status: T, restReason?: string): Promise<T> {
    return this.postMessage('setStatus', {
      status,
      restReason,
    })
      .then((res) => {
        if (res.data?.errorCode === '0') {
          return res.data;
        }
        return Promise.reject(res.data);
      })
      .catch((err) => {
        this.logger.info('catch worker setStatus error', err);
        return Promise.reject(err);
      });
  }

  call(
    callee: t.TypeOf<typeof APIs['/ccc/pstn/dial']['Input']>['callee'] | { sessionId: string },
    extra: Omit<t.TypeOf<typeof APIs['/ccc/pstn/dial']['Input']>, 'callee'>,
  ) {
    return this.postMessage('call', {
      callee,
      ...extra,
    })
      .then((res) => {
        if (res.data?.errorCode === '0') {
          return res.data;
        }
        return Promise.reject(res.data);
      })
      .catch((err) => {
        this.logger.info('catch worker call error', err);
        return Promise.reject(err);
      });
  }

  accept(sessionId: string) {
    return this.postMessage('accept', {
      sessionId,
    })
      .then((res) => {
        if (res.data?.errorCode === '0') {
          return res.data;
        }
        return Promise.reject(res.data);
      })
      .catch((err) => {
        this.logger.info('catch worker accept error', err);
        return Promise.reject(err);
      });
  }

  setLanguage(language: string) {
    return this.postMessage('setLanguage', { language })
      .then((res) => {
        if (res.data === 'ok') {
          return res.data;
        }
        return Promise.reject(res.data);
      })
      .catch((err) => {
        this.logger.info('catch worker set language error', err);
        return Promise.reject(err);
      });
  }

  private handleBeforeUnLoad = () => this.logout('beforeUnload');

  private postMessage<T = string>(command: T, data: any): Promise<{ command: T; data: any }> {
    return new Promise((resolve, reject) => {
      const nonce = ulid();
      const timer = setTimeout(() => {
        this.unhealthyCount = this.unhealthyCount + 1;
        this.logger.warn('post port message timeout');
        reject('post port message timeout');
        if (this.unhealthyCount > 2) {
          this.rebuildWorker();
        }
      }, 5000);
      this.nonceEvent.once(nonce, (args) => {
        this.unhealthyCount = 0;
        clearTimeout(timer);
        if (args?.data && typeof args.data === 'object' && args.data.errorCode !== '0') {
          return reject(args.data);
        }
        resolve(args);
      });
      this.worker.port.postMessage({ data, nonce, command });
    });
  }

  private handlePortMessage = (event: MessageEvent<PortMessageType>) => {
    const checkWorkerAlive = () => {
      if (this.aliveTimer) {
        clearTimeout(this.aliveTimer);
        this.aliveTimer = null;
      }
      // 5s没收到worker message，需要检查worker是否存活
      this.aliveTimer = setTimeout(() => {
        this.postMessage('alive', {}).catch(() => {
          // unhealthy
          checkWorkerAlive();
        });
      }, 5000);
    };
    checkWorkerAlive();

    if ('type' in event.data) {
      this.resolveWorkerMessage(event);
      if (event.data.type === 'connect') {
        this.emit('connect');
      }
      if (event.data.type === 'disconnect') {
        this.emit('disconnect', { code: event.data.data.code, reason: event.data.data.reason });
      }
      if (event.data.type === 'heartbeat') {
        const e = event.data.data.message as T;
        const tabUUId = event.data.data.tabUUId as string;
        this.emit('heartbeat', e, tabUUId);
      }
      if (event.data.type === 's2c') {
        const message = event.data.data.message as T;
        const tabUUId = event.data.data.tabUUId as string;
        this.emit('s2c', message, tabUUId);
      }
    } else {
      // command resolve
      this.nonceEvent.emit(event.data.nonce, event.data);
    }
  };

  private resolveWorkerMessage = (event: MessageEvent<PortMessageType>) => {
    this.worker.port.postMessage({ nonce: event.data.nonce });
  };

  private rebuildWorker = () => {
    this.logger.info('rebuild worker');
    if (!this.loginParams) {
      this.logger.debug('ignore rebuild worker');
      return;
    }
    this.unhealthyCount = 0;
    this.worker.port.close();
    this.worker.port.removeEventListener('message', this.handlePortMessage);
    window.removeEventListener('beforeunload', this.handleBeforeUnLoad);
    // @ts-ignore
    // saas
    this.worker = !Boolean(process.env.VITE_APPM_MODE)
      ? new SharedWorker(/* webpackChunkName: "tccc-worker" */ new URL('./socket.worker.ts', import.meta.url), {
          name: this.name,
        })
      : // sdk-v2
        new SocketWorker({ name: this.name });
    this.login(this.loginParams)
      .then((res) => {
        this.logger.debug('rebuild worker success', res);
      })
      .catch((err) => {
        this.logger.error('rebuild worker error', err);
      });
  };
}
