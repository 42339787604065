import {
  startRealtimeAsr as startRealtimeAsrTrunk,
  stopRealtimeAsr as stopRealtimeAsrTrunk,
} from '../store/sessions/asr.thunk';
import { TcccSdk } from '../tccc';
export class ASR {
  sdk: TcccSdk;
  constructor(sdk: TcccSdk) {
    this.sdk = sdk;
  }

  startRealtimeAsr = (params: Omit<Parameters<typeof startRealtimeAsrTrunk>[0], 'sdk'>) =>
    startRealtimeAsrTrunk({ ...params, sdk: this.sdk });

  stopRealtimeAsr = (params: Omit<Parameters<typeof stopRealtimeAsrTrunk>[0], 'sdk'>) =>
    stopRealtimeAsrTrunk({ ...params, sdk: this.sdk });
}
