import * as t from 'io-ts';
import { booleanFromString, booleanFromString0or1, nullableArray, numberFromString } from 'tccc-utils';

export const captchaType = t.intersection([
  t.type({
    ticket: t.string,
    randstr: t.string,
    appid: t.string,
  }),
  t.partial({
    errorCode: t.number,
    errorMessage: t.string,
  }),
]);

const captchaModule = t.union([
  t.literal('login'),
  t.literal('bindMobile'),
  t.literal('changePass'),
  t.literal('changeMobile'),
  t.literal('loginDemo'),
]);

export type CaptchaModule = t.TypeOf<typeof captchaModule>;

export const loginInstanceResponseC = t.intersection([
  t.type({
    im_sig: t.string,
    appInfo: t.partial({
      callTimeOut: numberFromString,
      endCountDown: numberFromString,
      imEndCountDown: numberFromString,
      imTimeOut: numberFromString,
      smsPrivilege: booleanFromString0or1,
    }),
    staff: t.type({
      email: t.string,
      mobile: t.string,
      nickName: t.string,
      roleId: t.string,
      sdkAppId: t.string,
      skillGroupId: nullableArray(t.string),
      staffName: t.string,
      staffNo: t.string,
    }),
    session_key: t.string,
  }),
  t.partial({
    isBindMobile: t.union([t.boolean, booleanFromString]),
  }),
]);

const commonLoginResponseC = t.type({
  errorCode: t.string,
});

const loginResponseC = t.intersection([
  commonLoginResponseC,
  t.type({
    isBindMobile: booleanFromString,
  }),
]);
export const loginAPIs = {
  '/tccclogin/login/checkLogin': {
    Input: t.type({}),
    Output: t.intersection([
      loginResponseC,
      t.type({
        userId: t.string,
      }),
    ]),
  },
  '/tccclogin/account/bindMobile': {
    Input: t.type({
      mobile: t.string,
      captcha: t.type({
        value: t.string,
        sessionId: t.string,
        module: t.string,
      }),
      isInstance: t.boolean,
    }),
    Output: t.type({}),
  },
  '/tccclogin/account/updateMobile': {
    Input: t.type({
      oldMobile: t.string,
      mobile: t.string,
      smsCaptcha: t.type({ module: t.string, sessionId: t.string, value: t.string }),
      userid: t.string,
    }),
    Output: t.type({}),
  },
  '/tccclogin/captcha/getCaptcha': {
    Input: t.type({
      module: captchaModule,
      mobile: t.string,
      captcha: captchaType,
    }),
    Output: t.type({
      sessionId: t.string,
    }),
  },
  '/tccclogin/account/changePass': {
    Input: t.type({
      emails: t.array(t.string),
      newPasswd: t.string,
      isInstance: t.boolean,
    }),
    Output: t.type({}),
  },
  '/tccclogin/login/loginInstance': {
    Input: t.type({
      staff: t.type({
        sdkAppId: t.string,
      }),
      sdkAppId: t.string,
      userId: t.string,
    }),
    Output: t.intersection([
      loginInstanceResponseC,
      t.type({
        session_key: t.string,
      }),
      t.partial({
        isBindMobile: t.union([t.boolean, booleanFromString]),
      }),
    ]),
  },
  '/tccclogin/login/checkSDKLoginToken': {
    Input: t.type({
      staff: t.type({
        userid: t.string,
      }),
      sdkAppId: t.string,
      token: t.string,
    }),
    Output: loginInstanceResponseC,
  },
  '/tccclogin/login/createWebLoginToken': {
    Input: t.type({}),
    Output: t.type({
      token: t.string,
      userid: t.string,
    }),
  },
};
