// 自定义消息类型
export const CUSTOM_MESSAGE_SRC = {
  // 公众号
  OFFICIAL_ACCOUNT: '1',
  // 小程序
  MINI_APP: '2',
  // 小程序服务号
  MINI_APP_SERVICE_ACCOUNT: '3',
  // 后台内部
  BACKEND_INTERNAL: '4',
  // 网页
  WEB: '5',
  // 会话消息分割
  SESSION_MESSAGE_SLICE: '6',
  // 小程序自动触发
  MINI_APP_AUTO: '7',
  // 内部会话
  INTERNAL: '8',
  // 菜单消息
  MENU: '9',
  // 菜单选择
  MENU_SELECTED: '10',
  // 客户端在线状态
  CLIENT_STATE: '11',
  // 输入状态
  TYPING_STATE: '12',

  // 微信客服
  WX_KF: '14',

  /** 用户端通知 */
  MEDIA_CHANGE: '16',
  // 相当于2017事件
  MEMBER: '17',

  // 无座席在线
  NO_SEAT_ONLINE: '18',

  // 会话正常结束
  END: '19',

  // 呼入超时
  TIMEOUT: '20',
  /** END -- 用户端通知 -- END */
};

// 下发满意度错误码
export const SEND_RATING_ERROR_CODE = {
  // 会话过期或还未开始
  SESSION_EXPIRED_OR_NOT_START: '-9006',
  // 重复操作
  DUPLICATE_SUBMIT: '-9501',
};
