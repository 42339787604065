import { UserStatus } from 'tccc-utils';
import Logger from 'tccc-logger';
import get from 'lodash/get';
import { getLogger } from '../common/Logger';
import { MSG_EVENTS } from '../constants/trtc';
import type { TcccSdk } from '../tccc';
import {
  IMCallInInvite,
  inviteCallStateChanged,
  receiveStateChanged,
  asrEvent,
  IMHangup,
  IMOnlineStateChanged,
} from './s2cHandler';
import { UseMobileCallOut } from '../constants/sessions';
import { SessionManager, SessionSummary } from './session-manager/session-manager';
import { checkSessionSeq } from './session-manager/checkSession';
import { AgentStateType, emitAgentState, emitUserSettings } from '../tccc/Agent';
import i18next, { getBackendLanguage } from '../i18n/i18next.config';
import { SocketClient } from './socket.client';
import TcccWebsocket from 'tccc-ws';
import { Span, context, trace } from '@opentelemetry/api';
import { ServerType } from '../store/sessions';
import { RequestError } from '../common/TcccError';
import { GET_API_BAK_DOMAIN, GET_API_DOMAIN } from 'tccc-env/src/url';

export type SocketMessage = {
  errorCode: string;
  msg: string;
  nonce: string;
  event?: string;
  command?: 's2c';
  sessionSummaryList?: null | SessionSummary[];
} & Omit<AgentStateType, 'status'> & { status: string } & Partial<{
    useMobileAccept: 0 | 1 | 2;
    useMobileCallOut: boolean;
  }>;

const enableWorker = (enableShared: boolean) => window.SharedWorker && enableShared;

export class RtcSocket {
  userId: string;
  sdkAppId: string;
  emitter: TcccSdk;
  running: boolean;
  eventQueue: Array<[SocketMessage, string]>;
  ws: SocketClient<SocketMessage> | TcccWebsocket<SocketMessage>;
  logger: ReturnType<typeof getLogger>;

  constructor({ sdk: emitter, enableShared }: { sdk: TcccSdk; enableShared: boolean }) {
    if (!emitter.Agent.userInfo) {
      throw new Error('Authorization failed');
    }

    this.emitter = emitter;
    this.userId = emitter.Agent.userInfo.userId;
    this.sdkAppId = emitter.Agent.userInfo.sdkAppId;
    this.logger = getLogger(emitter.Agent.userInfo);
    const url = `wss://${GET_API_DOMAIN(emitter.Agent.origin)}/staff`;
    const backupUrl = `wss://${GET_API_BAK_DOMAIN(emitter.Agent.origin)}/staff`;
    this.ws = enableWorker(enableShared)
      ? // worker方案
        new SocketClient(emitter.Agent)
      : // 降级方案
        new TcccWebsocket({
          logger: this.logger as unknown as Logger,
          url,
          backupUrl,
          language: getBackendLanguage(i18next.language),
        });
    this.ws.on('heartbeat', (e, tabUUID) => {
      const state: AgentStateType = {
        status: +e.status,
        logicState: e.logicState,
        logicStatus: e.logicStatus,
        reason: e.reason,
        IM: e.IM,
        queueCount: e.queueCount,
      };
      emitAgentState(this.emitter, state);
      if ('useMobileAccept' in e && 'useMobileCallOut' in e) {
        emitUserSettings(this.emitter, {
          useMobileAcceptType: e.useMobileAccept,
          useMobileCallOutType: e.useMobileCallOut ? UseMobileCallOut.on : UseMobileCallOut.off,
        });
      }
      checkSessionSeq({
        sdkAppId: this.sdkAppId,
        userId: this.userId,
        emitter: this.emitter,
        summaryList: e.sessionSummaryList,
        tabUUID: tabUUID as unknown as string,
      });
    });
    this.ws.on('connect', () => {
      this.emitter.emit('connectionStateChanged', { state: 'connected' });
    });
    this.ws.on('disconnect', () => {
      this.emitter.emit('connectionStateChanged', { state: 'disconnected' });
    });
    // @ts-ignore
    this.ws.on('s2c', (message, tabUUID) => {
      this.handleMessage(message, tabUUID as unknown as string);
    });
    this.eventQueue = [];
    this.running = false;
  }
  handleMessage(event: SocketMessage, tabUUID = '') {
    const data = event;
    const MsgEvent = get(data, 'event');
    if (data.errorCode && [MSG_EVENTS.OptRedisFailed, MSG_EVENTS.SetStatusConflict].includes(data.errorCode)) {
      return;
    }
    if (data.errorCode && data.errorCode !== '0') {
      this.logger.error(`socket response error ${data.errorCode}`);
      return;
    }
    // isTestEnv && console.log('[INFO] [WS] Message from server ', event.data);
    if (data.command === 's2c') {
      if (data.event && ![MSG_EVENTS.AsrResult].includes(data.event)) {
        this.logger.debug(`s2c response,`, `nonce: ${data.nonce}, event: ${data.event}`);
      }
    }
    if (MsgEvent) {
      this.enqueueEvent(data, tabUUID);
    }
  }

  enqueueEvent(data: SocketMessage, tabUUID: string) {
    if (data.event && [MSG_EVENTS.AsrResult].includes(data.event)) {
      return this.onHandleDataEvent(data, tabUUID);
    }
    this.eventQueue.push([data, tabUUID]);
    if (this.running) return;
    this.consumeEvent();
  }
  async consumeEvent() {
    this.running = true;
    if (this.eventQueue.length > 0) {
      const queueData = this.eventQueue.shift() || [];
      const [data, tabUUID] = queueData;
      try {
        await this.onHandleDataEvent(data, tabUUID);
      } catch (e) {
        this.logger.error('s2c handle failed', e);
      } finally {
        await this.consumeEvent();
      }
    } else {
      this.running = false;
    }
  }

  async onHandleDataEvent(data: any, tabUUID?: string) {
    // 1: 电话呼入邀请，attendee是主叫 2: 通知客户座席信息，attendee是座席信息 3: 对方振铃 4: 对方接听 5: 对方挂断 8: im接入 7 视频接入 9 音频接入 100:没有心跳断线 101:多处登录踢下线
    const MsgEvent = data.event;
    if (MsgEvent && ![MSG_EVENTS.AsrResult].includes(MsgEvent)) {
      this.logger.debug('Message from Server', data);
    }

    if (MsgEvent === MSG_EVENTS.UpdateAgentState) {
      const { newStatus } = data;
      const changed: AgentStateType = {
        status: +newStatus.status,
        logicState: newStatus.logicState,
        logicStatus: newStatus.logicStatus,
        reason: newStatus.reason,
        IM: newStatus.IM,
      };
      emitAgentState(this.emitter, changed);
    }

    if (MSG_EVENTS.IMHangUp === MsgEvent) {
      return IMHangup(data, this.emitter);
    }
    // 强制下线事件
    if (MsgEvent === MSG_EVENTS.ForceOffline) {
      this.emitter.emit('forcedOffline');
      this.logout('forcedOffLine');
      return;
    }
    if (MsgEvent === MSG_EVENTS.OfflineMultipleLogin) {
      this.emitter.emit('kickedOut');
      this.logout('kickedOut');
      return;
    }
    // IM 接入
    if (MsgEvent === MSG_EVENTS.ImCallIn) {
      return IMCallInInvite(data, this.emitter);
    }
    if (MsgEvent === MSG_EVENTS.BroadcastForwardAnswered) {
      return inviteCallStateChanged({ ...data, state: 'accepted' }, this.emitter);
    }
    if (MsgEvent === MSG_EVENTS.BroadcastForwardHangup) {
      return inviteCallStateChanged({ ...data, state: 'hangup' }, this.emitter);
    }
    if (MsgEvent === MSG_EVENTS.BroadcastForwardFailed) {
      return inviteCallStateChanged({ ...data, state: 'failed' }, this.emitter);
    }
    if (MsgEvent === MSG_EVENTS.InputCallback) {
      return receiveStateChanged(data, this.emitter);
    }
    if (MsgEvent === MSG_EVENTS.MemberListChange) {
      SessionManager.createSession({
        sdkAppId: this.sdkAppId,
        userId: this.userId,
        data,
        emitter: this.emitter,
        tabUUID: tabUUID || '',
      });
      return;
    }

    if (MsgEvent === MSG_EVENTS.ImStateChange) {
      return IMOnlineStateChanged(data, this.emitter);
    }
    if (MsgEvent === MSG_EVENTS.AsrResult) {
      return asrEvent(data, this.emitter);
    }
  }
  login({ sdkAppId, userId, sessionKey }: { sdkAppId: string; userId: string; sessionKey: string }) {
    return new Promise<void>((resolve, reject) => {
      // login success
      this.ws.once('connect', resolve);
      // 连续5次连接失败报错
      this.ws.once('failed', (err) => {
        if (err.code === '-998') {
          reject({
            ...err,
            message: i18next.t('Connection failed. Please check your network'),
          });
        } else {
          reject(err);
        }
      });
      this.userId = userId;
      this.sdkAppId = sdkAppId;

      return (
        this.ws
          // @ts-ignore
          .login({ sdkAppId, userId, sessionKey, tabUUId: this.emitter.uuid, origin: this.emitter.Agent.origin })
          .then(() => {
            resolve();
          })
          .catch((e) => {
            reject(e);
          })
      );
    });
  }
  setStatus<T extends keyof typeof UserStatus>(status: T, restReason?: string) {
    return this.ws.setStatus(status, restReason);
  }
  logout(force?: string) {
    this.ws.logout(force);
    const state: AgentStateType = {
      status: 0,
      logicState: 'idle',
      logicStatus: 'offline',
      reason: '',
      IM: {
        total: 0,
        used: 0,
      },
      queueCount: {},
    };
    if (force) {
      emitAgentState(this.emitter, state);
    }
    this.emitter.emit('connectionStateChanged', { state: 'disconnected' });
  }
  accept = ({ sessionId }: { sessionId: string }) => {
    // sharedWorker accept
    if ('call' in this.ws) {
      return this.ws.accept(sessionId);
    }
    return this.emitter.http.request('/ccc/pstn/seatin', {
      sessionId,
    });
  };
  call = (
    callee:
      | {
          phone: string;
          phoneEncodeType?: 'base64' | 'reflect' | 'number';
          remark?: string;
        }
      | {
          sessionId: string;
        }
      | {
          userId: string;
        },
    extra: {
      uui?: string;
      skillGroupId?: string;
      servingNumber?: string;
      servingNumberGroupIds?: string[];
    },
    span: Span,
  ): Promise<{
    sessionId: string;
    callerPhoneNumber?: string;
    calleePhoneNumber?: string;
    protectedCallee?: string;
    calleeLocation?: string;
    remark?: string;
    serverType: ServerType;
  }> =>
    new Promise((resolve, reject) => {
      context.with(trace.setSpan(context.active(), span), () => {
        this.doCall(callee, extra)
          .then(
            ({
              servingNumber: callerPhoneNumber,
              sessionId,
              userMark: remark,
              useMobile: useMobileCallOut,
              useExtension,
              phone: calleePhoneNumber,
              protectedPhone: protectedCallee,
            }) => {
              const httpSpan = trace.getSpan(context.active());
              httpSpan?.addEvent('http success', {
                sessionId,
              });
              let serverType: ServerType = 'staffSeat';
              if (useMobileCallOut) {
                serverType = 'staffPhoneSeat';
              }
              if (useExtension) {
                serverType = 'staffExtensionSeat';
              }
              return resolve({
                sessionId,
                remark,
                callerPhoneNumber,
                calleePhoneNumber,
                protectedCallee,
                serverType,
              });
            },
          )
          .catch((e) => {
            trace.getSpan(context.active())?.recordException(e);
            const requestError = new RequestError(e.errorCode, e.message);
            span.recordException(e);
            span.setAttributes({
              'error.name': requestError.name,
              'error.code': requestError.code,
              'error.message': requestError.message,
            });
            span.end();
            reject(e);
          });
      });
    });

  setLanguage = (language: string) => this.ws.setLanguage(language);

  private doCall = (
    callee:
      | {
          phone: string;
          phoneEncodeType?: 'base64' | 'reflect' | 'number';
          remark?: string;
        }
      | {
          userId: string;
        }
      | {
          sessionId: string;
        },
    extra: {
      uui?: string;
      skillGroupId?: string;
      servingNumber?: string;
      servingNumberGroupIds?: string[];
    },
  ) => {
    // sharedWorker call
    if ('call' in this.ws) {
      return this.ws.call(callee, extra);
    }
    if ('sessionId' in callee) {
      return this.emitter.http.request('/ccc/pstn/dialBack', {
        sessionId: callee.sessionId,
      });
    }
    return this.emitter.http.request('/ccc/pstn/dial', {
      callee,
      ...extra,
    });
  };
}
