import memoize from 'lodash/memoize';
import TRTC from 'trtc-sdk-v5';
import logger from '../common/Logger';

export const createTRTC = memoize(
  async ({ sdkAppId, userId }: { sdkAppId: string; userId: string }) => {
    const RTCProtoLength = 'RTCPeerConnection' in window ? window.RTCPeerConnection.toString().length : 0;
    logger.debug('createTRTC', sdkAppId, userId, `RTCPeerConnection length ${RTCProtoLength}`);
    const trtc = TRTC.create();
    try {
      await new Promise((resolve, reject) => {
        const getUserMediaTimer = setTimeout(() => {
          logger.warn('getUserMedia timeout');
          reject(new Error('getUserMedia timeout'));
        }, 5000);
        trtc
          .startLocalAudio()
          .then((res) => {
            clearTimeout(getUserMediaTimer);
            return resolve(res);
          })
          .catch((e) => {
            logger.info('getUserMedia failed', e);
            reject(e);
          });
      });
    } catch (e) {}
    return trtc;
  },
  ({ sdkAppId, userId }) => `${sdkAppId}-${userId}`,
);

export const destroyTRTC = ({ sdkAppId, userId }: { sdkAppId: string; userId: string }) => {
  logger.debug('destroyTRTC');
  const key = `${sdkAppId}-${userId}`;
  (createTRTC.cache.get(key) as Promise<TRTC>)?.then((trtc) => {
    if (trtc) {
      createTRTC.cache.delete(key);
      trtc.stopLocalAudio().finally(() => {
        trtc.destroy();
      });
    }
  });
};
