import * as t from 'io-ts';
import { deleteSession as deleteCallThunk, getSessionList } from '../store/sessions/sessions.thunk';
import {
  acceptCall as acceptThunk,
  cancelInviteCall as cancelInviteCallThunk,
  dialBack as dialBackThunk,
  endCall as endThunk,
  exitMonitorCall as exitMonitorThunk,
  hold as holdThunk,
  invite as inviteThunk,
  inviteCall as inviteCallThunk,
  kick as kickThunk,
  leaveCall as leaveCallThunk,
  monitorCall as monitorCallThunk,
  mute as muteThunk,
  restoreCall as restoreCallThunk,
  sendDigits as sendDigitsThunk,
  SendDigitsParams,
  SingleStepConferenceParams,
  startInternalCall as startInternalCallThunk,
  startOutboundCall as callOutThunk,
  transferCall as transferThunk,
  TransferParams,
  triggerReceiveKey as triggerReceiveKeyThunk,
  TriggerReceiveKeyParams,
  triggerSelfService as triggerSelfServiceThunk,
  TriggerSelfServiceParams,
  unHold as unHoldThunk,
  unmute as unmuteThunk,
  terminateSessions as terminateSessionsThunk,
} from '../store/sessions/call.thunk';
import { Session } from '../store/sessions';
import {
  isMicMuted as isMicMutedThunk,
  muteMic as muteMicThunk,
  unmuteMic as unmuteMicThunk,
} from '../store/sessions/trtc.thunk';
import { getMessageList } from '../store/sessions/tim.thunk';
import { SessionAdapter } from './SessionAdapter';
import { SessionManager, statusC } from '../socket/session-manager/session-manager';
import { unwrapResult, WrapResult } from '../store/createAsyncThunk';
import { Direction } from '../constants/sessions';

export class Call extends SessionAdapter {
  startOutboundCall = async (params: Omit<Parameters<typeof callOutThunk>[0], 'emitter'>) => {
    const res = await callOutThunk({
      ...params,
      emitter: this.sdk,
    }).then(unwrapResult);
    const {
      sessionId,
      remark,
      calleeLocation,
      calleePhoneNumber,
      callerPhoneNumber,
      protectedCallee,
      protectedCaller,
      aiEnabled,
      serverType,
    } = res;
    return {
      status: 'success',
      data: {
        sessionId,
        remark,
        calleeLocation,
        calleePhoneNumber,
        callerPhoneNumber,
        protectedCallee,
        protectedCaller,
        aiEnabled,
        serverType,
      },
    };
  };
  /**
   * @remark 回拨
   */
  dialBack = async (params: Omit<Parameters<typeof dialBackThunk>[0], 'emitter'>) => {
    const res = await dialBackThunk({
      ...params,
      emitter: this.sdk,
    }).then(unwrapResult);
    const {
      sessionId,
      remark,
      calleeLocation,
      calleePhoneNumber,
      callerPhoneNumber,
      protectedCaller,
      protectedCallee,
      aiEnabled,
      serverType,
    } = res;
    return {
      status: 'success',
      data: {
        sessionId,
        remark,
        calleeLocation,
        calleePhoneNumber,
        callerPhoneNumber,
        protectedCallee,
        protectedCaller,
        aiEnabled,
        serverType,
      },
    };
  };

  startInternalCall = async (params: Omit<Parameters<typeof startInternalCallThunk>[0], 'emitter'>) => {
    const res = await startInternalCallThunk({
      ...params,
      emitter: this.sdk,
    }).then(unwrapResult);
    const { sessionId, serverType } = res;
    return {
      status: 'success',
      data: {
        sessionId,
        serverType,
      },
    };
  };
  accept = async (params: Omit<Parameters<typeof acceptThunk>[0], 'emitter'>) => {
    const res = await acceptThunk({
      ...params,
      emitter: this.sdk,
    }).then(unwrapResult);
    const { sessionId, aiEnabled } = res;
    return {
      status: 'success',
      data: {
        sessionId,
        aiEnabled,
      },
    };
  };
  hold = (params: Omit<Parameters<typeof holdThunk>[0], 'emitter'>) => holdThunk({ ...params, emitter: this.sdk });
  /**
   *  取消通话保持
   */
  unHold = (params: Omit<Parameters<typeof unHoldThunk>[0], 'emitter'>) =>
    unHoldThunk({ ...params, emitter: this.sdk });
  transfer = (params: t.TypeOf<typeof TransferParams>) => transferThunk({ ...params, emitter: this.sdk });
  end = (params: Omit<Parameters<typeof endThunk>[0], 'closeBy' | 'emitter'>) =>
    endThunk({ ...params, closeBy: 'seat', emitter: this.sdk });
  hungUp = (params: Omit<Parameters<typeof endThunk>[0], 'closeBy' | 'emitter'>) =>
    endThunk({ ...params, closeBy: 'seat', emitter: this.sdk });
  sendDigits = (params: t.TypeOf<typeof SendDigitsParams>) => sendDigitsThunk({ ...params, emitter: this.sdk });
  getConversationList = (...params: Parameters<typeof getMessageList>): Promise<WrapResult<Session[]>> =>
    getSessionList(...params);

  muteMic = (params: Omit<Parameters<typeof muteMicThunk>[0], 'emitter'>) =>
    muteMicThunk({ ...params, emitter: this.sdk });

  unmuteMic = (params: Omit<Parameters<typeof unmuteMicThunk>[0], 'emitter'>) =>
    unmuteMicThunk({ ...params, emitter: this.sdk });

  isMicMuted(params: { sessionId: string }) {
    return isMicMutedThunk({ ...params, sdk: this.sdk });
  }

  deleteCall = (params: Omit<Parameters<typeof deleteCallThunk>[0], 'emitter'>) =>
    deleteCallThunk({ ...params, emitter: this.sdk });

  monitorCall = (params: Omit<Parameters<typeof monitorCallThunk>[0], 'emitter'>) =>
    monitorCallThunk({ ...params, emitter: this.sdk });

  exitMonitor = (params: Omit<Parameters<typeof exitMonitorThunk>[0], 'emitter'>) =>
    exitMonitorThunk({ ...params, emitter: this.sdk });

  inviteCall = (params: Omit<Parameters<typeof inviteCallThunk>[0], 'emitter'>) =>
    inviteCallThunk({ ...params, emitter: this.sdk });

  cancelInviteCall = (params: Omit<Parameters<typeof cancelInviteCallThunk>[0], 'emitter'>) =>
    cancelInviteCallThunk({ ...params, emitter: this.sdk });

  restoreCall = (params: Omit<Parameters<typeof restoreCallThunk>[0], 'emitter'>) =>
    restoreCallThunk({ ...params, emitter: this.sdk });

  leaveCall = (params: Omit<Parameters<typeof leaveCallThunk>[0], 'emitter'>) =>
    leaveCallThunk({ ...params, emitter: this.sdk });

  triggerReceiveKey = (params: t.TypeOf<typeof TriggerReceiveKeyParams>) =>
    triggerReceiveKeyThunk({ ...params, emitter: this.sdk });

  triggerSelfService = (params: t.TypeOf<typeof TriggerSelfServiceParams>) =>
    triggerSelfServiceThunk({ ...params, emitter: this.sdk });

  invite = (params: t.TypeOf<typeof SingleStepConferenceParams>) => inviteThunk({ ...params, emitter: this.sdk });

  kick = (params: Omit<Parameters<typeof kickThunk>[0], 'emitter'>) => kickThunk({ ...params, emitter: this.sdk });

  mute = (params: Omit<Parameters<typeof muteThunk>[0], 'emitter'>) => muteThunk({ ...params, emitter: this.sdk });

  unmute = (params: Omit<Parameters<typeof unmuteThunk>[0], 'emitter'>) =>
    unmuteThunk({ ...params, emitter: this.sdk });

  getCallInfos = () => {
    const { userInfo } = this.sdk.Agent;
    if (userInfo?.userId && userInfo.sdkAppId) {
      const transformStatus = (status: statusC) => {
        if (status === statusC.ACCEPTED) return '200';
        if (status === statusC.RINGING) return '100';
        return '400';
      };
      return Object.values(SessionManager.getSessionList({ sdkAppId: userInfo.sdkAppId, userId: userInfo.userId })).map(
        (item) => ({
          sessionId: item.sessionId,
          phone: item.phoneNumber,
          serverType: item.serverType,
          remark: item.customer?.remark,
          calleePhoneNumber:
            item.direction === Direction.callIn ? item.customer?.servingNumber || '' : item.phoneNumber,
          callerPhoneNumber: item.direction === Direction.callIn ? item.customer?.servingNumber : item.phoneNumber,
          status: transformStatus(item.status),
          direction: item.direction,
          type: item.type,
        }),
      );
    }
    return [];
  };

  terminateSessions = () => terminateSessionsThunk({ emitter: this.sdk });

  /**
   * 兼容旧版本API
   */
  reset() {
    super.reset();
    const { userInfo } = this.sdk.Agent;
    if (userInfo?.userId && userInfo.sdkAppId) {
      SessionManager.reset({ sdk: this.sdk });
    }
  }
}
