import { createAsyncThunk } from '../createAsyncThunk';
import { getOrPromiseReject } from 'tccc-utils';
import { getWebRTCInstance } from '../../trtc/trtc';
import { SessionIdParams } from './sessions.thunk';
import { InvalidParamsError, NotFoundError } from '../../common/TcccError';
import { TcccSdk } from '../../tccc';
import i18next from '../../i18n/i18next.config';

export const startRealtimeAsr = createAsyncThunk(
  'asr/startRealtimeAsr',
  async (params: { sessionId: string; sdk: TcccSdk }) => {
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    const webRtc = getWebRTCInstance({ sdk: params.sdk, sessionId });
    if (!webRtc) {
      throw new NotFoundError('session', sessionId);
    }
    try {
      await webRtc.startRealtimeAsr();
      params.sdk.Call.updateOne(sessionId, { isStartRealtimeAsr: true });
    } catch (e) {
      throw e;
    }
    return {
      sessionId,
    };
  },
);

export const stopRealtimeAsr = createAsyncThunk(
  'asr/stopRealtimeAsr',
  async (params: { sessionId: string; sdk: TcccSdk }) => {
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    if (!params.sdk.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const webRtc = getWebRTCInstance({ sdk: params.sdk, sessionId });
    if (!webRtc) {
      throw new NotFoundError('session', sessionId);
    }
    try {
      await webRtc.stopRealtimeAsr();
      params.sdk.Call.updateOne(sessionId, { isStartRealtimeAsr: false });
    } catch (e) {
      throw e;
    }
    return {
      sessionId,
    };
  },
);
