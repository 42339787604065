import { TcccSdk } from '../../tccc';
import type { CallState, SessionChangedType, SessionType } from './session-manager';
import { ServerType } from '../../store/sessions';
import EventEmitter from 'eventemitter3';
import isEqual from 'lodash/isEqual';
import { enterRoom, leaveRoom } from '../../store/sessions/trtc.thunk';
import { Direction } from '../../constants/sessions';
import { CreateLogger } from './createLogger';

type MemberDirection = 'Incoming' | 'Outgoing';

export type HungupParams = {
  message: string;
  side: 'REMOTE' | 'LOCAL' | 'SYSTEM';
  sipReason: string;
  sipCode: number;
  sipText: string;
  subMessage: string;
};

export type MemberServerType = ServerType | 'queue' | 'customer';

export type Member<T extends MemberServerType = MemberServerType> = {
  callState: CallState;
  serverType: T;
  userId: string;
  memberId: string;
  waitId?: string;
  direction: MemberDirection;
  trtcParams: {
    privateMapKey: string;
    roomId: string;
    sdkAppId: string;
    userId: string;
    userSig: string;
    unified?: boolean;
  };
  extra: {
    callerLocation: string;
    servingNumber: string;
    ivrPress: string;
    ivrPressLabel: string[];
    remark: string;
    servingNumberRemark?: string;
    clientData?: string;
    skillGroupId?: string;
  };
  protectedPhone: string;
  phone: string;
  displayName?: string;
  hungupParams: HungupParams;
  acceptedState?: keyof AcceptedState;
  isHost: boolean;
  isMonitor?: boolean;

  acceptTimestamp: number;
  hungupTimestamp: number;
};

export enum CallStateEnum {
  INIT,
  RINGING,
  ACCEPTED,
  END,
}

export type AcceptedState = {
  ON_HOLD: boolean;
  MUTE: boolean;
  INPUT: boolean;
  PLAY_AUDIO: boolean;
};

interface MemberEvents {
  _callStateChanged: (res: {
    prevCallState: CallState | null;
    callState: CallState;
    prevAcceptedState: AcceptedState | null;
    acceptedState: AcceptedState;
    event: SessionChangedType;
  }) => void;

  onExitRoom: () => void;
  onEnterRoom: () => void;
  'onEnterRoom:failed': (error: string) => void;

  stateChanged: (res: {
    prevState: CallState | null;
    state: CallState;
    hungupParams?: HungupParams;
    prevAcceptedState: AcceptedState | null;
    acceptedState: AcceptedState;
    member: Member;
    event: SessionChangedType;
  }) => void;
}

export const getAcceptedState = (member?: Member) => ({
  MUTE: member?.acceptedState?.includes('MUTE') || false,
  ON_HOLD: member?.acceptedState?.includes('ON_HOLD') || false,
  PLAY_AUDIO: member?.acceptedState?.includes('PLAY_AUDIO') || false,
  INPUT: member?.acceptedState?.includes('INPUT') || false,
});

abstract class AbstractMember extends EventEmitter<MemberEvents> {
  // 其他属性
  member: Member;

  logger: Record<string, (...args: any[]) => void>;
  // 动态属性
  protected hungupParams?: HungupParams;
  protected isHost?: boolean;

  // 静态属性
  protected readonly sessionId: string;
  protected readonly memberId: string;
  protected readonly direction: MemberDirection;
  protected readonly emitter: TcccSdk;

  private acceptedState: AcceptedState | null;
  private state: CallState | null;

  protected constructor({
    sessionId,
    member,
    emitter,
  }: {
    sessionId: string;
    member: Member;
    emitter: TcccSdk;
    event: SessionChangedType;
  }) {
    super();
    this.logger = CreateLogger('MEMBER', { sessionId, memberId: member.memberId }, emitter.Agent.userInfo);
    this.logger.debug('new Member');
    this.sessionId = sessionId;
    this.member = member;
    this.memberId = member.memberId;
    this.direction = member.direction;
    this.isHost = member.isHost;
    this.emitter = emitter;
    this.acceptedState = null;
    this.state = null;
  }
  public dispatchState = (member: Member, event: SessionChangedType) => {
    if (member) {
      this.member = member;
      if (member.hungupParams) {
        this.hungupParams = member.hungupParams;
      }
    }

    const prevAcceptedState = this.acceptedState;
    const acceptedState = getAcceptedState(member);
    const prevCallState = this.state;
    const { callState } = member;

    if (
      !isEqual(prevAcceptedState, acceptedState) ||
      (prevCallState === null && callState !== null) ||
      (prevCallState !== null && CallStateEnum[prevCallState] < CallStateEnum[callState]) ||
      member.isHost !== this.isHost
    ) {
      this.acceptedState = acceptedState;
      this.state = callState;
      this.isHost = member.isHost;
      this.emit('_callStateChanged', {
        prevCallState,
        callState,
        prevAcceptedState,
        acceptedState,
        event,
      });
    }
  };

  protected exitTRTCRoom() {
    this.logger.info('exitRoom');
    leaveRoom({
      sessionId: this.sessionId,
      emitter: this.emitter,
    }).then(() => {
      this.emit('onExitRoom');
    });
  }
}
export class AgentMember extends AbstractMember {
  serverType: ServerType;
  sessionType: SessionType;
  tabUUID: string;
  trtcParams: {
    sdkAppId: string;
    userId: string;
    roomId: string;
    userSig: string;
    privateMapKey: string;
    unified?: boolean;
  };

  constructor({
    sessionId,
    sessionType,
    member,
    emitter,
    event,
    tabUUID,
  }: {
    sessionId: string;
    sessionType: SessionType;
    member: Member;
    emitter: TcccSdk;
    event: SessionChangedType;
    tabUUID: string;
  }) {
    super({ sessionId, member, event, emitter });
    this.trtcParams = member.trtcParams;
    this.sessionType = sessionType;
    this.serverType = (member.serverType || 'staffSeat') as ServerType;
    this.tabUUID = tabUUID;

    this.on('_callStateChanged', ({ prevCallState, callState: nextState, event, prevAcceptedState, acceptedState }) => {
      const isRefresh = prevCallState === null && nextState === 'ACCEPTED'; // 刷新情况
      // 正常外呼直接进房
      const isCallOutAutoEnter = this.direction === 'Outgoing' && nextState === 'RINGING';
      // 外呼接通后刷新
      const isCallOutRefresh = this.direction === 'Outgoing' && isRefresh;
      // 呼入接通后刷新
      const isCallInRefresh = this.direction === 'Incoming' && isRefresh;
      if (this.serverType === 'staffSeat' && (isCallOutAutoEnter || isCallOutRefresh || isCallInRefresh)) {
        if (!this.tabUUID || this.tabUUID === this.emitter.uuid) {
          this.enterTRTCRoom();
        } else {
          this.logger.debug(`ignore other tab enter room, ${this.tabUUID} ${this.emitter.uuid}`);
        }
      }
      if (this.serverType === 'staffSeat' && nextState === 'END') {
        this.exitTRTCRoom();
      }
      this.emit('stateChanged', {
        prevState: prevCallState,
        state: nextState,
        prevAcceptedState,
        acceptedState,
        hungupParams: this.hungupParams,
        member: this.member,
        event,
      });
    });
  }
  private enterTRTCRoom = () => {
    this.logger.info('enterRoom');
    enterRoom({
      sessionId: this.sessionId,
      direction: this.direction === 'Outgoing' ? Direction.callOut : Direction.callIn,
      type: 'phone',
      emitter: this.emitter,
      ...this.trtcParams,
    })
      .then(() => {
        this.logger.debug('emit "onEnterRoom"', this.listenerCount('onEnterRoom'));
        // for test
        this.emit('onEnterRoom');
      })
      .catch((e) => {
        this.logger.warn('emit "onEnterRoom:failed"');
        this.emit('onEnterRoom:failed', (e as Error)?.message || '');
        const error = new Error((e as Error)?.message);
        this.emitter.http
          .request('/ccc/pstn/end', {
            sessionId: this.sessionId,
            error: error.toString(),
          })
          .then(() => {
            this.logger.debug('end success');
          })
          .catch((error) => {
            this.logger.error('end error', error);
          });
      });
  };
}

export class CustomerMember extends AbstractMember {
  constructor({
    sessionId,
    member,
    emitter,
    event,
  }: {
    sessionId: string;
    member: Member;
    emitter: TcccSdk;
    event: SessionChangedType;
  }) {
    super({ sessionId, member, event, emitter });

    this.on('_callStateChanged', ({ prevCallState, callState: nextState, event, prevAcceptedState, acceptedState }) => {
      this.emit('stateChanged', {
        prevState: prevCallState,
        state: nextState,
        prevAcceptedState,
        acceptedState,
        hungupParams: this.hungupParams,
        member: this.member,
        event,
      });
    });
  }
}
export class UserMember extends AbstractMember {
  constructor({
    sessionId,
    member,
    emitter,
    event,
  }: {
    sessionId: string;
    member: Member;
    emitter: TcccSdk;
    event: SessionChangedType;
  }) {
    super({ sessionId, member, event, emitter });

    this.on('_callStateChanged', ({ prevCallState, callState: nextState, event, prevAcceptedState, acceptedState }) => {
      this.emit('stateChanged', {
        prevState: prevCallState,
        state: nextState,
        prevAcceptedState,
        acceptedState,
        hungupParams: this.hungupParams,
        member: this.member,
        event,
      });
    });
  }
}
