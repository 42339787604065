import type { TurnServerConfig } from '../trtc/trtc';

export type ProxyType = {
  TRTCWebSocketProxy: string;
  TRTCLoggerProxy: string;
  TRTCUnifiedProxy?: string;
  TRTCTurnServer: TurnServerConfig[];
};

const initialState: ProxyType = {
  TRTCWebSocketProxy: '',
  TRTCLoggerProxy: '',
  TRTCUnifiedProxy: '',
  TRTCTurnServer: [],
};
export class Proxy {
  proxy: ProxyType;
  constructor() {
    this.proxy = initialState;
  }
  setTRTCProxyServer = ({
    TRTCWebSocketProxy,
    TRTCLoggerProxy,
    TRTCUnifiedProxy,
  }: {
    TRTCWebSocketProxy: string;
    TRTCLoggerProxy: string;
    TRTCUnifiedProxy?: string;
  }) => {
    Object.assign(this.proxy, {
      TRTCWebSocketProxy,
      TRTCLoggerProxy,
      TRTCUnifiedProxy,
    });
  };
  setTRTCTurnServer(turnServer: TurnServerConfig | TurnServerConfig[]) {
    Object.assign(this.proxy, {
      TRTCTurnServer: Array.isArray(turnServer) ? turnServer : [turnServer],
    });
  }
}
