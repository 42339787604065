export const MSG_EVENTS = {
  OptRedisFailed: '-11',
  SetStatusConflict: '-2021',
  // 8  IM呼入邀请，staff是主叫
  ImCallIn: '8',
  // 101  多处登录下踢下线
  OfflineMultipleLogin: '101',
  // 102  本端状态变更
  UpdateAgentState: '102',
  // 1054  IM呼入挂断
  IMHangUp: '1054',
  // 1100  强制下线
  ForceOffline: '1100',
  // 通知座席外呼第三方接通
  BroadcastForwardAnswered: '2010',
  // 通知座席外呼第三方挂断(已接通)
  BroadcastForwardHangup: '2011',
  // 通知座席外呼第三方未接通
  BroadcastForwardFailed: '2012',
  // 收号
  InputCallback: '2016',
  // 统一Session变更事件
  MemberListChange: '2017',
  // IM用户上下线通知
  ImStateChange: '2019',
  // asr识别结果
  AsrResult: '2020',
};

export enum ConnectionStateEnum {
  UNKNOWN = -1,
  DISCONNECTED = 0,
  CONNECTING = 1,
  CONNECTED = 2,
  RECONNECTING = 3,
}
