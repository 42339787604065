import { Span, ContextAPI, context, trace } from '@opentelemetry/api';
import { bootstrap } from './opentelemetry';
import { Tracer } from '@opentelemetry/sdk-trace-base';

export const contextWithSpan = <T>(span: Span, callback: (ctx: ContextAPI) => T): Promise<T> =>
  new Promise((resolve, reject) =>
    context.with(trace.setSpan(context.active(), span), async () => {
      try {
        const res = await callback(context);
        trace.setSpan(context.active(), span);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    }),
  );
let tracer: Tracer;

const obj = {
  get tracer() {
    if (!tracer) {
      return bootstrap();
    }
    return tracer;
  },

  set tracer(newTracer) {
    tracer = newTracer;
  },
};
export default obj;
